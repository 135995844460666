const ShowItemsCarriers = ({ type, list, setList, itemType, setListType }) => (
  <div className="self-center text-xl font-bold flex flex-col place-content-center w-full m-2">
    <div className="self-center text-blue-900 mb-5">Pick A {type}:</div>
    <div className="flex flex-wrap place-content-evenly">
      {list.map(
        (
          {
            mainIcon,
            iconRegular,
            iconSmall,
            label,
            color,
            fill,
            stroke,
            viewBox,
            d,
            ref,
          },
          index
        ) =>
          label && (
            <button
              key={index}
              ref={ref}
              onClick={() => {
                setList((list) =>
                  [...list, itemType].filter((i) => i.mainIcon !== mainIcon)
                );
                setListType({
                  mainIcon,
                  iconRegular,
                  iconSmall,
                  label,
                  color,
                  fill,
                  stroke,
                  viewBox,
                  d,
                  ref,
                });
              }}
              className="self-center outline-none focus:shadow-2xl focus:shadow-slate-600 hover:shadow-2xl hover:shadow-slate-600 p-5"
            >
              {mainIcon}
            </button>
          )
      )}
    </div>
  </div>
);
export default ShowItemsCarriers;
