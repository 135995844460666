import { forwardRef } from "react";
import "./style.css";
import LandingPageTabs from "./LandingPageTabs";
import LandingPageFooter from "./LandingPageFooter";
import LandingPageImages from "./LandingPageImages";
import LandingPageDropDown from "./LandingPageDropDown";
import SchoolRegistration from "../SchoolRegistration/SchoolRegistration";
import { GiPolarStar } from "react-icons/gi";

const LandingPage = forwardRef(
  (
    {
      setTabSelect,
      currentTab,
      apod,
      setShowSignin,
      authData,
      authChecked,
      setLogoutConfirm,
      setShowStudentsData,
      appCheckToken,
      setShowSpinner,
      showSchoolReg,
      setShowSchoolReg,
      showAdminView,
      setShowAdminView,
      setShowTeacherDetails,
      setShowStudentDetails,
    },
    ref
  ) => (
    <div className="w-screen h-screen landing-page overflow-scroll border-[16px] border-black">
      <div className="bg-black mb-2 h-fit flex flex-wrap place-content-between w-full">
        <div className="max-[640px]:w-full self-center text-xl font-bold">
          <div className="flex flex-wrap place-content-center self-center">
            <button
              onClick={() =>
                window.open("https://superteacherguy.com/", "_blank")
              }
              className="outline-none hover:-translate-y-1 hover:shadow hover:shadow-[#0eb9e8] focus:-translate-y-1 focus:shadow focus:shadow-[#0eb9e8]"
            >
              <img
                className="h-20 max-[1024px]:h-16 p-1"
                alt="superteachergames!"
                src={`/logo192.png`}
              />
            </button>
            <div className="text-[#00c6fc] max-[1024px]:text-base self-center text-2xl font-bold ml-2">
              <div className="flex flex-row font-bold">
                <div className="font-antonio">superteachergames!</div>
                <GiPolarStar className="sparkle text-white max-[1024px]:text-xs text-lg" />
              </div>
            </div>
          </div>
        </div>
        <div className="max-[640px]:w-full w-1/2 self-center text-center flex flex-wrap place-content-between">
          <div className="self-center">
            <div className="self-center text-[#DFCFBE] max-[1024px]:text-[12px] tracking-tight font-bold text-xl ">
              {authData?.role && authData?.role?.school}
            </div>
            <div
              className={`font-pbv text-[#DFCFBE] max-[1024px]:text-[10px] ${
                authData?.role && authData?.role?.school ? "text-sm" : "text-xl"
              } font-bold tracking-tight italic`}
            >
              <button
                onClick={() =>
                  window.open("https://superteacherguy.com/", "_blank")
                }
                className="outline-none hover:underline hover:text-[#0eb9e8] focus:underline focus:text-[#0eb9e8]"
              >
                Make sense of math.
              </button>
            </div>
          </div>

          <div className="self-center">
            <LandingPageDropDown
              authChecked={authChecked}
              authData={authData}
              setShowSignin={setShowSignin}
              setLogoutConfirm={setLogoutConfirm}
              setShowStudentsData={setShowStudentsData}
              setShowSchoolReg={setShowSchoolReg}
              setShowAdminView={setShowAdminView}
              setShowTeacherDetails={setShowTeacherDetails}
              setShowStudentDetails={setShowStudentDetails}
            />
          </div>
        </div>
      </div>
      <LandingPageTabs
        setTabSelect={setTabSelect}
        currentTab={currentTab}
        ref={ref}
      />
      {apod?.url && <LandingPageImages apod={apod} />}
      {showSchoolReg && (
        <SchoolRegistration
          appCheckToken={appCheckToken}
          authData={authData}
          setShowSpinner={setShowSpinner}
          showSchoolReg={showSchoolReg}
          setShowSchoolReg={setShowSchoolReg}
        />
      )}
      <LandingPageFooter />
    </div>
  )
);
export default LandingPage;
