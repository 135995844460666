import { useEffect, useState, useCallback, createRef } from "react";

const useLinesL = ({
  maxWidth,
  maxHeight,
  level: { max, min, short },
  width,
}) => {
  const [polyColorPoints, setPolyColorPoints] = useState([]);
  const [linePoints, setLinePoints] = useState([]);
  const [vgLeft, setVgLeft] = useState([]);
  const [hgTop] = useState([]);
  const [hgBelow, setHgBelow] = useState([]);
  const [hg2] = useState([]);
  const [vgRight] = useState([]);
  const [vg2] = useState([]);
  const [lLength, setLLength] = useState({
    horizontal: null,
    vertical: null,
    l1: null,
    l2: null,
    l3: null,
    l4: null,
    l5: null,
    l6: null,
  });

  const [lineRefs, setLineRefs] = useState([...new Array(6)]);

  useEffect(() => setLineRefs((l) => l.map(() => createRef())), []);

  const getLineLengths = useCallback(() => {
    let horizontal, vertical, l1, l2, l3, l4, l5, l6;
    horizontal = Math.floor(Math.random() * (max - min + 1) + min);
    vertical = Math.floor(Math.random() * (max - min + 1) + min);
    l1 = vertical;
    l2 = horizontal;
    l3 = Math.floor(Math.random() * (l1 - min));
    l3 = l3 < short ? short : l3;
    l4 = Math.floor(Math.random() * (l2 - min));
    l4 = l4 < short ? short : l4;
    l5 = l1 - l3;
    l6 = l2 - l4;
    return { horizontal, vertical, l1, l2, l3, l4, l5, l6 };
  }, [max, min, short]);

  useEffect(() => {
    const { horizontal, vertical, l1, l2, l3, l4, l5, l6 } = getLineLengths();
    setLLength({ horizontal, vertical, l1, l2, l3, l4, l5, l6 });
  }, [getLineLengths]);

  useEffect(() => {
    if (maxWidth && maxHeight && lLength.horizontal) {
      const { horizontal, vertical, l1, l2, l3, l4, l5, l6 } = lLength;
      const polyBorderStart = 50;
      const verticalPoints = maxHeight - 100;
      const horizontalPoints = maxWidth - 100;
      const points = [
        {
          ref: lineRefs[0],
          type: "verticalGroupLeft",
          x1: `${polyBorderStart}`,
          y1: `${polyBorderStart}`,
          x2: `${polyBorderStart}`,
          y2: `${verticalPoints + polyBorderStart}`,
        },
        {
          ref: lineRefs[1],
          type: "horizontalGroupBelow",
          x1: `${polyBorderStart}`,
          y1: `${verticalPoints + polyBorderStart}`,
          x2: `${horizontalPoints + polyBorderStart}`,
          y2: `${verticalPoints + polyBorderStart}`,
        },
        {
          ref: lineRefs[2],
          type: "verticalGroupLeft",
          x1: `${horizontalPoints + polyBorderStart}`,
          y1: `${verticalPoints + polyBorderStart}`,
          x2: `${horizontalPoints + polyBorderStart}`,
          y2: `${(l5 * verticalPoints) / vertical + polyBorderStart}`,
        },
        {
          ref: lineRefs[3],
          type: "horizontalGroupBelow",
          x1: `${horizontalPoints + polyBorderStart}`,
          y1: `${(l5 * verticalPoints) / vertical + polyBorderStart}`,
          x2: `${(l6 * horizontalPoints) / horizontal + polyBorderStart}`,
          y2: `${(l5 * verticalPoints) / vertical + polyBorderStart}`,
        },
        {
          ref: lineRefs[4],
          type: "verticalGroupLeft",
          x1: `${(l6 * horizontalPoints) / horizontal + polyBorderStart}`,
          y1: `${(l5 * verticalPoints) / vertical + polyBorderStart}`,
          x2: `${(l6 * horizontalPoints) / horizontal + polyBorderStart}`,
          y2: `${polyBorderStart}`,
        },
        {
          ref: lineRefs[5],
          type: "horizontalGroupBelow",
          x1: `${(l6 * horizontalPoints) / horizontal + polyBorderStart}`,
          y1: `${polyBorderStart}`,
          x2: `${polyBorderStart}`,
          y2: `${polyBorderStart}`,
        },
      ];
      let colorPoints;
      [...points]
        .filter((p) => p.line !== "placeHolder")
        .map(
          ({ x1, y1, x2, y2 }) =>
            (colorPoints = colorPoints
              ? `${colorPoints} ${x1},${y1} ${x2},${y2}`
              : `${x1},${y1} ${x2},${y2}`)
        );
      setPolyColorPoints(colorPoints);
      setLinePoints(points);
      setVgLeft([
        {
          lineRef: lineRefs[0],
          vertical,
          text: {
            x: `${polyBorderStart - 15}`,
            y: `${maxHeight / 2}`,
            lineLength: l1,
          },
        },
        {
          lineRef: lineRefs[2],
          vertical,
          text: {
            x: `${horizontalPoints + polyBorderStart + 15}`,
            y: `${
              (verticalPoints +
                polyBorderStart +
                ((l5 * verticalPoints) / vertical + polyBorderStart)) /
              2
            }`,
            lineLength: l3,
          },
        },
        {
          lineRef: lineRefs[4],
          vertical,
          text: {
            x: `${(l6 * horizontalPoints) / horizontal + polyBorderStart + 15}`,
            y: `${
              ((l5 * verticalPoints) / vertical +
                polyBorderStart +
                polyBorderStart) /
              2
            }`,
            lineLength: l5,
          },
        },
      ]);
      setHgBelow([
        {
          lineRef: lineRefs[1],
          horizontal,
          text: {
            x: `${
              (polyBorderStart + (horizontalPoints + polyBorderStart)) / 2
            }`,
            y: `${verticalPoints + polyBorderStart + 25}`,
            lineLength: l2,
          },
        },
        {
          lineRef: lineRefs[3],
          horizontal,
          text: {
            x: `${
              (horizontalPoints +
                polyBorderStart +
                ((l6 * horizontalPoints) / horizontal + polyBorderStart)) /
              2
            }`,
            y: `${(l5 * verticalPoints) / vertical + polyBorderStart - 10}`,
            lineLength: l4,
          },
        },
        {
          lineRef: lineRefs[5],
          horizontal,
          text: {
            x: `${
              ((l6 * horizontalPoints) / horizontal +
                polyBorderStart +
                polyBorderStart) /
              2
            }`,
            y: "40",
            lineLength: l6,
          },
        },
      ]);
    }
  }, [maxWidth, maxHeight, width, lineRefs, lLength]);

  return [
    polyColorPoints,
    linePoints,
    vgLeft,
    hgTop,
    hgBelow,
    hg2,
    vgRight,
    vg2,
  ];
};

export default useLinesL;
