import { useEffect, useState } from "react";
import findindex from "lodash.findindex";

const useLineNumber = ({
  divisor,
  divisorNumerator,
  dividend,
  maxWidth,
  maxHeight,
}) => {
  const [linePoints, setLinePoints] = useState(null);
  const [dividendPoints, setDividendPoints] = useState([]);
  const [dividendAnimationPoints, setDividendAnimationPoints] = useState(null);
  const [divisorLength, setDivisorLength] = useState(null);
  const [divisorPoints, setDivisorPoints] = useState([]);
  const [divisorAnimationPointsTop, setDivisorAnimationPointsTop] =
    useState(null);
  const [divisorAnimationPointsBelow, setDivisorAnimationPointsBelow] =
    useState(null);
  const [lineLength, setLineLength] = useState(null);
  const [fracts, setFracts] = useState([]);
  const [fractPoints, setFractPoints] = useState([]);
  const [fractAnimationPointsTop, setFractAnimationPointsTop] = useState(null);
  const [fractAnimationPointsBelow, setFractAnimationPointsBelow] =
    useState(null);

  useEffect(() => {
    if (divisor && dividend) {
      let min = Math.floor(maxWidth / (dividend * divisor));
      setDivisorLength(min);
      setLineLength(min * dividend * divisor);
    }
  }, [divisor, dividend, maxWidth]);

  useEffect(() => {
    if (lineLength) {
      let dividendLength = lineLength / dividend;
      let dp = [],
        dpa = "";

      [...Array(dividend + 1)].forEach((item, i) => {
        dp = [
          ...dp,
          {
            x1: dividendLength * i + (maxWidth - lineLength) / 2,
            y1: maxHeight / 2,
          },
        ];
        setDividendPoints([...dp]);
        dpa = dpa
          ? `${dpa} ${dividendLength * i + (maxWidth - lineLength) / 2},${
              maxHeight / 2
            }`
          : `${dividendLength * i + (maxWidth - lineLength) / 2},${
              maxHeight / 2
            }`;
        setDividendAnimationPoints(dpa);
      });
    }
  }, [lineLength, dividend, maxWidth, maxHeight]);

  useEffect(() => {
    if (dividendPoints.length > 0) {
      setLinePoints(
        `${dividendPoints[0].x1},${dividendPoints[0].y1 + 15} ${
          dividendPoints[0].x1
        },${dividendPoints[0].y1 - 15} ${
          dividendPoints[dividendPoints.length - 1].x1
        },${dividendPoints[dividendPoints.length - 1].y1 - 15} ${
          dividendPoints[dividendPoints.length - 1].x1
        },${dividendPoints[dividendPoints.length - 1].y1 + 15}`
      );
      let f = [];
      dividendPoints.forEach(({ x1, y1 }, index) =>
        [...Array(divisor)].forEach((item, i) => {
          if (index < dividendPoints.length - 1) {
            f = [
              ...f,
              {
                x1: divisorLength * i + x1,
                y1,
              },
            ];
          }
        })
      );

      f = [
        ...f,
        {
          x1: dividendPoints[dividendPoints.length - 1].x1,
          y1: dividendPoints[dividendPoints.length - 1].y1,
        },
      ];
      setFracts([...f]);
    }
  }, [dividendPoints, divisorLength, divisor]);

  useEffect(() => {
    let points = [];

    if (fracts.length > 0) {
      for (let i = 0; i * divisorNumerator <= fracts.length - 1; i = i + 1)
        points.push(fracts[i * divisorNumerator]);
      setDivisorPoints(points);
      setFractPoints(
        fracts.filter((f) =>
          findindex(points, { x1: f.x1 }) !== -1 ? false : true
        )
      );
    }
  }, [fracts, dividendPoints, divisor, divisorNumerator]);

  useEffect(() => {
    if (divisorPoints.length > 0 && dividendPoints.length > 0) {
      const dp = [...divisorPoints].sort((a, b) => (a.x1 < b.x1 ? 1 : -1));
      let dpt, dpb;

      dp.forEach(({ x1, y1 }, index) => {
        if (index < dp.length - 1) {
          dpt = dpt
            ? `${dpt} ${x1},${y1} Q${(x1 + dp[index + 1].x1) / 2},${y1 - 100}`
            : `${x1},${y1} Q${(x1 + dp[index + 1].x1) / 2},${y1 - 100}`;
          dpb = dpb
            ? `${dpb} ${x1},${y1} Q${(x1 + dp[index + 1].x1) / 2},${y1 + 100}`
            : `${x1},${y1} Q${(x1 + dp[index + 1].x1) / 2},${y1 + 100}`;
        }
      });
      dpt = `${dpt} ${dp[dp.length - 1].x1},${dp[dp.length - 1].y1}`;
      dpb = `${dpb} ${dp[dp.length - 1].x1},${dp[dp.length - 1].y1}`;
      setDivisorAnimationPointsTop(dpt);
      setDivisorAnimationPointsBelow(dpb);

      let top = "",
        below = "";

      if (
        divisorPoints[divisorPoints.length - 1].x1 <
        dividendPoints[dividendPoints.length - 1].x1
      ) {
        top = `${
          divisorPoints[divisorPoints.length - 1].x1 +
          divisorLength * divisorNumerator
        },${divisorPoints[divisorPoints.length - 1].y1}
         Q${
           divisorPoints[divisorPoints.length - 1].x1 +
           (divisorLength * divisorNumerator) / 2
         },${divisorPoints[divisorPoints.length - 1].y1 - 100}
        ${divisorPoints[divisorPoints.length - 1].x1},${
          divisorPoints[divisorPoints.length - 1].y1
        }`;
        below = `${
          divisorPoints[divisorPoints.length - 1].x1 +
          divisorLength * divisorNumerator
        },${divisorPoints[divisorPoints.length - 1].y1} Q${
          divisorPoints[divisorPoints.length - 1].x1 +
          (divisorLength * divisorNumerator) / 2
        },${divisorPoints[divisorPoints.length - 1].y1 + 100}
        ${divisorPoints[divisorPoints.length - 1].x1},${
          divisorPoints[divisorPoints.length - 1].y1
        }`;
      }
      setFractAnimationPointsTop(top);
      setFractAnimationPointsBelow(below);
    }
  }, [dividendPoints, divisorPoints, divisorLength, divisorNumerator]);
  return [
    dividendAnimationPoints,
    dividendPoints,
    divisorPoints,
    divisorAnimationPointsTop,
    divisorAnimationPointsBelow,
    linePoints,
    fractPoints,
    fractAnimationPointsTop,
    fractAnimationPointsBelow,
  ];
};
export default useLineNumber;
