import { forwardRef } from "react";
import Fractions from "../../Fractions/Fractions";

const KEquationInputs = forwardRef(
  (
    {
      challengeLevel,
      showSolution,
      kVal,
      setKVal,
      kOptions,
      setScoreChange,
      verifyKVal,
      result,
    },
    ref
  ) => (
    <form className="flex flex-wrap place-content-center p-2">
      <div className="flex flex-row place-content-center">
        {challengeLevel === 0 ? (
          <input
            ref={ref}
            type="number"
            className={`${
              showSolution &&
              (result === "Incorrect" ? "bg-red-200" : "bg-green-200")
            } mt-1 text-center self-center h-12 w-32 pl-3 pr-2 rounded-lg z-0 focus:bg-cyan-200 hover:bg-cyan-200`}
            placeholder="k?"
            value={kVal}
            onChange={(event) => setKVal(event.target.value)}
            disabled={showSolution}
          />
        ) : (
          kOptions?.options.map((a, index) => (
            <div className="flex flex-row place-content-center" key={a}>
              <input
                className={`self-center h-4 w-4 max-[1024px]:h-3 max-[1024px]:w-3 cursor-pointer rounded-full appearance-none border-2 border-blue-900 
              checked:border-[6px] checked:max-[1024px]:border-[5px] checked:border-black
              focus:border-[6px] focus:max-[1024px]:border-[5px] focus:border-slate-400
              hover:border-[6px] hover:max-[1024px]:border-[5px] hover:border-slate-400
              disabled:pointer-events-none`}
                ref={ref}
                type="radio"
                name="answer"
                value={`${a}`}
                onClick={(event) => setKVal(event.target.value)}
                disabled={showSolution}
              />

              <label htmlFor={`${a}`} className={`self-center p-1`}>
                <div className="flex flex-row place-content-center">
                  <Fractions
                    small={true}
                    numerator={kOptions?.splitOptions[index].rn}
                    denominator={kOptions?.splitOptions[index].rd}
                  />
                </div>
              </label>
            </div>
          ))
        )}
      </div>
      <div className="self-center p-1">
        <button
          type="submit"
          disabled={!kVal || showSolution}
          onClick={(event) => {
            event.preventDefault();
            setScoreChange(true);
            verifyKVal();
          }}
          className={`h-fit w-fit border-2 text-sm outline-none p-1
${
  !kVal || showSolution
    ? "bg-gray-200 text-gray-100"
    : "bg-blue-200 focus:bg-blue-400 hover:bg-blue-400"
}`}
        >
          Submit
        </button>
      </div>
    </form>
  )
);
export default KEquationInputs;
