import { forwardRef, useEffect } from "react";
const LineNumberImage = forwardRef(
  (
    {
      dividendAnimationPoints,
      dividendPoints,
      divisorPoints,
      divisorAnimationPointsTop,
      divisorAnimationPointsBelow,
      linePoints,
      maxWidth,
      maxHeight,
      width,
      result,
      showDividendLines,
      showDivisorLines,
      fractPoints,
      fractAnimationPointsTop,
      fractAnimationPointsBelow,
    },
    ref
  ) => {
    useEffect(
      () =>
        ref?.current &&
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      [ref, maxWidth]
    );
    return (
      <svg
        width={maxWidth}
        height={maxHeight}
        viewBox={`0 0 ${maxWidth} ${maxHeight}`}
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
      >
        {(showDividendLines || showDivisorLines) && dividendAnimationPoints && (
          <path
            className="line-path stroke-cyan-200"
            strokeWidth={"30"}
            d={`M${dividendAnimationPoints}z`}
            pathLength="1"
          />
        )}
        {linePoints && (
          <path
            className="line-path fill-transparent stroke-black"
            strokeWidth="2"
            d={`M${linePoints}z`}
            pathLength="1"
            strokeLinecap="round"
          />
        )}

        {showDividendLines &&
          dividendPoints.length > 0 &&
          dividendPoints.map(({ x1 }, index) => (
            <g key={x1}>
              <path
                key={x1}
                className="line-path fill-slate-200 stroke-blue-800"
                strokeWidth={`${width < 1024 ? "3" : "10"}`}
                d={`M${x1},${maxHeight / 2 - 50} ${x1},${maxHeight / 2 - 15}z`}
                pathLength="1"
              />
              <text
                className="fill-blue-800 text-sm max-[1024px]:text-xs font-bold"
                x={index ? x1 - 15 : 0}
                y={maxHeight / 2 - 60}
              >
                {index}
              </text>
            </g>
          ))}

        {showDivisorLines &&
          divisorPoints.length > 0 &&
          divisorPoints.map(
            ({ x1 }, index) =>
              index !== 0 && (
                <path
                  key={x1}
                  className="line-path fill-slate-200 stroke-red-800"
                  strokeWidth={`${width < 1024 ? "1" : "10"}`}
                  d={`M${x1},${maxHeight / 2 - 15} ${x1},${
                    maxHeight / 2 + 15
                  }z`}
                  pathLength="1"
                  strokeLinecap="round"
                />
              )
          )}

        {showDivisorLines &&
          fractPoints.length > 0 &&
          fractPoints.map(({ x1 }) => (
            <path
              key={x1}
              className="line-path fill-slate-200 stroke-black"
              strokeWidth="1"
              d={`M${x1},${maxHeight / 2 - 15} ${x1},${maxHeight / 2 + 15}z`}
              pathLength="1"
              strokeLinecap="round"
            />
          ))}

        {result && divisorAnimationPointsTop && (
          <path
            className="curve-path fill-transparent stroke-black"
            strokeWidth="2"
            d={`M${divisorAnimationPointsTop}`}
            pathLength="1"
          />
        )}
        {result && divisorAnimationPointsBelow && (
          <path
            className="curve-path fill-transparent stroke-black"
            strokeWidth="2"
            d={`M${divisorAnimationPointsBelow}`}
            pathLength="1"
          />
        )}
        {result && fractAnimationPointsTop && (
          <path
            className="partial-curve-path fill-transparent stroke-purple-600"
            strokeWidth="2"
            d={`M${fractAnimationPointsTop}`}
            pathLength="1"
          />
        )}
        {result && fractAnimationPointsBelow && (
          <path
            className="partial-curve-path fill-transparent stroke-purple-600"
            strokeWidth="2"
            d={`M${fractAnimationPointsBelow}`}
            pathLength="1"
          />
        )}
      </svg>
    );
  }
);
export default LineNumberImage;
