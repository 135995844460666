import React from "react";
import DrawPolygon from "../DrawPolygon/DrawPolygon";
import CurrentLevel from "../../CurrentLevel/CurrentLevel";
import {
  useLinesL,
  useLinesU,
  useLinesH,
  useLinesRandom,
  usePolyLevels,
} from "../../../hooks";
import SignUpAndVideoLinks from "../../SignUpAndVideoLinks/SignUpAndVideoLinks";

const Polygon = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  setChallengeLevel,
  showTabs,
  setShowSignin,
  authData,
  authChecked,
  setLogoutConfirm,
  saveScores,
}) => {
  const [
    randomPoly,
    level,
    handleNext,
    disableNext,
    linesDrawn,
    groupsStatus,
    setGroupsStatus,
    showInstructions,
    setShowInstructions,
    setChallengeSolved,
    challengeSolved,
  ] = usePolyLevels({
    score,
    challengeLevel,
  });

  return (
    <div className="absolute top-auto right-0 left-0">
      {randomPoly && (
        <>
          <CurrentLevel
            challengeLevel={challengeLevel}
            setChallengeLevel={setChallengeLevel}
            handleNext={handleNext}
            disableNext={disableNext}
            linesDrawn={linesDrawn}
            challengeSolved={challengeSolved}
            showTabs={showTabs}
          />
          <div className="flex flex-col place-content-center">
            <SignUpAndVideoLinks
              vid={"j72JD2ArBTQ"}
              showInstructions={showInstructions}
              setShowInstructions={setShowInstructions}
              setShowSignin={setShowSignin}
              authData={authData}
              authChecked={authChecked}
              setLogoutConfirm={setLogoutConfirm}
            />

            <div className="self-center">
              <DrawPolygon
                level={level[challengeLevel]}
                challengeLevel={challengeLevel}
                solved={solved}
                setSolved={setSolved}
                score={score}
                setScore={setScore}
                getLines={
                  randomPoly === 1
                    ? useLinesL
                    : randomPoly === 2
                    ? useLinesU
                    : randomPoly === 3
                    ? useLinesH
                    : randomPoly === 4 && useLinesRandom
                }
                linesDrawn={linesDrawn}
                groupsStatus={groupsStatus}
                setGroupsStatus={setGroupsStatus}
                setChallengeSolved={setChallengeSolved}
                challengeSolved={challengeSolved}
                saveScores={saveScores}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Polygon;
