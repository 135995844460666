import Fractions from "../../Fractions/Fractions";

const RateColumn = ({ k, kNumerator, unitRate, challengeLevel, reverse }) => (
  <td className="w-24 border-2 border-black text-center font-bold">
    {challengeLevel < 2 ? (
      1
    ) : challengeLevel === 2 ? (
      <div className="flex flex-wrap place-content-center">
        <Fractions
          numerator={reverse ? kNumerator : k}
          denominator={reverse ? k : kNumerator}
          small={false}
        />
        {unitRate && (
          <div className="p-1 text-xs max-[300px]:text-[12px] self-center flex flex-row place-content-center">
            <div className="self-center">&nbsp;or&nbsp;</div>
            <div className="self-center">{unitRate?.w}</div>
            <div className="self-center">
              <Fractions
                numerator={unitRate?.rn}
                denominator={unitRate?.rd}
                small={false}
              />
            </div>
          </div>
        )}
      </div>
    ) : reverse ? (
      <Fractions numerator={kNumerator} denominator={k} small={false} />
    ) : (
      k
    )}
  </td>
);

export default RateColumn;
