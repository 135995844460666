import React from "react";
import TableHeaders1 from "./TableHeaders1";
import TableHeaders2 from "./TableHeaders2";

const TableHeaders = ({ challengeLevel, item, carrier, result }) => {
  const headerProps = { challengeLevel, item, carrier, result };
  return challengeLevel === 1 ? (
    <TableHeaders2 {...headerProps} />
  ) : (
    <TableHeaders1 {...headerProps} />
  );
};
export default TableHeaders;
