import { Fragment, useState, useRef } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { BsFillEmojiNeutralFill } from "react-icons/bs";
import { useTimeoutFn } from "react-use";

const AntiConfetti = ({
  showAntiConfetti,
  setShowAntiConfetti,
  afterEffects = () => {},
}) => {
  const [emoAnimate, setEmoAnimate] = useState(false);
  let closeButtonRef = useRef(null);
  useTimeoutFn(() => setEmoAnimate(true), 1000);
  useTimeoutFn(() => setShowAntiConfetti(false), 3000);
  const closeModal = () => setShowAntiConfetti(false);

  return (
    <>
      <Transition appear show={showAntiConfetti} as={Fragment}>
        <Dialog
          as="div"
          initialFocus={closeButtonRef}
          className="relative z-40"
          onClose={closeModal}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={afterEffects}
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-1000"
                enterFrom="opacity-100 scale-0"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-1000"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-100 scale-0"
              >
                <DialogPanel
                  className={`max-[1024px]:w-48 max-[1024px]:h-48 w-96 h-96 place-content-center max-w-md transform overflow-hidden rounded-full bg-black shadow-xl transition-all
                  ${emoAnimate && "animate-pulseTwo"}
                  `}
                >
                  <button
                    type="button"
                    ref={closeButtonRef}
                    className="absolute right-0 top-0 p-2 outline-none hidden"
                  ></button>
                  <DialogTitle
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  ></DialogTitle>
                  <div>
                    <BsFillEmojiNeutralFill className="text-yellow-200 w-96 h-96 max-[1024px]:w-48 max-[1024px]:h-48" />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default AntiConfetti;
