import { BsArrowsCollapse, BsArrowsExpand } from "react-icons/bs";
import { FaPercent } from "react-icons/fa";

const TableTop = ({
  startDate,
  endDate,
  collapse,
  setCollapse,
  setShowPercent,
  showPercent,
}) => (
  <div className="max-[1024px]:text-xs px-2 h-fit sticky top-0 self-center w-full text-center flex flex-row place-content-center">
    <div className="self-center w-1/6 hidden"></div>
    <div className="self-center w-full h-full bg-lime-200 flex flex-wrap place-content-between">
      <button
        onClick={() => setShowPercent(() => !showPercent)}
        className={`text-sky-700 self-center w-5 h-5 p-1 max-[1024px]:text-xs flex place-content-center bg-opacity-50 ${
          showPercent
            ? "bg-red-600 hover:bg-red-900"
            : "bg-green-600 hover:bg-green-900"
        }`}
      >
        <FaPercent className="self-center" />
      </button>
      {startDate ? (
        <p className="self-center font-bold text-sky-700">
          {new Date(`${startDate} 00:00:00.000`)
            .toDateString("default", {
              month: "long",
            })
            .substring(4)}
          {endDate &&
            endDate !== startDate &&
            ` -
                    ${new Date(`${endDate} 00:00:00.000`)
                      .toDateString("default", {
                        month: "long",
                      })
                      .substring(4)}`}
        </p>
      ) : (
        <p className="self-center font-bold text-sky-700">To Date</p>
      )}
      <button
        onClick={() => setCollapse(() => !collapse)}
        className={`text-sky-700 self-center w-5 max-[1024px]:text-xl text-2xl h-5 p-1 max-[1024px]:text-xs flex place-content-center bg-opacity-50 ${
          collapse
            ? "bg-red-600 hover:bg-red-900"
            : "bg-green-600 hover:bg-green-900"
        }`}
      >
        {collapse ? <BsArrowsExpand /> : <BsArrowsCollapse />}
      </button>
    </div>
  </div>
);
export default TableTop;
