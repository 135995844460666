import GCFImage from "../GCFImage/GCFImage";
import MessageModal from "../../MessageModal/MessageModal";
import Confetti from "react-confetti";
import ConfettiEmo from "../../ConfettiEmo/ConfettiEmo";

const FactorTrees = ({
  showSolution,
  firstNumber,
  firstNumberTree,
  firstSvgDim,
  secondNumber,
  secondNumberTree,
  secondSvgDim,
  checkFactorInput,
  inputChange,
  checkFactor,
  firstTreeComplete,
  errorModal,
  setErrorModal,
  message,
  showConfettiEmo,
  width,
  height,
  setShowConfettiEmo,
  partyItems,
  setPartyItems,
}) => {
  return (
    <div className="self-center w-full">
      <div className="flex flex-col place-content-center p-5">
        {!showSolution && (
          <div className="self-center flex flex-wrap place-content-evenly font-bold text-blue-900 w-full">
            <p className="bg-yellow-300">
              Directions: After entering a factor press “enter” and choose
              either P for a prime number or C for a composite number.
            </p>
            <button
              onClick={() => setPartyItems([])}
              className="text-blue-900 outline-none font-bold hover:text-green-600 focus:text-green-600"
            >
              Change Party Items
            </button>
          </div>
        )}

        <div className="border-2 border-black self-center flex flex-row place-content-evenly w-full">
          <GCFImage
            mainNumber={firstNumber}
            mainNumberType={"first"}
            numberTree={firstNumberTree}
            bgColor={"bg-blue-100"}
            svgDim={firstSvgDim}
            checkFactorInput={checkFactorInput}
            inputChange={inputChange}
            checkFactor={checkFactor}
            item={[partyItems[0]]}
          />

          <GCFImage
            mainNumber={secondNumber}
            mainNumberType={"second"}
            numberTree={secondNumberTree}
            bgColor={"bg-red-100"}
            svgDim={secondSvgDim}
            checkFactorInput={checkFactorInput}
            inputChange={inputChange}
            checkFactor={checkFactor}
            firstTreeComplete={firstTreeComplete}
            item={[partyItems[1]]}
          />
        </div>
      </div>
      <MessageModal
        openModal={errorModal}
        closeModal={() => setErrorModal(false)}
        message={message}
      />
      {showConfettiEmo && (
        <>
          <Confetti
            numberOfPieces={200}
            recycle={false}
            width={width}
            height={height}
          />
          <ConfettiEmo
            showConfettiEmo={showConfettiEmo}
            setShowConfettiEmo={setShowConfettiEmo}
            afterEffects={() => {}}
          />
        </>
      )}
    </div>
  );
};
export default FactorTrees;
