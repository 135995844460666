import PartyItems from "../PartyItems/PartyItems";
import "./style.css";

const PartyBags = ({ solution, firstNumber, secondNumber, items, coords }) => {
  return (
    <div className="flex flex-wrap place-content-center self-center">
      {[...new Array(solution)].map((i, index) => (
        <div key={index} className="self-center">
          <svg width={150} height={130} xmlns="http://www.w3.org/2000/svg">
            <PartyItems coords={coords} items={items} />
            {firstNumber && (
              <text x={40} y={50} className="fill-black font-black text-sm">
                {firstNumber / solution}
              </text>
            )}
            {secondNumber && (
              <text x={95} y={50} className="fill-black font-black text-sm">
                {secondNumber / solution}
              </text>
            )}

            <rect
              className="stroke-red-600 fill-black/10"
              x="5"
              y="28"
              width="140"
              height="100"
              strokeWidth="5"
              rx="30"
              ry="30"
            />
            <path
              className="fill-transparent stroke-red-600"
              strokeWidth="2"
              d={`M35,26 Q68,0 120,26 `}
              pathLength="1"
            />
          </svg>
        </div>
      ))}
    </div>
  );
};
export default PartyBags;
