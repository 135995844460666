import { FaBaseballBatBall } from "react-icons/fa6";
import { BsCookie } from "react-icons/bs";
import { TbHorseToy } from "react-icons/tb";
import { MdToys } from "react-icons/md";
import { TbFidgetSpinner } from "react-icons/tb";
import { IoGameController } from "react-icons/io5";
import { GiHeartEarrings } from "react-icons/gi";
import { GiLipstick } from "react-icons/gi";
import { PiBookFill } from "react-icons/pi";
import { GiFairyWand } from "react-icons/gi";

const partyBagItems = [
  {
    mainIcon: <FaBaseballBatBall className="self-center w-1/2 text-sky-700" />,
    color: "text-sky-700",
    label: "Baseball cards",
  },
  {
    mainIcon: (
      <BsCookie className="self-center w-full h-full text-yellow-800 bg-yellow-600 rounded-full" />
    ),
    color: "text-yellow-800",
    label: "Cookies",
  },
  {
    mainIcon: (
      <TbHorseToy className="self-center w-full h-full text-pink-400" />
    ),
    color: "text-pink-400",
    label: "Ponies",
  },
  {
    mainIcon: <MdToys className="self-center w-full h-full text-blue-600" />,
    color: "text-blue-600",
    label: "Cars",
  },
  {
    mainIcon: (
      <TbFidgetSpinner className="self-center w-full h-full text-black" />
    ),
    color: "text-black",
    label: "Fidgets",
  },
  {
    mainIcon: (
      <GiLipstick className="self-center w-full h-full text-rose-600" />
    ),
    color: "text-rose-600",
    label: "Makeup",
  },
  {
    mainIcon: (
      <GiFairyWand className="self-center w-full h-full text-yellow-600" />
    ),
    color: "text-yellow-600",
    label: "Fairy Wands",
  },
  {
    mainIcon: (
      <GiHeartEarrings className="self-center w-full h-full text-zinc-200 bg-black" />
    ),
    color: "text-zinc-200",
    label: "Jewelry",
    cn: "bg-black",
  },
  {
    mainIcon: (
      <PiBookFill className="self-center w-full h-full text-slate-500" />
    ),
    color: "text-slate-500",
    label: "Books",
  },
  {
    mainIcon: (
      <IoGameController className="self-center w-full h-full text-green-600" />
    ),
    color: "text-green-600",
    label: "Video Games",
  },
];
export default partyBagItems;
