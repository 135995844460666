import React, { forwardRef } from "react";
import UnitRateRow from "./UnitRateRow";
import TableHeaders from "./TableHeaders";
import TableRows from "./TableRows";

const ItemCarrierTable = forwardRef(
  (
    {
      selected,
      item,
      carrier,
      itemCarriers,
      columnValue,
      checkVal,
      onChangeItem,
      result,
      k,
      kNumerator,
      challengeLevel,
      unitRate,
      expandCollapse,
      setMove,
      setDisableNext,
      showTable,
      setShowItems,
      setShowLN,
    },
    ref
  ) => (
    <div
      className={`${
        !showTable ? "leave" : "table-enter"
      } flex flex-col place-content-center p-5 max-[1024px]:p-0`}
    >
      <table className="table-fixed text-blue-900" ref={ref}>
        <thead>
          <TableHeaders
            challengeLevel={challengeLevel}
            item={item}
            carrier={carrier}
            result={result}
          />
        </thead>
        <tbody>
          {itemCarriers.length > 0 &&
            itemCarriers.map(
              (
                {
                  items,
                  carriers,
                  itemsQ,
                  carriersQ,
                  status,
                  estimate,
                  expand,
                  ref,
                },
                index
              ) => (
                <TableRows
                  key={index}
                  items={items}
                  carriers={carriers}
                  itemsQ={itemsQ}
                  carriersQ={carriersQ}
                  carrier={carrier}
                  item={item}
                  status={status}
                  estimate={estimate}
                  index={index}
                  challengeLevel={challengeLevel}
                  checkVal={checkVal}
                  onChangeItem={onChangeItem}
                  columnValue={columnValue}
                  result={result}
                  kNumerator={kNumerator}
                  k={k}
                  expand={expand}
                  expandCollapse={expandCollapse}
                  ref={ref}
                />
              )
            )}
          <UnitRateRow
            result={result}
            challengeLevel={challengeLevel}
            k={k}
            kNumerator={kNumerator}
            unitRate={unitRate}
          />
        </tbody>
      </table>
    </div>
  )
);
export default ItemCarrierTable;
