import { createRef } from "react";
import { FaBoxOpen } from "react-icons/fa6";
import { FaShoppingBag } from "react-icons/fa";
import { BsFillBasketFill } from "react-icons/bs";
const carriers = [
  {
    mainIcon: <FaBoxOpen className="text-[#a8896c] text-7xl" />,
    ref: createRef(),
    iconRegular: <FaBoxOpen className="text-[#a8896c] text-7xl" />,
    iconSmall: (
      <FaBoxOpen className="text-[#a8896c] text-base max-[1024px]:text-xs" />
    ),
    label: "Boxes",
    color: "text-[#a8896c]",
    fill: "fill-[#a8896c]",
    stroke: "stroke-[#a8896c]",
  },
  {
    mainIcon: <FaShoppingBag className="text-black text-7xl" />,
    ref: createRef(),
    iconRegular: <FaShoppingBag className="text-black text-7xl" />,
    iconSmall: (
      <FaShoppingBag className="text-black text-base max-[1024px]:text-xs" />
    ),
    label: "Bags",
    color: "text-black",
    fill: "fill-black",
    stroke: "stroke-black",
  },
  {
    mainIcon: <BsFillBasketFill className="text-[#8e7b58] text-7xl" />,
    ref: createRef(),
    iconRegular: <BsFillBasketFill className="text-[#8e7b58] text-7xl" />,
    iconSmall: (
      <BsFillBasketFill className="text-[#8e7b58] text-base max-[1024px]:text-xs" />
    ),
    label: "Baskets",
    color: "text-[#8e7b58]",
    fill: "fill-[#8e7b58]",
    stroke: "stroke-[#8e7b58]",
  },
];
export default carriers;
