import UnitRateRow1 from "./UnitRateRow1";
import UnitRateRow2 from "./UnitRateRow2";
const UnitRateRow = ({ challengeLevel, result, k, kNumerator, unitRate }) => {
  return challengeLevel < 1 ? (
    <>
      <UnitRateRow1
        result={result}
        challengeLevel={challengeLevel}
        k={k}
        kNumerator={kNumerator}
        unitRate={unitRate[0]}
      />
      <UnitRateRow2
        result={result}
        challengeLevel={challengeLevel}
        k={k}
        kNumerator={kNumerator}
        unitRate={unitRate[1]}
      />
    </>
  ) : (
    <>
      <UnitRateRow2
        result={result}
        challengeLevel={challengeLevel}
        k={k}
        kNumerator={kNumerator}
        unitRate={unitRate[1]}
      />
      <UnitRateRow1
        result={result}
        challengeLevel={challengeLevel}
        k={k}
        kNumerator={kNumerator}
        unitRate={unitRate[0]}
      />
    </>
  );
};
export default UnitRateRow;
