import { useState, useEffect, useCallback } from "react";
import orderBy from "lodash.orderby";
import gameLabels from "../icons/gameLabels";
const keys = require("../keys.js");

const useGetStudentsData = ({ appCheckToken, authData }) => {
  const [allUsersData, setAllUsersData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [scores, setScores] = useState([]);
  const [noScores, setNoScores] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [fetchComplete, setFetchComplete] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [showPercent, setShowPercent] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const getAllScores = useCallback(async () => {
    if (authData?.role.type === "teacher") {
      setShowLoader(true);
      setFetchComplete(false);
      try {
        const postData = await appCheckToken();
        postData.body = JSON.stringify({ school: authData?.role.school });

        const request = new Request(
          keys.cloudFunctions.getAllUserScores,
          postData
        );
        const response = await fetch(request);
        const allUsersData = await response.json();
        setFetchComplete(true);
        if (!allUsersData?.error) {
          const usersWithGames = allUsersData?.allUserGames.filter(
            (a) => a.games
          );
          usersWithGames.map((u) => (u.show = false));
          usersWithGames.map((a) => (a.userLowerCase = a.user.toLowerCase()));
          const userSorted = orderBy(
            [...usersWithGames],
            ["userLowerCase"],
            ["asc"]
          );
          setAllUsersData(userSorted);
          setScores(userSorted);
        } else {
          setAllUsersData([]);
          setScores([]);
        }
      } catch (error) {
        setFetchComplete(true);
        setAllUsersData([]);
        setScores([]);
      }
    }
  }, [appCheckToken, authData?.role]);

  const filterScoresByDate = useCallback(
    ({ startDate, endDate }) => {
      const unixStartDateStamp = Math.floor(
        new Date(`${startDate} 00:00:00.000`).getTime() / 1000
      );
      const unixEndDateStamp = endDate
        ? Math.floor(new Date(`${endDate} 00:00:00.000`).getTime() / 1000)
        : unixStartDateStamp;
      const current = [...allUsersData];
      let filtered = [];
      current.map((gs) =>
        gameLabels.forEach(({ keyName }, index) => {
          for (const [key, value] of Object.entries(
            gs.games[keyName]?.datesPlayed || {}
          )) {
            if (
              parseInt(key) >= unixStartDateStamp &&
              parseInt(key) <= unixEndDateStamp
            ) {
              filtered = [
                ...filtered,
                {
                  user: (
                    <div>
                      <p>{gs.user}</p>
                    </div>
                  ),
                  userId: gs.user,
                  show: gs.show,
                  combined: `${gs.user}${key}`,
                  date: key,
                  games: {
                    [keyName]: {
                      score: value.scoreOnDate,
                      solved: value.solvedOnDate,
                    },
                  },
                },
              ];
            }
          }
        })
      );

      const mergedGames = Object.groupBy(filtered, ({ combined }) => combined);
      let newSet = [];
      for (const [, value] of Object.entries(mergedGames)) {
        let games = {};
        value.forEach((v) => {
          games = { ...games, ...v.games };
        });
        gameLabels.forEach(({ keyName }) => {
          if (!Object.hasOwn(games, `${keyName}`)) {
            games = {
              ...games,
              ...{
                [keyName]: {
                  score: { level1: 0, level2: 0, level3: 0, overAll: 0 },
                  solved: { level1: 0, level2: 0, level3: 0, overAll: 0 },
                },
              },
            };
          }
        });
        newSet = [
          ...newSet,
          {
            user: value[0].user,
            userId: value[0].userId,
            show: value[0].show,
            games,
          },
        ];
      }
      const newMergedGames = Object.groupBy(newSet, ({ userId }) => userId);
      let final = [];
      for (const [, value] of Object.entries(newMergedGames)) {
        let games = {};
        value.forEach((v, index) => {
          if (index === 0) {
            games = structuredClone(v.games);
          } else {
            gameLabels.forEach(({ keyName }) => {
              ["level1", "level2", "level3", "overAll"].forEach((l) => {
                games[keyName].score[l] =
                  games[keyName].score[l] + v.games[keyName].score[l];
                games[keyName].solved[l] =
                  games[keyName].solved[l] + v.games[keyName].solved[l];
              });
            });
          }
        });
        final = [...final, { games, user: value[0].user, show: value[0].show }];
      }
      if (final.length === 0) {
        setNoScores(true);
      } else {
        setNoScores(false);
      }
      setScores(final);
    },
    [allUsersData]
  );

  const clear = useCallback(() => {
    let gamesList = [];
    for (const [, value] of Object.entries(allUsersData || {})) {
      gamesList = [...gamesList, Object.keys(value)].flat();
    }
    if (allUsersData.length === 0 || !gamesList.includes("games")) {
      setNoScores(true);
    } else {
      setNoScores(false);
    }
    setScores(allUsersData);
    setStartDate("");
  }, [allUsersData]);

  useEffect(() => {
    getAllScores();
  }, [getAllScores]);

  useEffect(() => {
    setEndDateError(false);
    setScores([]);
    if (!startDate) {
      setEndDate("");
      clear();
    } else {
      setNoScores(false);
      filterScoresByDate({ startDate, endDate });
    }
    if (endDate && endDate < startDate) {
      setEndDateError(true);
    }
  }, [startDate, endDate, clear, filterScoresByDate]);

  const toggleUser = ({ user, show }) => {
    setScores((scores) =>
      scores.map((s) => {
        if (s.user === user) {
          s.show = show;
        }
        return s;
      })
    );
  };

  useEffect(
    () =>
      setScores((scores) =>
        scores.map((s) => {
          s.show = !collapse;
          return s;
        })
      ),
    [collapse]
  );

  useEffect(() => {
    if (scores.length > 0) {
      if ([...scores].every(({ show }) => !show)) setCollapse(true);
      if ([...scores].every(({ show }) => show)) setCollapse(false);
    }
  }, [scores]);

  useEffect(() => {
    let timerId;
    if (fetchComplete && showLoader) {
      timerId = setTimeout(() => setShowLoader(false), [1000]);
    }
    return () => clearTimeout(timerId);
  }, [fetchComplete, showLoader]);

  return [
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    scores,
    setScores,
    clear,
    noScores,
    endDateError,
    setEndDateError,
    gameLabels,
    toggleUser,
    collapse,
    setCollapse,
    showPercent,
    setShowPercent,
    showLoader,
  ];
};
export default useGetStudentsData;
