const ConnectingLines = ({
  coords,
  svgDim,
  pos,
  row,
  animate,
  factor1Type,
  factor2Type,
}) => (
  <g>
    <path
      className={`${animate && "connecting-line-path"} fill-none ${
        factor1Type === "p" ? "stroke-lime-500" : "stroke-purple-600"
      } stroke-2`}
      d={`M${svgDim.width * ((pos + (pos - 1)) / Math.pow(2, row + 1 + 1))},${
        (row + 1) * 100
      } ${coords.x + 35},${coords.y + 70} `}
      pathLength="1"
    />

    <path
      className={`${animate && "connecting-line-path"} fill-none ${
        factor2Type === "p" ? "stroke-lime-500" : "stroke-purple-600"
      } stroke-2`}
      d={`M${svgDim.width * ((pos + (pos + 1)) / Math.pow(2, row + 1 + 1))},${
        (row + 1) * 100
      } ${coords.x + 35},${coords.y + 70}`}
      pathLength="1"
    />
  </g>
);
export default ConnectingLines;
