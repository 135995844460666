import { forwardRef } from "react";
import ColumnData1n2 from "./ColumnData1n2";
import ColumnData3 from "./ColumnData3";
import ColumnDataK from "./ColumnDataK";
import { useGetColumnProps } from "../../../hooks";

const TableRows = forwardRef(
  (
    {
      items,
      carriers,
      itemsQ,
      carriersQ,
      status,
      estimate,
      index,
      challengeLevel,
      checkVal,
      onChangeItem,
      columnValue,
      carrier,
      item,
      result,
      kNumerator,
      k,
      expand,
      expandCollapse,
    },
    ref
  ) => {
    const [columnProps1, columnProps2, column3Props, columnKProps] =
      useGetColumnProps({
        carrier,
        carriers,
        carriersQ,
        challengeLevel,
        checkVal,
        columnValue,
        estimate,
        expandCollapse,
        expand,
        index,
        item,
        items,
        itemsQ,
        k,
        kNumerator,
        onChangeItem,
        ref,
        result,
        status,
      });
    return (
      <tr key={items}>
        <ColumnData1n2 {...columnProps1} />
        <ColumnDataK {...columnKProps} />
        <ColumnData1n2 {...columnProps2} />
        <ColumnData3 {...column3Props} />
      </tr>
    );
  }
);
export default TableRows;
