import { useState } from "react";
import { CgClose } from "react-icons/cg";
import { BsInfoCircleFill } from "react-icons/bs";

const LandingPageImages = ({ apod }) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div
      className={`mt-5 ${
        showInfo && "bg-black"
      } overflow-scroll py-10 h-fit flex flex-col place-content-center max-[300px]:text-[12px] max-[1024px]:text-xs text-base`}
    >
      <p className="self-center text-[#DFCFBE] text-center font-bold">
        Astronomy picture of the day
      </p>
      <div className="self-center flex flex-row place-content-center overflow-scroll">
        <div className="self-center p-2 relative">
          <a
            tabIndex="-1"
            target="_blank"
            rel="noreferrer"
            href="https://www.nasa.gov"
          >
            <button
              className={`outline-none self-center float-left ${
                apod.media_type === "image"
                  ? "max-[1024px]:w-48 w-72"
                  : showInfo
                  ? "max-[1024px]:w-48 w-72"
                  : "w-11/12"
              } mr-5 focus:border-[#00c6fc] hover:border-[#00c6fc] border-blue-200 border-8 border-double`}
            >
              {apod.media_type === "image" ? (
                <img alt={apod.title} src={apod.url} />
              ) : (
                <section className="w-full mx-auto">
                  <iframe
                    className="w-full sm:aspect-[4/3]"
                    width="760"
                    height="415"
                    src={apod.url}
                    title="Apod"
                    allowFullScreen
                    allow="autoplay"
                  ></iframe>
                </section>
              )}
            </button>
          </a>
          <button
            onClick={() => setShowInfo(!showInfo)}
            className={`${
              showInfo
                ? "text-red-600 border-2 border-red-600 rounded-full bg-red-300 focus:text-red-300 focus:bg-red-600"
                : "text-sky-300 focus:text-[#00c6fc]"
            } border-0 text-lg absolute top-0 right-1 transition ease-in-out delay-150 outline-none focus:translate-y-1 focus:scale-110 hover:translate-y-1 hover:scale-110 duration-300`}
          >
            {showInfo ? <CgClose /> : <BsInfoCircleFill />}
          </button>
          <div
            className={`mt-5 text-[#DFCFBE] bg-black font-medium ${
              showInfo ? "block" : "hidden"
            }`}
          >
            {apod.title}: {apod.explanation}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LandingPageImages;
