import BlockSlider from "../BlockSlider/BlockSlider";
import ResultTable from "../ResultTable/ResultTable";
import TableBlocks from "../TableBlocks/TableBlocks";

const FractionBarTable = ({
  addBlock,
  removeBlock,
  setDropIndex,
  dropped,
  boxWidth,
  slide,
  dropIndex,
  dragged,
  tableRowKeyEvent,
  slideComplete,
  touchEleCoords,
  setTouchEleCoords,
  fractionBlockTouchMove,
  fractioBlockTouchEnd,
  tableBlockDrag,
  showSolution,
  displayLock,
  blockHeight,
  boxWidth2,
  challengeLevel,
}) =>
  [...new Array(challengeLevel > 0 ? 2 : 5)].map((a, index) => (
    <div
      key={index}
      autoFocus={true}
      id={`row${index}`}
      tabIndex={dropped[index]?.rowLocked ? -1 : 0}
      style={{
        width: challengeLevel > 0 ? boxWidth2 : boxWidth,
        height: blockHeight,
      }}
      className={`bg-green-200 self-center border border-slate-900 ${
        index < (challengeLevel > 0 ? 1 : 4) && "border-b-0"
      } ${
        index === 0 && "pointer-events-none"
      } flex flex-row place-content-start focus:bg-green-300 focus:outline-none`}
      onDrop={(event) => {
        event?.preventDefault();
        addBlock(index);
      }}
      onDropCapture={() => setDropIndex(index)}
      onKeyDown={(event) => tableRowKeyEvent({ event, index })}
    >
      <TableBlocks
        rowIndex={index}
        showSolution={showSolution}
        dropped={dropped}
        tableBlockDrag={tableBlockDrag}
        dropIndex={dropIndex}
        removeBlock={removeBlock}
        touchEleCoords={touchEleCoords}
        setTouchEleCoords={setTouchEleCoords}
        fractionBlockTouchMove={fractionBlockTouchMove}
        fractioBlockTouchEnd={fractioBlockTouchEnd}
        boxWidth={boxWidth}
        blockHeight={blockHeight}
      />
      {displayLock && (
        <ResultTable
          rowIndex={index}
          showSolution={showSolution}
          dropped={dropped}
          slideComplete={slideComplete}
          boxWidth={boxWidth}
          blockHeight={blockHeight}
        />
      )}
      {index > 0 && (
        <BlockSlider
          dragged={dragged}
          boxWidth={boxWidth}
          slide={slide}
          rowIndex={index}
          dropIndex={dropIndex}
          slideComplete={slideComplete}
          blockHeight={blockHeight}
        />
      )}
    </div>
  ));
export default FractionBarTable;
