import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { useGetAngleText } from "../../../hooks";

const AnglesImageText = ({
  equation,
  showText,
  angleType,
  supplementaryType,
  r,
  quadrant,
  setDisableNext,
}) => {
  const [rightAngle, textYellow, textViolet] = useGetAngleText({
    quadrant,
    angleType,
    r,
    supplementaryType,
  });
  return (
    <Transition
      as={Fragment}
      show={showText}
      enter="transform transition duration-[1500ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterEnter={() => setDisableNext(false)}
    >
      <g>
        <text
          className="fill-yellow-300 font-bold text-base max-[300px]:text-[10px]"
          x={5}
          y={15}
        >
          yellow:&nbsp;
          {equation.solution?.yellow.split ? (
            <tspan y={15}>
              {Math.abs(equation.solution?.yellow.split)} <tspan y="4">.</tspan>
              <tspan y={15}>
                {equation.solution?.yellow.answer < 0 ? "-" : "+"}&nbsp;
              </tspan>
            </tspan>
          ) : (
            ""
          )}
          <tspan
            className={`${
              equation.solution?.yellow.displayAngle === "x" && "italic"
            }`}
          >
            {equation.solution?.yellow.displayAngle}
          </tspan>
          <tspan y="4">.</tspan>
        </text>
        <text
          className="fill-violet-300 font-bold text-base max-[300px]:text-[10px]"
          x={5}
          y={r - 11}
        >
          violet:&nbsp;
          {equation.solution?.violet.split ? (
            <tspan y={r - 11}>
              {Math.abs(equation.solution?.violet.split)}{" "}
              <tspan y={r - 25}>.</tspan>
              <tspan y={r - 11}>
                {equation.solution?.violet.answer < 0 ? "-" : "+"}&nbsp;
              </tspan>
            </tspan>
          ) : (
            ""
          )}
          <tspan
            className={`${
              equation.solution?.violet.displayAngle === "x" && "italic"
            }`}
          >
            {equation.solution?.violet.displayAngle}
          </tspan>
          <tspan y={r - 25}>.</tspan>
        </text>
        <text
          className={`
      fill-black font-black text-base max-[300px]:text-[10px]`}
          x={textYellow.x}
          y={textYellow.y}
        >
          {equation.solution?.yellow.split ? (
            <tspan>
              {Math.abs(equation.solution?.yellow.split)}
              <tspan y={textYellow.y - 15}>.</tspan>
              <tspan y={textYellow.y}>
                {equation.solution?.yellow.answer < 0 ? "-" : "+"}&nbsp;
              </tspan>
            </tspan>
          ) : (
            ""
          )}
          <tspan
            className={`${
              equation.solution?.yellow.displayAngle === "x" && "italic"
            }`}
          >
            {equation.solution?.yellow.displayAngle}
          </tspan>
          <tspan y={textYellow.y - 15}>.</tspan>
        </text>

        <text
          className={`fill-black font-black text-base max-[300px]:text-[10px]`}
          x={textViolet.x}
          y={textViolet.y}
        >
          {equation.solution?.violet.split ? (
            <tspan>
              {Math.abs(equation.solution?.violet.split)}
              <tspan y={textViolet.y - 15}>.</tspan>
              <tspan y={textViolet.y}>
                {equation.solution?.violet.answer < 0 ? "-" : "+"}&nbsp;
              </tspan>
            </tspan>
          ) : (
            ""
          )}
          <tspan
            className={`${
              equation.solution?.violet.displayAngle === "x" && "italic"
            }`}
          >
            {equation.solution?.violet.displayAngle}
          </tspan>
          <tspan y={textViolet.y - 15}>.</tspan>
        </text>
        {angleType === 2 && rightAngle && (
          <path
            className={`fill-transparent right-angle-path stroke-black stroke-2`}
            d={`${rightAngle}`}
            strokeLinejoin="miter-clip"
            strokeLinecap="square"
            pathLength="1"
          />
        )}
      </g>
    </Transition>
  );
};
export default AnglesImageText;
