import { forwardRef } from "react";
import {
  useGetWindowSize,
  useLineAnimation,
  useLineNumber,
} from "../../../hooks";
import "./style.css";
import Confetti from "react-confetti";
import AntiConfetti from "../../AntiConfetti/AntiConfetti";
import ConfettiEmo from "../../ConfettiEmo/ConfettiEmo";
import LineNumberImage from "../LineNumberImage/LineNumberImage";

const DrawNumberLine = forwardRef(
  (
    {
      divisor,
      divisorNumerator,
      dividend,
      result,
      showDividendLines,
      showDivisorLines,
    },
    ref
  ) => {
    const [maxWidth, maxHeight, width, height] = useGetWindowSize();

    const [
      dividendAnimationPoints,
      dividendPoints,
      divisorPoints,
      divisorAnimationPointsTop,
      divisorAnimationPointsBelow,
      linePoints,
      fractPoints,
      fractAnimationPointsTop,
      fractAnimationPointsBelow,
    ] = useLineNumber({
      divisor,
      divisorNumerator,
      dividend,
      maxWidth,
      maxHeight,
    });
    const [
      showAntiConfetti,
      setShowAntiConfetti,
      showConfettiEmo,
      setShowConfettiEmo,
      linesDrawn,
    ] = useLineAnimation({ result });
    const lineProps = {
      dividendAnimationPoints,
      dividendPoints,
      divisorPoints,
      divisorAnimationPointsTop,
      divisorAnimationPointsBelow,
      linePoints,
      fractPoints,
      maxWidth,
      maxHeight,
      width,
      result,
      showDividendLines,
      showDivisorLines,
      fractAnimationPointsTop,
      fractAnimationPointsBelow,
      ref,
    };

    return (
      <div className="flex flex-row place-content-center p-5">
        <LineNumberImage {...lineProps} />
        {linesDrawn && (
          <>
            {result === "You Got it!" && (
              <>
                <Confetti
                  numberOfPieces={200}
                  recycle={false}
                  width={width}
                  height={height}
                />
                <ConfettiEmo
                  showConfettiEmo={showConfettiEmo}
                  setShowConfettiEmo={setShowConfettiEmo}
                />
              </>
            )}
            {result === "Incorrect" && (
              <AntiConfetti
                showAntiConfetti={showAntiConfetti}
                setShowAntiConfetti={setShowAntiConfetti}
              />
            )}
          </>
        )}
      </div>
    );
  }
);
export default DrawNumberLine;
