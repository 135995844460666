import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";

const TableHeaders2 = ({ challengeLevel, item, carrier, result }) => (
  <tr className="bg-cyan-200">
    <th
      className={`w-24 border-2 border-black text-center ${
        challengeLevel === 0 ? carrier?.color : item?.color
      }`}
    >
      <div className="flex flex-col place-content-center">
        <div className="self-center flex flex-row place-content-center">
          <div className="self-center h-7 mr-1 text-base">x</div>
          <div className="self-center h-7">
            {challengeLevel === 0 ? carrier?.iconSmall : item?.iconSmall}
          </div>
        </div>

        <div className="self-center">
          {challengeLevel === 0 ? carrier?.label : item?.label}
        </div>
      </div>
    </th>
    <Transition
      as={Fragment}
      show={result ? true : false}
      enter="transform transition duration-[1000ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <th
        className={`w-28 border-2 border-black text-center text-green-900 text-base`}
      >
        y = kx
      </th>
    </Transition>
    <th
      className={`w-24 border-2 border-black text-center ${
        challengeLevel === 0 ? item?.color : carrier?.color
      }`}
    >
      <div className="flex flex-col place-content-center">
        <div className="self-center flex flex-row place-content-center">
          <div className="self-center h-7 mr-1 text-base">y</div>
          <div className="self-center h-7">
            {challengeLevel === 0 ? item?.iconSmall : carrier?.iconSmall}
          </div>
        </div>

        <div className="self-center">
          {challengeLevel === 0 ? item?.label : carrier?.label}
        </div>
      </div>
    </th>
  </tr>
);
export default TableHeaders2;
