import { useState, useEffect, useCallback, useRef } from "react";

const useGetAngleEquations = ({
  maxWidth,
  score,
  setScore,
  challengeLevel,
  solved,
  setSolved,
  saveScores,
}) => {
  const [disableNext, setDisableNext] = useState(false);
  const [next, setNext] = useState(false);
  const [angle, setAngle] = useState(0);
  const [r, setR] = useState(0);
  const [curve1, setCurve1] = useState("");
  const [curve2, setCurve2] = useState("");
  const [equation, setEquation] = useState({
    problem: null,
    solution: null,
    label: "",
  });
  const [scorePct, setScorePct] = useState({
    previousScore: "0%",
    currentScore: "0%",
  });
  const [showSolution, setShowSolution] = useState(false);
  const [result, setResult] = useState("");
  const [aVal, setAVal] = useState("");
  const [angleType, setAngleType] = useState(0);
  const [supplementaryType, setSupplementaryType] = useState(0);
  const [showText, setShowText] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showConfettiEmo, setShowConfettiEmo] = useState(false);
  const [showAntiConfetti, setShowAntiConfetti] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [quadrant, setQuadrant] = useState(null);
  const [curvePoints, setCurvePoints] = useState(null);
  const svgRef = useRef(null);
  const answerRef = useRef(null);
  const [draw, setDraw] = useState(false);
  const levelRef = useRef(challengeLevel);

  const handleNext = useCallback(() => {
    setNext(true);
    setDisableNext(true);
    setAngle(0);
    setR(0);
    setCurve1("");
    setCurve2("");
    const a = Math.floor(Math.random() * (3 - 1) + 1);
    setAngleType(a);
    setSupplementaryType(
      a === 1 && levelRef.current === 0
        ? Math.floor(Math.random() * (3 - 1) + 1)
        : levelRef.current > 0
        ? 1
        : 0
    );
    setAVal("");
    setResult("");
    setShowSolution(false);
    setShowInstructions(false);
    setQuadrant(Math.floor(Math.random() * (5 - 1) + 1));
    setCurvePoints(null);
    setEquation({
      problem: null,
      solution: null,
      label: "",
    });
  }, []);

  const checkAVal = () => {
    setDisableSubmit(true);
    setShowSolution(true);
    const tmpSolved = solved;
    const tmpScore = score;
    tmpSolved[`level${challengeLevel + 1}`] =
      tmpSolved[`level${challengeLevel + 1}`] + 1;
    tmpSolved.overAll = tmpSolved.overAll + 1;
    setSolved(tmpSolved);
    if (parseFloat(aVal) === Math.abs(equation.solution.correctAnswer)) {
      setResult("You Got it!");
      tmpScore[`level${challengeLevel + 1}`] =
        tmpScore[`level${challengeLevel + 1}`] + 1;
      tmpScore.overAll = score.overAll + 1;
      setScore(tmpScore);
      setShowConfettiEmo(true);
    } else {
      setResult("Incorrect");
      setShowAntiConfetti(true);
    }
    saveScores({ solved: tmpSolved, score: tmpScore, type: "angles" });

    const split = equation.solution[equation.solution.color].split;

    setEquation((equation) => {
      equation.solution[equation.solution.color].displayAngle =
        angleType === 1 && supplementaryType === 2
          ? 180 - equation.solution[equation.solution.color].angle
          : equation.solution[equation.solution.color].angle;
      equation.solution[equation.solution.color].split = 0;

      equation.solvedEquation =
        angleType === 1 && supplementaryType === 2 ? (
          <div className="flex place-content-center">
            <p>
              {equation.solution.correctAnswer}
              <sup className="self-center text-xl">.</sup>&nbsp;=&nbsp;
            </p>
            <p className="self-center">
              {equation.solution.correctAnswer}
              <sup className="text-xl">.</sup>
            </p>
          </div>
        ) : (
          <div className="flex place-content-center">
            <p>
              {equation.solution[equation.solution.color].angle}
              <sup className="self-center text-xl">.</sup>&nbsp;+&nbsp;
            </p>
            <p className="self-center">
              {equation.solution[equation.solution.other].angle}
              <sup className="text-xl">.</sup>&nbsp;=&nbsp;
            </p>
            <p className="self-center">
              {equation.solution.yellow.angle + equation.solution.violet.angle}
              <sup className="text-xl">.</sup>
            </p>
          </div>
        );
      if (challengeLevel > 0) {
        equation.solvedEquationVariant = (
          <div className="flex place-content-center">
            <p>
              {split}
              <sup className="self-center text-xl">.</sup>&nbsp;
              {equation.solution[equation.solution.color].answer < 0
                ? "-"
                : "+"}
              &nbsp;
              {Math.abs(equation.solution[equation.solution.color].answer)}
              <sup className="self-center text-xl">.</sup>&nbsp;+&nbsp;
              {equation.solution[equation.solution.other].angle}
              <sup className="self-center text-xl">.</sup>&nbsp;=&nbsp;
            </p>
            <p className="self-center">
              {equation.solution.yellow.angle + equation.solution.violet.angle}
              <sup className="text-xl">.</sup>
            </p>
          </div>
        );
      }
      return equation;
    });
  };

  useEffect(() => {
    if (angle && quadrant && angleType) {
      const angle2 = (angleType === 1 ? 180 : 90 * quadrant) - angle;
      const halfAngle = angle / 2;
      setCurvePoints(
        quadrant === 1
          ? {
              point1: angleType === 1 ? -halfAngle : 0,
              point2: angleType === 1 ? halfAngle : angle2,
              point3: angleType === 1 ? halfAngle : angle2,
              point4: angleType === 1 ? angle2 + halfAngle : 90,
            }
          : quadrant === 2
          ? {
              point1: angleType === 1 ? halfAngle : 90,
              point2: angleType === 1 ? angle2 + halfAngle : angle2,
              point3: angleType === 1 ? angle2 + halfAngle : angle2,
              point4: angleType === 1 ? 180 + halfAngle : 180,
            }
          : quadrant === 3
          ? {
              point1: angleType === 1 ? angle2 + halfAngle : 180,
              point2: angleType === 1 ? 180 + halfAngle : angle2,
              point3: angleType === 1 ? 180 + halfAngle : angle2,
              point4: angleType === 1 ? 180 + halfAngle + angle2 : 270,
            }
          : quadrant === 4 && {
              point1: angleType === 1 ? 180 + halfAngle : 270,
              point2: angleType === 1 ? 180 + halfAngle + angle2 : angle2,
              point3: angleType === 1 ? 180 + halfAngle + angle2 : angle2,
              point4: angleType === 1 ? 360 + halfAngle : 360,
            }
      );
    }
  }, [angle, quadrant, angleType]);

  useEffect(() => setDisableSubmit(aVal ? false : true), [aVal]);
  useEffect(() => {
    let timerId;
    if (!disableNext) {
      timerId = setTimeout(() => answerRef?.current?.focus(), [1000]);
    }
    return () => clearTimeout(timerId);
  }, [disableNext]);

  useEffect(() => {
    if (levelRef.current !== challengeLevel) setDraw(false);
    else setDraw(true);
  }, [challengeLevel]);

  useEffect(() => {
    if (draw) handleNext();
  }, [draw, handleNext]);

  useEffect(
    () => setR(maxWidth > 500 ? 500 : maxWidth < 300 ? 200 : 300),
    [maxWidth]
  );
  useEffect(() => {
    if (next) {
      setNext(false);
      setR(maxWidth > 500 ? 500 : maxWidth < 300 ? 200 : 300);
      if (angleType === 1) {
        setAngle(Math.floor(Math.random() * (166 - 10) + 10));
      } else {
        setAngle(Math.floor(Math.random() * (81 - 10) + 10));
      }
    }
  }, [next, maxWidth, angleType]);

  useEffect(() => {
    let timerId;
    if (angle) {
      let designateX = Math.floor(Math.random() * (3 - 1) + 1);
      if (angleType === 1 && supplementaryType === 2) {
        const angle2 = 180 - angle;
        setEquation({
          problem: (
            <div className="flex place-content-center">
              <p className="self-center italic">
                x<sup className="text-xl">.</sup>
              </p>
              <div className="self-center">&nbsp;= ?</div>
            </div>
          ),
          solution: {
            violet: {
              angle: quadrant % 2 === 0 ? angle : angle2,
              displayAngle:
                quadrant % 2 === 0
                  ? designateX === 1
                    ? angle2
                    : "x"
                  : designateX === 1
                  ? angle
                  : "x",
            },
            yellow: {
              angle: quadrant % 2 === 0 ? angle : angle2,
              displayAngle:
                quadrant % 2 === 0
                  ? designateX === 2
                    ? angle2
                    : "x"
                  : designateX === 2
                  ? angle
                  : "x",
            },
            correctAnswer: quadrant % 2 === 0 ? angle2 : angle,
            color: designateX === 1 ? "yellow" : "violet",
            other: designateX === 1 ? "violet" : "yellow",
          },
          label: "Find the missing Vertical Angle Pair",
        });
      } else {
        let xVal = (
          <div className="self-center italic">
            x<sup className="text-xl">.</sup>
          </div>
        );
        let split = 0,
          answer = 0;
        if (challengeLevel > 0) {
          const a =
            designateX === 2 ? angle : angleType > 1 ? 90 - angle : 180 - angle;
          answer =
            challengeLevel === 1
              ? Math.floor(Math.random() * (a - 2) + 2)
              : Math.floor(Math.random() * (-2 + 5 * angle) - 5 * angle);

          split = a - answer;
          xVal = (
            <div className="self-center flex flex-row place-content-center">
              <p className="not-italic">
                {split}
                <sup className="text-xl">.</sup>&nbsp;
                {answer < 0 ? "-" : "+"}&nbsp;
              </p>
              <p className="italic">
                x<sup className="text-xl">.</sup>
              </p>
            </div>
          );
        }

        const problem = (
          <div className="flex place-content-center">
            {xVal}
            <div className="self-center">
              +&nbsp;
              {designateX === 1
                ? `${angle}`
                : `${angleType > 1 ? 90 - angle : 180 - angle}`}
              <sup className="text-xl">.</sup>&nbsp;
            </div>
            <div className="self-center">
              =&nbsp;{angleType > 1 ? 90 : 180}
              <sup className="text-xl">.</sup>
            </div>
          </div>
        );
        const a1 = {
          angle: angle,
          displayAngle: designateX === 1 ? angle : "x",
          split: designateX === 1 ? 0 : split,
          answer: split
            ? designateX === 1
              ? 0
              : answer
            : designateX === 1
            ? 0
            : angle,
        };
        const a2 = {
          angle: angleType > 1 ? 90 - angle : 180 - angle,
          displayAngle:
            designateX === 2 ? (angleType > 1 ? 90 - angle : 180 - angle) : "x",
          split: designateX === 2 ? 0 : split,
          answer: split
            ? designateX === 2
              ? 0
              : answer
            : designateX === 2
            ? 0
            : angleType > 1
            ? 90 - angle
            : 180 - angle,
        };
        const color =
          quadrant % 2 === 0 || angleType === 2
            ? designateX === 1
              ? "yellow"
              : "violet"
            : designateX === 1
            ? "violet"
            : "yellow";
        const other =
          quadrant % 2 === 0 || angleType === 2
            ? designateX === 1
              ? "violet"
              : "yellow"
            : designateX === 1
            ? "yellow"
            : "violet";

        setEquation({
          problem,
          solution: {
            violet: quadrant % 2 === 0 || angleType === 2 ? a1 : a2,
            yellow: quadrant % 2 === 0 || angleType === 2 ? a2 : a1,
            correctAnswer:
              designateX === 1
                ? split
                  ? designateX === 2
                    ? 0
                    : answer
                  : designateX === 2
                  ? 0
                  : angleType > 1
                  ? 90 - angle
                  : 180 - angle
                : split
                ? designateX === 1
                  ? 0
                  : answer
                : designateX === 1
                ? 0
                : angle,
            color,
            other,
          },
          label:
            angleType > 1
              ? "Find the missing Complementary Angle"
              : "Find the missing Supplementary Angle",
        });
      }
      timerId = setTimeout(() => setShowText(true), [3100]);
    }
    return () => clearTimeout(timerId);
  }, [angle, angleType, supplementaryType, challengeLevel, quadrant]);

  useEffect(() => {
    setScorePct(({ currentScore }) => {
      return {
        previousScore: currentScore,
        currentScore: `${
          solved.overAll && Math.round((score.overAll * 100) / solved.overAll)
        }%`,
      };
    });
  }, [solved.overAll, score.overAll]);

  return [
    disableNext,
    angle,
    r,
    curve1,
    setCurve1,
    curve2,
    setCurve2,
    angleType,
    equation,
    showText,
    svgRef,
    showSolution,
    result,
    aVal,
    setAVal,
    checkAVal,
    disableSubmit,
    scorePct,
    showConfettiEmo,
    setShowConfettiEmo,
    showAntiConfetti,
    setShowAntiConfetti,
    supplementaryType,
    showInstructions,
    setShowInstructions,
    quadrant,
    curvePoints,
    answerRef,
    draw,
    setDraw,
    levelRef,
    setShowText,
    setDisableNext,
  ];
};
export default useGetAngleEquations;
