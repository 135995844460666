import React from "react";
import PickItemCarrier from "../PickItemCarrier/PickItemCarrier";
import ItemCarrierTable from "../ItemCarrierTable/ItemCarrierTable";
import KLineNumber from "../KLineNumber/KLineNumber";
const DrawKConstant = ({
  selected,
  confirmSelection,
  item,
  setItem,
  carrier,
  setCarrier,
  itemList,
  setItemList,
  carrierList,
  setCarrierList,
  itemCarriers,
  columnValue,
  checkVal,
  onChangeItem,
  maxWidth,
  maxHeight,
  k,
  kNumerator,
  result,
  challengeLevel,
  numberLineRef,
  tableRef,
  unitRate,
  move,
  setMove,
  expandCollapse,
  transportRef,
  setDisableNext,
  draw,
  setDraw,
  levelRef,
  showItems,
  showTable,
}) => (
  <div className="flex flex-col place-content-center relative">
    {showItems ? (
      <div className="absolute top-0 left-0 right-0 bottom-0">
        <PickItemCarrier
          selected={selected}
          confirmSelection={confirmSelection}
          item={item}
          setItem={setItem}
          carrier={carrier}
          setCarrier={setCarrier}
          itemList={itemList}
          setItemList={setItemList}
          carrierList={carrierList}
          setCarrierList={setCarrierList}
          challengeLevel={levelRef.current}
          move={move}
          setMove={setMove}
          transportRef={transportRef}
          showItems={showItems}
        />
      </div>
    ) : (
      <div className="absolute top-0 left-0 right-0 bottom-0">
        <ItemCarrierTable
          selected={selected}
          item={item}
          carrier={carrier}
          itemCarriers={itemCarriers}
          columnValue={columnValue}
          checkVal={checkVal}
          onChangeItem={onChangeItem}
          k={k}
          kNumerator={kNumerator}
          result={result}
          challengeLevel={levelRef.current}
          ref={tableRef}
          unitRate={unitRate}
          expandCollapse={expandCollapse}
          setMove={setMove}
          setDisableNext={setDisableNext}
          showTable={showTable}
        />
        {showTable && (
          <KLineNumber
            maxWidth={maxWidth}
            itemCarriers={itemCarriers}
            item={item}
            carrier={carrier}
            ref={numberLineRef}
          />
        )}
      </div>
    )}
  </div>
);
export default DrawKConstant;
