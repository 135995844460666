import FactorInput from "./FactorInput";
import FactorButtons from "./FactorButtons";
import ConnectingLines from "./ConnectingLines";
const CompositeNode = ({
  coords,
  svgDim,
  pos,
  row,
  factors,
  checkFactorInput,
  checkFactor,
  mainNumberType,
  inputChange,
  mainNumber,
  animate,
  firstTreeComplete,
}) => {
  const inputProps = {
    svgDim,
    pos,
    row,
    checkFactorInput,
    mainNumberType,
    coords,
    factors,
    inputChange,
    animate,
    firstTreeComplete,
  };

  return (
    <g>
      <ConnectingLines
        coords={coords}
        svgDim={svgDim}
        pos={pos}
        row={row}
        animate={animate}
        factor1Type={factors.factor1.type}
        factor2Type={factors.factor2.type}
      />
      {!factors.factor1.solved && (
        <FactorInput {...inputProps} factorType={"factor1"} />
      )}
      {!factors.factor2.solved && (
        <FactorInput {...inputProps} factorType={"factor2"} />
      )}
      {factors.factor1.showBtn && (
        <FactorButtons
          {...inputProps}
          checkFactor={checkFactor}
          factorType={"factor1"}
        />
      )}
      {factors.factor2.showBtn && (
        <FactorButtons
          {...inputProps}
          checkFactor={checkFactor}
          factorType={"factor2"}
        />
      )}
    </g>
  );
};
export default CompositeNode;
