import { forwardRef, useState } from "react";
import { Transition } from "@headlessui/react";
import FractionBlocks from "../FractionBlocks/FractionBlocks";
import FractionBarTable from "../FractionBarTable/FractionBarTable";
import MuteButtons from "../MuteButtons/MuteButtons";
import MessageModal from "../../MessageModal/MessageModal";

const DrawFractionsBox = forwardRef(
  (
    {
      dragged,
      dropped,
      boxWidth,
      addBlock,
      removeBlock,
      slide,
      dropIndex,
      setDropIndex,
      setBlockFail,
      tableRowKeyEvent,
      fractions,
      currentBlockRow,
      slideComplete,
      blockPickUp,
      touchEleCoords,
      setTouchEleCoords,
      fractionBlockTouchMove,
      fractioBlockTouchEnd,
      tableBlockDrag,
      showSolution,
      displayLock,
      setDisplayLock,
      mute,
      setMute,
      blockHeight,
      rowComplete,
      setRowComplete,
      boxWidth2,
      challengeLevel,
      levelRef,
    },
    ref
  ) => {
    const [showInstructions, setShowInstructions] = useState(false);
    return (
      <Transition
        show={dropped[0].length > 0}
        enter="transition-opacity duration-[1500ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full self-center flex flex-col place-content-center max-[640px]:p-5 p-10"
        afterEnter={() => {
          setDisplayLock(true);
          levelRef.current = challengeLevel;
        }}
        afterLeave={() => (levelRef.current = challengeLevel)}
      >
        <div className="self-center">
          <div className="w-full self-center flex flex-row place-content-center">
            <div
              ref={ref}
              className={`self-start mx-1`}
              style={{ width: boxWidth, height: blockHeight }}
            >
              {challengeLevel > 0 && (
                <div
                  className={`flex place-content-center bg-cyan-300 self-start text-sm max-[1024px]:text-[12px] text-black font-black max-[1024px]:border-solid max-[1024px]:border border-double border-8 border-black`}
                  style={{ width: boxWidth, height: blockHeight }}
                >
                  <p className="text-3xl max-[1024px]:text-xl text-center self-center">
                    1
                  </p>
                </div>
              )}
              <FractionBarTable
                addBlock={addBlock}
                removeBlock={removeBlock}
                setDropIndex={setDropIndex}
                dropped={dropped}
                boxWidth={boxWidth}
                slide={slide}
                dropIndex={dropIndex}
                dragged={dragged}
                tableRowKeyEvent={tableRowKeyEvent}
                slideComplete={slideComplete}
                touchEleCoords={touchEleCoords}
                setTouchEleCoords={setTouchEleCoords}
                fractionBlockTouchMove={fractionBlockTouchMove}
                fractioBlockTouchEnd={fractioBlockTouchEnd}
                tableBlockDrag={tableBlockDrag}
                showSolution={showSolution}
                displayLock={displayLock}
                blockHeight={blockHeight}
                boxWidth2={boxWidth2}
                challengeLevel={challengeLevel}
              />
              <div
                className={`flex flex-wrap ${
                  challengeLevel > 0
                    ? "place-content-start"
                    : "place-content-between"
                }`}
              >
                <MuteButtons mute={mute} setMute={setMute} />
                <button
                  onClick={() => setShowInstructions(true)}
                  className="font-bold text-blue-900 max-[640px]:text-xs hover:text-blue-600 focus:text-blue-600 hover:underline focus:underline focus:outline-none"
                >
                  Instructions
                </button>
              </div>
            </div>
            <div
              className={`mr-1 self-start border-2 border-black bg-lime-100`}
            >
              {!showSolution && (
                <p className="max-[640px]:text-[12px] max-[640px]:leading-none bg-yellow-300 text-center font-bold leading-none text-green-800">
                  Pull from the fraction bin
                </p>
              )}
              <FractionBlocks
                dragged={dragged}
                boxWidth={boxWidth}
                setBlockFail={setBlockFail}
                fractions={fractions}
                currentBlockRow={currentBlockRow}
                blockPickUp={blockPickUp}
                touchEleCoords={touchEleCoords}
                fractionBlockTouchMove={fractionBlockTouchMove}
                fractioBlockTouchEnd={fractioBlockTouchEnd}
                showSolution={showSolution}
                blockHeight={blockHeight}
                rowComplete={rowComplete}
                setRowComplete={setRowComplete}
              />
            </div>
          </div>
          <MessageModal
            openModal={showInstructions}
            closeModal={() => setShowInstructions(false)}
            message={{
              message: (
                <ul className="list-disc text-black max-[640px]:text-xs">
                  <li>
                    {challengeLevel > 0
                      ? "Find the sum of the two fractions using a common denominator."
                      : "Combine fractions in each row so that each row equals 1. Row2 is an example that demonstrates the concept."}
                  </li>
                  <li>
                    {challengeLevel > 0
                      ? "Move each unit fraction until they equal the sum."
                      : "Use at least two different fraction sizes in each row."}
                  </li>
                  <li>
                    When navigating the game by keyboard, use "enter" key to
                    select/place the block and "tab" to the row to place it, to
                    remove the block from a row select and use "delete" button.
                  </li>
                </ul>
              ),
              bgColor: "bg-blue-300",
            }}
          />
        </div>
      </Transition>
    );
  }
);
export default DrawFractionsBox;
