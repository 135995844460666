import React, { Fragment, useRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { MdClose } from "react-icons/md";
import MessageModal from "../MessageModal/MessageModal";

const SigninModal = ({
  showSignin,
  setShowSignin,
  email,
  setEmail,
  getAuth,
  verifyUser,
  signInResult,
  setSignInResult,
  message,
  emailOTPSuccess,
  setEmailOTPSuccess,
  code,
  setCode,
}) => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [verifyBoth, setVerifyBoth] = useState(false);

  useEffect(() => {
    let timerId;
    if (emailOTPSuccess) {
      timerId = setTimeout(() => passwordRef?.current?.focus(), [500]);
    }
    return () => clearTimeout(timerId);
  }, [emailOTPSuccess]);

  useEffect(() => {
    let timerId;
    if (verifyBoth) {
      timerId = setTimeout(() => {
        email ? passwordRef?.current?.focus() : emailRef?.current?.focus();
      }, [500]);
    }
    return () => clearTimeout(timerId);
  }, [verifyBoth, email]);

  useEffect(
    () =>
      showSignin || emailOTPSuccess
        ? setShowAuthModal(true)
        : setShowAuthModal(false),

    [showSignin, emailOTPSuccess]
  );

  return (
    <>
      <Transition appear show={showAuthModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => false}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterEnter={() => emailRef?.current?.focus()}
            afterLeave={() => {
              setShowSignin(false);
              setEmailOTPSuccess(false);
              setVerifyBoth(false);
            }}
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="bg-stone-900 bg-opacity-50 fixed inset-0 overflow-y-auto text-white">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="bg-cyan-700 w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    type="button"
                    className="absolute right-2 top-2 p-0 outline-none focus:outline-none focus:border-2 focus:border-white focus:ring-offset-2"
                    onClick={() => {
                      setShowAuthModal(false);
                    }}
                  >
                    <MdClose size={25} />
                  </button>
                  <DialogTitle
                    as="h3"
                    className="max-[640px]:text-sm max-[320px]:text-xs text-lg font-serif font-medium leading-6 text-center"
                  >
                    {emailOTPSuccess
                      ? `Code has been emailed to ${email} from superteachergames@gmail.com. Verify Code below`
                      : "Sign Up/In"}
                  </DialogTitle>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      if (
                        (emailOTPSuccess && code) ||
                        (verifyBoth && email && code)
                      ) {
                        verifyUser();
                      } else if (email) {
                        getAuth();
                      }
                    }}
                  >
                    {(!emailOTPSuccess || verifyBoth) && (
                      <div className="mt-2 font-serif">
                        <input
                          ref={emailRef}
                          type="text"
                          className="h-14 max-[320px]:text-xs max-[1024px]:text-sm max-[1024px]:h-10 w-full text-black pl-2 pr-2 rounded-lg z-0 focus:shadow focus:outline-none"
                          placeholder="Email"
                          value={email}
                          onChange={(event) =>
                            setEmail(event.target.value.trim())
                          }
                        />
                      </div>
                    )}
                    {(emailOTPSuccess || verifyBoth) && (
                      <div className="mt-2 font-serif">
                        <input
                          ref={passwordRef}
                          type="text"
                          className="h-14 max-[320px]:text-xs max-[1024px]:text-sm max-[1024px]:h-10 w-full text-black pl-2 pr-2 rounded-lg z-0 focus:shadow focus:outline-none"
                          placeholder="Code"
                          value={code}
                          onChange={(event) =>
                            setCode(event.target.value.trim())
                          }
                        />
                      </div>
                    )}
                    <div className="flex flex-wrap place-content-evenly">
                      <div className="p-1 self-center">
                        <button
                          type="submit"
                          disabled={
                            !email ||
                            (emailOTPSuccess && !code) ||
                            (verifyBoth && !(email && code))
                          }
                          className={`${
                            !email ||
                            (emailOTPSuccess && !code) ||
                            (verifyBoth && !(email && code))
                              ? "bg-gray-400 text-slate-300"
                              : "bg-green-300 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          } max-[320px]:text-xs max-[1024px]:text-sm inline-flex justify-center rounded-md border border-2 border-black px-4 py-2 text-sm font-medium`}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="p-1 self-center">
                        <button
                          type="button"
                          className="max-[320px]:text-xs max-[1024px]:text-sm inline-flex justify-center rounded-md border bg-green-300 border-2 border-black px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            setShowAuthModal(false);
                            setCode("");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    {!emailOTPSuccess && !verifyBoth && (
                      <div className="flex flex-row place-content-center">
                        <button
                          onClick={() => setVerifyBoth(true)}
                          className="max-[320px]:text-xs max-[1024px]:text-sm self-center outline-none focus:text-yellow-300 hover:text-yellow-300 focus:underline hover:underline"
                          type="button"
                        >
                          Already have a code? Verify here.
                        </button>
                      </div>
                    )}
                    {(emailOTPSuccess || verifyBoth) && (
                      <div className="max-[320px]:text-xs max-[1024px]:text-sm text-center font-bold text-yellow-400 break-words">
                        Can't find the email with the code? Check the spam
                        folder. If issue persists please contact
                        superteachergames@gmail.com
                      </div>
                    )}
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <MessageModal
        openModal={signInResult}
        closeModal={() => setSignInResult(false)}
        message={message}
      />
    </>
  );
};

export default SigninModal;
