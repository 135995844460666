import { useEffect } from "react";

const useGetAngleLines = ({
  angle,
  r,
  setCurve1,
  setCurve2,
  angleType,
  supplementaryType,
  curvePoints,
}) => {
  useEffect(() => {
    const createCurve = (start, end) => {
      let curve = "";
      for (let i = start; i <= end; i++) {
        curve =
          r / 2 +
          (r / 4) * Math.cos(i * (Math.PI / 180)) +
          "," +
          (r / 2 + (r / 4) * Math.sin(i * (Math.PI / 180))) +
          " " +
          curve;
      }
      return `M${r / 2},${r / 2} ${curve} ${r / 2},${r / 2}`;
    };

    if (r && angle && curvePoints) {
      if (angleType === 1) {
        setCurve1(createCurve(curvePoints.point1, curvePoints.point2));
        if (supplementaryType === 1) {
          setCurve2(createCurve(curvePoints.point3, curvePoints.point4));
        } else {
          setCurve2(
            createCurve(curvePoints.point1 + 180, curvePoints.point2 + 180)
          );
        }
      } else {
        setCurve1(createCurve(curvePoints.point1, curvePoints.point2));
        setCurve2(createCurve(curvePoints.point3, curvePoints.point4));
      }
    }
  }, [
    r,
    angle,
    setCurve1,
    setCurve2,
    angleType,
    supplementaryType,
    curvePoints,
  ]);
};
export default useGetAngleLines;
