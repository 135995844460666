import Fractions from "../../Fractions/Fractions";

const DragImage = ({ cn, f, touchEleCoords, boxWidth, blockHeight }) => (
  <div
    className={`fixed ${cn} flex place-content-center`}
    style={{
      left: touchEleCoords.left,
      top: touchEleCoords.top,
      width: f ? boxWidth / f : 0,
      height: blockHeight,
    }}
  >
    {f > 1 ? (
      <Fractions
        small={false}
        numerator={1}
        denominator={f}
        dividerColor={"border-black self-center"}
      />
    ) : (
      <p className="text-3xl text-center">1</p>
    )}
  </div>
);
export default DragImage;
