import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
const RateLabelColumn = ({ challengeLevel, reverse }) => (
  <td className="w-28 border-2 border-black text-center font-bold text-xs max-[300px]:text-[12px]">
    <div className="flex flex-col place-content-center">
      <div className="self-center text-purple-600">
        {reverse ? (
          challengeLevel < 2 ? (
            <FaArrowRight />
          ) : (
            <FaArrowLeft />
          )
        ) : challengeLevel < 2 ? (
          <FaArrowLeft />
        ) : (
          <FaArrowRight />
        )}
      </div>
      <div className="self-center">Unit Rate</div>
    </div>
  </td>
);
export default RateLabelColumn;
