import { createRef } from "react";
import {
  GiShinyApple,
  GiBanana,
  GiStrawberry,
  GiTangerine,
} from "react-icons/gi";

const fruits = [
  {
    mainIcon: <GiShinyApple className="text-red-600 text-7xl" />,
    ref: createRef(),
    iconRegular: <GiShinyApple className="text-red-600 text-5xl" />,
    iconSmall: (
      <GiShinyApple className="text-red-600 text-base max-[1024px]:text-xs" />
    ),
    label: "Apples",
    color: "text-red-600",
    fill: "fill-red-600",
    stroke: "stroke-red-600",
  },
  {
    mainIcon: <GiBanana className="text-yellow-600 text-7xl" />,
    ref: createRef(),
    iconRegular: <GiBanana className="text-yellow-600 text-5xl" />,
    iconSmall: (
      <GiBanana className="text-yellow-600 text-base max-[1024px]:text-xs" />
    ),
    label: "Bananas",
    color: "text-yellow-600",
    fill: "fill-yellow-600",
    stroke: "stroke-yellow-600",
  },
  {
    mainIcon: <GiStrawberry className="text-red-600 text-7xl" />,
    ref: createRef(),
    iconRegular: <GiStrawberry className="text-red-600 text-5xl" />,
    iconSmall: (
      <GiStrawberry className="text-red-600 text-base max-[1024px]:text-xs" />
    ),
    label: "Strawberries",
    color: "text-red-600",
    fill: "fill-red-600",
    stroke: "stroke-red-600",
  },
  {
    mainIcon: <GiTangerine className="text-orange-500 text-7xl" />,
    ref: createRef(),
    iconRegular: <GiTangerine className="text-orange-500 text-5xl" />,
    iconSmall: (
      <GiTangerine className="text-orange-500 text-base max-[1024px]:text-xs" />
    ),
    label: "Oranges",
    color: "text-orange-500",
    fill: "fill-orange-500",
    stroke: "stroke-orange-500",
  },
];
export default fruits;
