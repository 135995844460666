const Form = ({
  userType,
  disbledRadio,
  setUserType,
  school,
  setSchool,
  saveSchool,
  schoolNames,
  setShowSchoolReg,
  saveRef,
}) => (
  <form className="w-11/12 h-full self-center flex flex-col place-content-evenly">
    <fieldset className="bg-zinc-300 rounded-lg">
      <p className="text-center mt-1 leading-6 text-gray-600 font-bold">
        I am a:
      </p>
      <div className="flex flex-wrap place-content-evenly my-2 gap-x-1">
        <div className="flex items-center self-center gap-x-1">
          <input
            id="teacher"
            name="user-type"
            type="radio"
            value="teacher"
            defaultChecked={userType === "teacher"}
            disabled={disbledRadio}
            onClick={(event) => setUserType(event.target.value)}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <label
            htmlFor="push-everything"
            className="block font-medium leading-6 text-gray-900"
          >
            Teacher
          </label>
        </div>
        <div className="flex items-center self-center gap-x-1">
          <input
            id="student"
            name="user-type"
            value="student"
            type="radio"
            defaultChecked={userType === "student"}
            disabled={disbledRadio}
            onClick={(event) => setUserType(event.target.value)}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <label
            htmlFor="student"
            className="block font-medium leading-6 text-gray-900"
          >
            Student
          </label>
        </div>
      </div>
    </fieldset>
    {userType === "student" ? (
      <div>
        <div className="flex place-content-center">
          <select
            onChange={(event) => setSchool(event.target.value)}
            id="school"
            name="school"
            autoComplete="school-name"
            className="font-medium block w-full rounded-md border-2 border-black p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          >
            <option value="">
              {schoolNames.length === 0
                ? "Student registration not available"
                : "Pick a school"}
            </option>

            {schoolNames.length > 0 &&
              schoolNames.map((s) => (
                <option key={s} value={s}>
                  {s}
                </option>
              ))}
          </select>
        </div>
      </div>
    ) : (
      userType === "teacher" && (
        <div>
          <input
            type="text"
            className="border-2 border-slate-400 bg-purple-100 max-[320px]:text-xs h-10 min-[640px]:h-14 w-full text-black pl-2 pr-2 rounded-lg z-0 focus:shadow focus:outline-none"
            placeholder="School Name"
            value={school}
            onChange={(event) => setSchool(event.target.value)}
          />
        </div>
      )
    )}
    <div className="bg-zinc-300 rounded-lg self-center w-full flex flex-wrap place-content-evenly">
      <button
        ref={saveRef}
        onClick={(event) => {
          event.preventDefault();
          saveSchool({ userType, school });
        }}
        disabled={!(userType && school)}
        className={`w-16 p-2 self-center flex rounded-full place-content-center font-bold ${
          !(userType && school)
            ? "text-slate-200"
            : "text-green-900 focus:text-green-600 hover:text-green-600 focus:bg-teal-300"
        } focus:outline-none`}
      >
        Save
      </button>
      <button
        onClick={() => setShowSchoolReg(false)}
        className="w-16 p-2 self-center flex rounded-full place-content-center font-bold text-red-900 focus:text-red-600 hover:text-red-600 focus:bg-teal-300 focus:outline-none"
      >
        Cancel
      </button>
    </div>
  </form>
);

export default Form;
