import { Transition } from "@headlessui/react";
import Block from "../Block/Block";

const BlockSlider = ({
  dragged,
  boxWidth,
  slide,
  rowIndex,
  dropIndex,
  slideComplete,
  blockHeight,
}) => (
  <Transition
    show={slide && rowIndex === dropIndex}
    className="flex flex-row place-content-start"
    afterLeave={slideComplete}
    style={{
      width: dragged.f ? boxWidth / dragged.f : 0,
      height: blockHeight,
    }}
  >
    <div>
      <Block
        f={dragged.f}
        boxWidth={boxWidth}
        blockHeight={blockHeight}
        cn={`self-start ${dragged.blockAttr} text-sm max-[1024px]:text-[12px] text-black font-black max-[1024px]:border-solid max-[1024px]:border border-double border-8 border-black`}
      />
    </div>
  </Transition>
);
export default BlockSlider;
