const FactorInput = ({
  svgDim,
  pos,
  row,
  checkFactorInput,
  mainNumberType,
  coords,
  factors,
  inputChange,
  animate,
  firstTreeComplete,
  factorType,
}) => (
  <foreignObject
    x={
      svgDim.width *
        ((pos + (factorType === "factor1" ? pos - 1 : pos + 1)) /
          Math.pow(2, row + 1 + 1)) -
      35
    }
    y={(row + 1) * 100}
    width="70"
    height="70"
  >
    <div
      className={`${
        animate && "opacity-0 node-appear"
      } flex flex-col place-content-center self-center text-center ${
        mainNumberType === "first"
          ? "border-4 border-blue-600"
          : "border-4 border-red-600"
      } w-full h-full rounded-full`}
    >
      <form
        className="bg-green-50 w-full h-full rounded-full"
        onSubmit={(event) => {
          event.preventDefault();
          checkFactorInput({
            event,
            mainNumberType,
            row,
            type: factorType,
            otherFactor: factorType === "factor1" ? "factor2" : "factor1",
            coords: {
              x:
                svgDim.width *
                  ((pos + (factorType === "factor1" ? pos - 1 : pos + 1)) /
                    Math.pow(2, row + 1 + 1)) -
                35,
              y: (row + 1) * 100,
            },
            parentCoords: {
              x: coords.x,
              y: coords.y,
            },
          });
        }}
      >
        <input
          ref={factors[factorType].inputRef}
          type="number"
          step="1"
          min="2"
          disabled={
            factors[factorType].showBtn ||
            (mainNumberType === "second" && !firstTreeComplete)
          }
          className={`${
            factors[factorType].error
              ? "bg-red-300"
              : !factors[factorType].showBtn
              ? "focus:shadow focus:outline-none focus:bg-slate-200 hover:bg-slate-200"
              : "bg-green-200"
          } text-center self-center h-full w-full pl-2 rounded-full`}
          value={factors[factorType].number}
          onChange={(event) =>
            inputChange({
              mainNumberType,
              event,
              row,
              type: factorType,
              parentCoords: {
                x: coords.x,
                y: coords.y,
              },
            })
          }
        />
      </form>
    </div>
  </foreignObject>
);
export default FactorInput;
