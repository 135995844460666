import React, { useRef } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import Polygon from "../PolygonTab/Polygon/Polygon";
import NumberLine from "../NumberLineTab/NumberLine/NumberLine";
import ProportionalityConstant from "../ProportionalityConstantTab/ProportionalityConstant/ProportionalityConstant";
import Angles from "../AnglesTab/Angles/Angles";
import FractionsBar from "../FractionsBarTab/FractionsBar/FractionsBar";
import GreatestCommonFactor from "../GreatestCommonFactorTab/GreatestCommonFactor/GreatestCommonFactor";
import PullDownMenu from "../PullDownMenu/PullDownMenu";

const GameTabs = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  setChallengeLevel,
  showTabs,
  tabSelect,
  setTabClicked,
  setGameChangeConfirm,
  fractionBarRef,
  setCoords,
  touchEleCoords,
  setTouchEleCoords,
  setBlockFail,
  mute,
  setMute,
  games,
  setShowTabs,
  setShowSignin,
  authData,
  authChecked,
  setLogoutConfirm,
  saveScores,
}) => {
  const pullDownRef = useRef(null);

  const tabProps = {
    score,
    setScore,
    solved,
    setSolved,
    challengeLevel,
    setChallengeLevel,
    showTabs,
    setShowSignin,
    authData,
    authChecked,
    setLogoutConfirm,
    saveScores,
  };
  return (
    <div className="max-[1024px]:text-xs flex mx-auto w-full h-fit flex-col place-content-center">
      <TabGroup
        vertical
        manual
        defaultIndex={tabSelect}
        selectedIndex={tabSelect}
        onChange={(index) => {
          setTabClicked(index);
          setGameChangeConfirm(true);
        }}
      >
        <TabList
          onDrop={(event) => {
            event?.preventDefault();
            setBlockFail(true);
          }}
          className="h-fit w-full flex flex-wrap self-center border-0"
        >
          <PullDownMenu
            showTabs={showTabs}
            setShowTabs={setShowTabs}
            ref={pullDownRef}
          />
          {games.map((tabLabel, index) => (
            <Tab
              key={tabLabel}
              className={`${!showTabs && "hidden"} ${
                tabSelect === index
                  ? "bg-[#5df5e6]"
                  : "bg-[#7FCDCD] focus:bg-[#9af8f8] hover:bg-[#9af8f8]"
              } outline-none italic border border-white w-1/2 max-[1024px]:p-1 p-2 font-black text-center`}
            >
              {tabLabel}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="w-full h-screen">
          {[
            <Polygon {...tabProps} />,
            <NumberLine {...tabProps} />,
            <ProportionalityConstant {...tabProps} />,
            <Angles {...tabProps} />,
            <FractionsBar
              {...tabProps}
              setShowTabs={setShowTabs}
              setBlockFail={setBlockFail}
              ref={fractionBarRef}
              setCoords={setCoords}
              touchEleCoords={touchEleCoords}
              setTouchEleCoords={setTouchEleCoords}
              mute={mute}
              setMute={setMute}
              pullDownRef={pullDownRef}
            />,
            <GreatestCommonFactor {...tabProps} />,
          ].map((p, index) => (
            <TabPanel key={index}>{p}</TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default GameTabs;
