import React from "react";
import { PiVideoDuotone } from "react-icons/pi";

const VideoExplanation = ({ vid, showInstructions, setShowInstructions }) => {
  return (
    <div className="relative self-center flex place-content-center">
      <button
        className={`${
          showInstructions && "hidden"
        } text-white outline-none focus:text-green-700 hover:text-green-700 bg-black focus:bg-green-100 hover:bg-green-100 border-white focus:border-black hover:border-black font-bold border-dashed border-2`}
        onClick={() => setShowInstructions(true)}
        disabled={showInstructions}
      >
        <div className="flex place-content-center">
          <PiVideoDuotone className="self-center text-blue-500 text-3xl ml-1 mr-1" />
          <p className="self-center max-[1024px]:text-xs text-base mr-1 italic">
            Activity Explanation!
          </p>
        </div>
      </button>

      {showInstructions && (
        <button
          onClick={() => setShowInstructions(false)}
          className="absolute outline-none focus:text-green-600 hover:text-green-600 right-1 font-bold text-red-600 text-3xl"
        >
          X
        </button>
      )}
      {showInstructions && (
        <section className="w-3/4 mx-auto">
          <iframe
            className="w-full sm:aspect-[4/3]"
            width="760"
            height="415"
            src={`https://www.youtube-nocookie.com/embed/${vid}?autoplay=1&mute=1`}
            title="YouTube video player"
            allowFullScreen
            allow="autoplay"
          ></iframe>
        </section>
      )}
    </div>
  );
};
export default VideoExplanation;
