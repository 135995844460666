import { forwardRef } from "react";
import { WiSmog } from "react-icons/wi";
import { MdFace3 } from "react-icons/md";
import { GiCarWheel } from "react-icons/gi";
import { BsFillBoxFill } from "react-icons/bs";
import "../style.css";

const PetTransport = forwardRef(
  ({ item, carrier, confirmSelection, move, setMove, selected }, ref) => (
    <div
      className={`${
        "iconRegular" in item && "iconRegular" in carrier && "enter"
      } ${
        selected && "leave"
      } mb-10 self-center text-xl font-bold flex flex-row place-content-center w-full`}
    >
      {("iconRegular" in item || "iconRegular" in carrier) &&
        !("iconRegular" in item && "iconRegular" in carrier) && (
          <div className="self-center flex flex-row place-content-center relative w-24 h-24">
            <div className="absolute top-2">{item?.mainIcon}</div>
            <div className="absolute bottom-2">{carrier?.mainIcon}</div>
          </div>
        )}

      {"iconRegular" in item && "iconRegular" in carrier && (
        <div className="flex flex-row relative">
          <button
            ref={ref}
            onClick={confirmSelection}
            className="self-center fill-yellow-500 outline-none focus:fill-yellow-400 hover:fill-yellow-400"
          >
            <div className="self-center flex flex-row place-content-center relative w-96 h-64">
              <div
                className={`${item.color} text-4xl absolute left-20 bottom-36`}
              >
                <BsFillBoxFill />
              </div>
              <div className="absolute right-52 top-20">
                {carrier?.iconRegular}
              </div>
              <div className="absolute left-20 top-10 text-xs font-black">
                {item?.label}
              </div>
              <div className="absolute right-40 bottom-40">
                <MdFace3 className="text-4xl text-black" />
              </div>
              <svg
                viewBox="0 0 32 32"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                className="text-[400px] z-40"
              >
                <path d="M 10.71875 6 C 9.863281 6 9.082031 6.558594 8.8125 7.375 L 7.28125 12 L 0 12 L 0 20.21875 C 0 21.132813 0.613281 21.933594 1.5 22.15625 L 4.09375 22.8125 C 4.46875 24.628906 6.078125 26 8 26 C 9.851563 26 11.398438 24.71875 11.84375 23 L 21.15625 23 C 21.601563 24.71875 23.148438 26 25 26 C 26.851563 26 28.398438 24.71875 28.84375 23 L 30 23 C 31.09375 23 32 22.09375 32 21 L 32 17.25 C 32 15.46875 30.808594 13.894531 29.09375 13.40625 L 24.5625 12.125 L 20.65625 7.4375 C 19.894531 6.527344 18.78125 6 17.59375 6 Z M 10.71875 8 L 14 8 L 14 12 L 9.375 12 Z M 16 8 L 17.59375 8 C 18.1875 8 18.746094 8.261719 19.125 8.71875 L 21.875 12 L 16 12 Z M 2 14 L 23.84375 14 L 28.5625 15.34375 C 29.425781 15.589844 30 16.351563 30 17.25 L 30 21 L 28.84375 21 C 28.398438 19.28125 26.851563 18 25 18 C 23.148438 18 21.601563 19.28125 21.15625 21 L 11.84375 21 C 11.398438 19.28125 9.851563 18 8 18 C 6.226563 18 4.738281 19.171875 4.21875 20.78125 L 2 20.21875 Z M 8 20 C 9.117188 20 10 20.882813 10 22 C 10 23.117188 9.117188 24 8 24 C 6.882813 24 6 23.117188 6 22 C 6 20.882813 6.882813 20 8 20 Z M 25 20 C 26.117188 20 27 20.882813 27 22 C 27 23.117188 26.117188 24 25 24 C 23.882813 24 23 23.117188 23 22 C 23 20.882813 23.882813 20 25 20 Z"></path>
              </svg>
            </div>
            <div
              className={`absolute text-5xl bottom-14 right-24 text-[#d0ddbf] bg-black rounded-full 
            ${move && "animate-spin"}`}
            >
              <GiCarWheel />
            </div>
            <div
              className={`absolute ml-2 text-5xl bottom-14 left-24 text-[#d0ddbf] bg-black rounded-full 
            ${move && "animate-spin"}`}
            >
              <GiCarWheel />
            </div>
            <div className="absolute ml-1 text-xs top-32 right-36 font-black bg-yellow-200 p-1 rounded-full">
              Let's Go!
            </div>
          </button>
          <div className="absolute bottom-5 left-10">
            <WiSmog className="text-7xl text-slate-500 -rotate-90 animate-pulse" />
          </div>
        </div>
      )}
    </div>
  )
);
export default PetTransport;
