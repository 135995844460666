import { useRef } from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

const MessageModal = ({ openModal, closeModal, message }) => {
  const okRef = useRef(null);
  return (
    <Transition
      appear
      show={openModal}
      className="z-50 h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0"
    >
      <Dialog
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={closeModal}
      >
        <div className="bg-stone-900 bg-opacity-50 fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              enter="ease-out duration-1000"
              enterFrom="opacity-0 transform-[scale(95%)]"
              enterTo="opacity-100 transform-[scale(100%)]"
              leave="ease-in duration-500"
              leaveFrom="opacity-100 transform-[scale(100%)]"
              leaveTo="opacity-0 transform-[scale(95%)]"
              afterEnter={() => okRef?.current?.focus()}
            >
              <DialogPanel
                className={`w-full max-w-md rounded-xl ${message.bgColor} p-6 backdrop-blur-2xl`}
              >
                <div className="mt-2 text-base max-[1024px]:text-xs font-bold text-white">
                  {message.message}
                </div>
                <div className="mt-4 flex place-content-center">
                  <button
                    ref={okRef}
                    className="self-center inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 hover:bg-gray-600 focus:bg-green-700 outline-none"
                    onClick={closeModal}
                  >
                    ok!
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default MessageModal;
