import { useRef, useEffect, useState } from "react";

const GcfCheck = ({ gcf, showSolution, checkSolution, solution }) => {
  const [checkDisbaled, SetCheckDisabled] = useState(
    gcf.length === 0 || showSolution
  );
  const checkRef = useRef(null);

  useEffect(() => {
    SetCheckDisabled(gcf.length === 0 || showSolution);
  }, [gcf, showSolution]);

  useEffect(() => {
    if (!checkDisbaled) checkRef.current.focus();
  }, [checkDisbaled]);

  return (
    <div className="p-1 w-1/4 max-[1024px]:w-full self-center flex flex-col place-content-center border border-black">
      <div className="self-center">
        GCF = {gcf.length === 0 ? "?" : gcf.join("*")}
        {showSolution && gcf.length > 1 && ` = ${solution}`}
      </div>
      <div className="self-center">
        <button
          ref={checkRef}
          disabled={checkDisbaled}
          onClick={checkSolution}
          className={`${
            checkDisbaled
              ? "text-slate-400"
              : "text-blue-600 hover:text-green-600 hover:outline-none focus:text-green-600 focus:outline-none"
          } `}
        >
          Check
        </button>
      </div>
    </div>
  );
};
export default GcfCheck;
