const PolygonScoreStatus = ({ equation, currentGroupStatus, type }) =>
  equation?.problem && (
    <div className="self-start text-center flex flex-row max-[1024px]:flex-col place-content-center ">
      {type === "polygon" && currentGroupStatus.status && (
        <div>
          <span>
            You have already attempted this equation
            <p
              className={`${
                currentGroupStatus.result ? "text-green-600" : "text-red-600"
              } `}
            >
              Answer provided was{" "}
              {currentGroupStatus.result ? " correct" : " incorrect"}
            </p>
          </span>
        </div>
      )}
    </div>
  );
export default PolygonScoreStatus;
