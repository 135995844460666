import { useScoreAnimation } from "../../hooks";
import ScoreBoard from "./ScoreBoard";
import Solution from "./Solution";
import PolygonScoreStatus from "./PolygonScoreStatus";

const ScoreCard = ({
  type,
  showSolution,
  result,
  equation,
  scorePct,
  equationVariant,
  equationVariant2,
  currentGroupStatus,
  solved,
  score,
  scoreChange,
  setScoreChange,
}) => {
  const [previousShowing, newShowing] = useScoreAnimation({
    scorePct,
    scoreChange,
    setScoreChange,
  });

  return (
    <>
      {showSolution && (
        <Solution
          equation={equation}
          equationVariant={equationVariant}
          equationVariant2={equationVariant2}
          type={type}
          result={result}
        />
      )}
      <PolygonScoreStatus
        equation={equation}
        currentGroupStatus={currentGroupStatus}
        type={type}
      />

      {equation?.problem && (
        <ScoreBoard
          scoreChange={scoreChange}
          scorePct={scorePct}
          previousShowing={previousShowing}
          score={score}
          solved={solved}
          newShowing={newShowing}
        />
      )}
    </>
  );
};
export default ScoreCard;
