import { CgMathDivide, CgMathEqual } from "react-icons/cg";
import Fractions from "../Fractions/Fractions";

const Solution = ({
  type,
  equation,
  equationVariant,
  equationVariant2,
  result,
}) => (
  <div className="max-[1024px]:w-full self-start text-center flex flex-col place-contents-center">
    <div
      className={`${
        result === "Incorrect" ? "text-red-600" : "text-green-600"
      }`}
    >
      {result}
    </div>
    <div>
      {type === "fractionBar"
        ? "Full solution in the table"
        : type === "gcf"
        ? "Check the party bags"
        : "Full Solution:"}
    </div>
    {type === "polygon" ? (
      <div className="flex flex-col place-content-center">
        {equation.solution}
        {equationVariant?.solution}
        {equationVariant2?.solution}
      </div>
    ) : type === "k" ? (
      <div className="flex flex-row place-content-center">
        <div className="self-center">y =&nbsp;</div>
        <div className="self-center">
          <Fractions
            small={false}
            numerator={equation?.solution.rn}
            denominator={equation?.solution.rd}
          />
        </div>
        <div className="self-center ml-1">x</div>
      </div>
    ) : type === "angle" ? (
      <div className="flex flex-col place-content-center">
        <div className="self-center">{equation.solvedEquationVariant}</div>
        <div className="self-center">{equation.solvedEquation}</div>
      </div>
    ) : (
      type === "NumberLine" && (
        <div className="self-center flex flex-row place-contents-center">
          <div className="self-center">{equation?.problem.dividend}</div>
          <CgMathDivide className="self-center font-bold text-3xl max-[1024px]:text-lg" />
          <div className="self-center">
            <Fractions
              numerator={equation?.problem.divisorNumerator}
              denominator={equation?.problem.divisor}
            />
          </div>
          <CgMathEqual className="self-center font-bold text-3xl max-[1024px]:text-lg" />
          <div className="self-center flex flex-row place-content-center">
            <div className="self-center mr-1">{equation?.solution.w}</div>
            <Fractions
              small={true}
              numerator={equation?.solution.rn}
              denominator={equation?.solution.rd}
            />
          </div>
        </div>
      )
    )}
  </div>
);
export default Solution;
