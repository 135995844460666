import { TbMathXDivideY } from "react-icons/tb";
import { GiThink } from "react-icons/gi";
import { MdOutlineInsights } from "react-icons/md";
import { GiDandelionFlower } from "react-icons/gi";
import { BiMath } from "react-icons/bi";

const LandingPageFooter = () => (
  <>
    <div className="bg-black absolute bottom-3 left-3 right-3 flex flex-row place-content-around">
      <BiMath className="text-5xl max-[1024px]:text-sm animate-pulse self-center text-[#DFCFBE]" />
      <TbMathXDivideY className="text-5xl max-[1024px]:text-sm animate-pulse self-center text-[#DFCFBE]" />
      <GiThink className="text-5xl max-[1024px]:text-sm animate-pulse self-center text-[#DFCFBE]" />
      <MdOutlineInsights className="text-5xl max-[1024px]:text-sm animate-pulse self-center text-[#DFCFBE]" />
    </div>

    <div className="absolute bottom-0 right-1 w-fit h-fit text-white">
      <div className="self-end leading-none">
        <a
          href="https://github.com/marciamoss"
          target="_blank"
          rel="noreferrer"
          className="outline-none text-[14px] focus:text-xs hover:text-xs focus:underline hover:underline focus:text-cyan-200 hover:text-cyan-200"
        >
          <div className="flex flex-row font-bold italic">
            <GiDandelionFlower
              className="sparkle-footer text-[#FFD464]"
              size={10}
            />
            <div className="px-1">Built by Marcia Moss</div>
            <GiDandelionFlower
              className="sparkle-footer text-[#FFD464]"
              size={10}
            />
          </div>
        </a>
      </div>
    </div>
  </>
);
export default LandingPageFooter;
