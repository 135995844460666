import { RxDropdownMenu } from "react-icons/rx";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

const LandingPageDropDown = ({
  authChecked,
  authData,
  setShowSignin,
  setLogoutConfirm,
  setShowStudentsData,
  setShowSchoolReg,
  saveFailMessage,
  setShowAdminView,
  setShowTeacherDetails,
  setShowStudentDetails,
}) => (
  <Menu>
    <MenuButton className="hover:-translate-y-1 hover:shadow hover:shadow-[#0eb9e8] focus:-translate-y-1 focus:shadow focus:shadow-[#0eb9e8] self-end inline-flex items-center gap-2 rounded-md bg-gray-800 py-1.5 px-3 text-sm/6 font-semibold text-white outline-none">
      <RxDropdownMenu className="text-[#00c6fc] text-4xl text-center self-center" />
    </MenuButton>
    <MenuItems
      transition
      anchor="bottom end"
      className="w-64 origin-top-right rounded-xl border border-white/5 bg-black p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
    >
      {authChecked && !authData && (
        <MenuItem>
          <button
            onClick={() => setShowSignin(true)}
            className="p-3 overflow-scroll max-[1024px]:text-xs text-base w-full self-start font-bold outline-none text-cyan-300 group rounded-lg data-[focus]:text-[#00c6fc] data-[focus]:bg-cyan-300/30"
          >
            <p className="float-left">Sign Up/In</p>
            <kbd className="float-right ml-auto hidden font-sans text-xs text-white group-data-[focus]:inline">
              ⌘S
            </kbd>
          </button>
        </MenuItem>
      )}
      {authData && authData.role?.type === "teacher" && (
        <MenuItem>
          <button
            onClick={() => setShowStudentsData(true)}
            className="p-3 overflow-scroll max-[1024px]:text-xs text-base w-full self-start font-bold outline-none text-cyan-300 group rounded-lg data-[focus]:text-[#00c6fc] data-[focus]:bg-cyan-300/30"
          >
            <p className="float-left">Student's Summary</p>
            <kbd className="float-right ml-auto hidden font-sans text-xs text-white group-data-[focus]:inline">
              ⌘S
            </kbd>
          </button>
        </MenuItem>
      )}
      {authData && (
        <MenuItem>
          <button
            onClick={() => setShowSchoolReg(true)}
            className="p-3 overflow-scroll max-[1024px]:text-xs text-base w-full self-start font-bold outline-none text-cyan-300 group rounded-lg data-[focus]:text-[#00c6fc] data-[focus]:bg-cyan-300/30"
          >
            <p className="float-left">
              {!authData.role ? "Register your school" : "Change your school"}
            </p>
            <kbd className="float-right ml-auto hidden font-sans text-xs text-white group-data-[focus]:inline">
              {!authData.role ? "⌘R" : "⌘C"}
            </kbd>
          </button>
        </MenuItem>
      )}
      {authData && authData?.admin && (
        <MenuItem>
          <button
            onClick={() => setShowAdminView(true)}
            className="p-3 overflow-scroll max-[1024px]:text-xs text-base w-full self-start font-bold outline-none text-cyan-300 group rounded-lg data-[focus]:text-[#00c6fc] data-[focus]:bg-cyan-300/30"
          >
            <p className="float-left">Admin View</p>
            <kbd className="float-right ml-auto hidden font-sans text-xs text-white group-data-[focus]:inline">
              ⌘A
            </kbd>
          </button>
        </MenuItem>
      )}
      {authData && (
        <MenuItem>
          <button
            onClick={() => setLogoutConfirm(true)}
            className="p-3 overflow-scroll max-[1024px]:text-xs text-base w-full self-start font-bold outline-none text-cyan-300 group rounded-lg data-[focus]:text-[#00c6fc] data-[focus]:bg-cyan-300/30"
          >
            <p className="float-left">Logout</p>
            <kbd className="float-right ml-auto hidden font-sans text-xs text-white group-data-[focus]:inline">
              ⌘L
            </kbd>
            <p>"{authData.email}"</p>
          </button>
        </MenuItem>
      )}
      <MenuItem>
        <button
          onClick={() => setShowTeacherDetails(true)}
          className="p-3 overflow-scroll max-[1024px]:text-xs text-base w-full self-start font-bold outline-none text-cyan-300 group rounded-lg data-[focus]:text-[#00c6fc] data-[focus]:bg-cyan-300/30"
        >
          <p className="float-left">Details for Teachers</p>
          <kbd className="float-right ml-auto hidden font-sans text-xs text-white group-data-[focus]:inline">
            ⌘D
          </kbd>
        </button>
      </MenuItem>
      <MenuItem>
        <button
          onClick={() => setShowStudentDetails(true)}
          className="p-3 overflow-scroll max-[1024px]:text-xs text-base w-full self-start font-bold outline-none text-cyan-300 group rounded-lg data-[focus]:text-[#00c6fc] data-[focus]:bg-cyan-300/30"
        >
          <p className="float-left">Details for Students</p>
          <kbd className="float-right ml-auto hidden font-sans text-xs text-white group-data-[focus]:inline">
            ⌘D
          </kbd>
        </button>
      </MenuItem>
    </MenuItems>
  </Menu>
);
export default LandingPageDropDown;
