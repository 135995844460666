const useGetColumnProps = ({
  carrier,
  carriers,
  carriersQ,
  challengeLevel,
  checkVal,
  columnValue,
  estimate,
  expandCollapse,
  expand,
  index,
  item,
  items,
  itemsQ,
  k,
  kNumerator,
  onChangeItem,
  ref,
  result,
  status,
}) => {
  const columnProps1 = {
    qType:
      challengeLevel === 1
        ? challengeLevel === 0
          ? carriersQ
          : itemsQ
        : challengeLevel === 0
        ? itemsQ
        : carriersQ,
    valType:
      challengeLevel === 1
        ? challengeLevel === 0
          ? "carriers"
          : "items"
        : challengeLevel === 0
        ? "items"
        : "carriers",
    itemType:
      challengeLevel === 1
        ? challengeLevel === 0
          ? carriers
          : items
        : challengeLevel === 0
        ? items
        : carriers,
    checkVal,
    index,
    columnValue,
    onChangeItem,
    status,
    ref,
    expandCollapse,
  };
  const columnProps2 = {
    qType:
      challengeLevel === 1
        ? challengeLevel === 0
          ? itemsQ
          : carriersQ
        : challengeLevel === 0
        ? carriersQ
        : itemsQ,
    valType:
      challengeLevel === 1
        ? challengeLevel === 0
          ? "items"
          : "carriers"
        : challengeLevel === 0
        ? "carriers"
        : "items",
    itemType:
      challengeLevel === 1
        ? challengeLevel === 0
          ? items
          : carriers
        : challengeLevel === 0
        ? carriers
        : items,
    checkVal,
    index,
    columnValue,
    onChangeItem,
    status,
    showButton: true,
    expand,
    expandCollapse,
    ref,
    rowIndex: index,
  };
  const column3Props = {
    columnValue,
    items,
    carriers,
    status,
    rowIndex: index,
    carrier,
    item,
    challengeLevel,
    expand,
    estimate,
  };

  const columnKProps = {
    carriersQ: challengeLevel === 0 ? itemsQ : carriersQ,
    itemsQ: challengeLevel === 0 ? carriersQ : itemsQ,
    kNumerator: challengeLevel === 0 ? null : kNumerator,
    k,
    result,
  };

  return [columnProps1, columnProps2, column3Props, columnKProps];
};

export default useGetColumnProps;
