import { forwardRef } from "react";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";

const PullDownMenu = forwardRef(({ showTabs, setShowTabs }, ref) => (
  <div
    ref={ref}
    className={`border-t border-white border-b-0 bg-[#45B8AC] text-center w-full`}
  >
    <button
      onClick={() => setShowTabs(!showTabs)}
      className={`p-0 mt-0 mb-0 -translate-x-3 -translate-y-1 absolute z-30 top-0 left-1/2
    ${
      showTabs && "translate-y-4"
    } bg-black text-yellow-300 rounded-full font-bold outline-none hover:scale-150 hover:text-green-600 hover:duration-300 focus:scale-150 focus:text-green-600 focus:duration-300`}
    >
      {showTabs ? (
        <FaAngleDoubleUp className="text-2xl" />
      ) : (
        <FaAngleDoubleDown className="text-2xl" />
      )}
    </button>
  </div>
));
export default PullDownMenu;
