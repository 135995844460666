import { FaCloud } from "react-icons/fa";
import { BsCloudsFill } from "react-icons/bs";
import { TbCloudFilled } from "react-icons/tb";
import { IoIosSunny } from "react-icons/io";
import { PiFlyingSaucerFill } from "react-icons/pi";
import { RiAliensFill } from "react-icons/ri";

const Flight = ({ times, cn, transitionColor }) =>
  Array(times)
    .fill(0)
    .map((_, i) => (
      <div key={i} className={`${cn} relative overflow-hidden rounded mb-2.5`}>
        <IoIosSunny className="absolute top-1/4 right-10 h-48 w-48 max-[640px]:h-24 max-[640px]:w-24 text-amber-600" />
        <FaCloud className="animate-pulse absolute top-10 left-0 h-52 w-52 max-[640px]:h-12 max-[640px]:w-12 text-white" />
        <FaCloud className="animate-pulse absolute top-0 left-1/3 h-40 w-40 max-[640px]:h-12 max-[640px]:w-12 text-white" />
        <FaCloud className="animate-pulse absolute top-10 right-1/3 h-48 w-48 max-[640px]:h-20 max-[640px]:w-20 text-white" />
        <BsCloudsFill className="animate-pulse absolute top-0 left-3/4 h-40 w-40 max-[640px]:h-12 max-[640px]:w-12 text-white" />
        <FaCloud className="animate-pulse absolute top-1/4 right-0 h-44 w-44 max-[640px]:h-12 max-[640px]:w-12 text-white" />
        <BsCloudsFill className="animate-pulse absolute top-3/4 left-0 h-80 w-80 max-[640px]:h-24 max-[640px]:w-24 text-white" />
        <BsCloudsFill className="animate-pulse absolute top-1/3 right-1/2 h-40 w-40 max-[640px]:h-12 max-[640px]:w-12 text-white" />
        <TbCloudFilled className="animate-pulse absolute top-1/2 right-1/2 h-72 w-72 max-[640px]:h-20 max-[640px]:w-20 text-white" />
        <TbCloudFilled className="animate-pulse absolute bottom-40 left-0 h-40 w-40 max-[640px]:h-12 max-[640px]:w-12 text-white" />
        <TbCloudFilled className="animate-pulse absolute bottom-0 right-0 h-40 w-40 max-[640px]:h-40 max-[640px]:w-40 text-white" />
        <BsCloudsFill className="animate-pulse absolute top-1/4 right-0 h-80 w-80 max-[640px]:h-32 max-[640px]:w-32 text-white" />

        <div className="animate-fly absolute top-1/4 inset-0 -translate-x-full">
          <div className="w-fit h-fit relative">
            <PiFlyingSaucerFill className="text-sky-900 w-96 max-[640px]:w-48" />

            <p className="z-50 absolute p-2 rounded-xl top-1/2 left-1/3 text-white font-black text-xl max-[640px]:text-xs max-[640px]:p-0">
              Off we go...
            </p>

            <RiAliensFill className="absolute top-24 left-36 w-20 h-20 max-[640px]:top-12 max-[640px]:left-16 max-[640px]:w-10 max-[640px]:h-10 rounded-full bg-sky-900 text-lime-400" />
          </div>
        </div>
      </div>
    ));

export default Flight;
