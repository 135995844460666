import ScoreCard from "../../ScoreCard/ScoreCard";

const FractionBarScore = ({ showSolution, score, solved, scorePct }) => (
  <div className="mb-2 flex flex-row place-content-center">
    <div className="flex flex-wrap place-content-evenly text-blue-900 self-center border-2 border-black font-bold text-black text-lg p-2 bg-lime-100 text-center w-full max-[1024px]:text-xs">
      <ScoreCard
        type="fractionBar"
        showSolution={showSolution}
        result={"You Got it!"}
        equation={{ problem: "fraction Bars", solution: "solved" }}
        scorePct={scorePct}
        score={score}
        solved={solved}
        setScoreChange={() => {}}
        scoreChange={true}
      />
    </div>
  </div>
);
export default FractionBarScore;
