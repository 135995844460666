const AnglesImageLines = ({
  r,
  angle,
  angleType,
  curve1,
  curve2,
  showText,
  quadrant,
}) => {
  return (
    <>
      {r && angle && (
        <g>
          <path
            className="angle-line-path fill-none stroke-white stroke-[4px]"
            d={`M${r / 2},${r / 2} ${
              r / 2 +
              (r / 2) *
                Math.cos((angleType > 1 ? 90 : angle / 2) * (Math.PI / 180))
            },${
              r / 2 +
              (r / 2) *
                Math.sin((angleType > 1 ? 90 : angle / 2) * (Math.PI / 180))
            }`}
            pathLength="1"
          />
          <path
            className="angle-line-path fill-none stroke-white stroke-[4px]"
            d={`M${r / 2},${r / 2} ${
              r / 2 +
              (r / 2) *
                Math.cos(
                  (180 + (angleType > 1 ? 90 : angle / 2)) * (Math.PI / 180)
                )
            },${
              r / 2 +
              (r / 2) *
                Math.sin(
                  (180 + (angleType > 1 ? 90 : angle / 2)) * (Math.PI / 180)
                )
            }`}
            pathLength="1"
          />
          <path
            className="angle-line-path fill-none stroke-white stroke-[4px]"
            d={`M${r / 2},${r / 2} ${
              r / 2 +
              (r / 2) *
                Math.cos(
                  ((360 - (angleType > 1 ? 360 : angle)) / 2) * (Math.PI / 180)
                )
            },${
              r / 2 +
              (r / 2) *
                Math.sin(
                  ((360 - (angleType > 1 ? 360 : angle)) / 2) * (Math.PI / 180)
                )
            }`}
            pathLength="1"
          />
          <path
            className="angle-line-path fill-none stroke-white stroke-[4px]"
            d={`M${r / 2},${r / 2} ${
              r / 2 +
              (r / 2) *
                Math.cos(
                  (angleType > 1 ? 180 : 180 + (360 - angle) / 2) *
                    (Math.PI / 180)
                )
            },${
              r / 2 +
              (r / 2) *
                Math.sin(
                  (angleType > 1 ? 180 : 180 + (360 - angle) / 2) *
                    (Math.PI / 180)
                )
            }`}
            pathLength="1"
          />
          {angleType === 2 && (
            <g>
              <path
                className="angle-line-path fill-none stroke-white stroke-[4px]"
                d={`M${r / 2},${r / 2} ${
                  r / 2 +
                  (r / 2) *
                    Math.cos(
                      (90 * (quadrant - 1) + (90 - angle)) * (Math.PI / 180)
                    )
                },${
                  r / 2 +
                  (r / 2) *
                    Math.sin(
                      (90 * (quadrant - 1) + (90 - angle)) * (Math.PI / 180)
                    )
                }`}
                pathLength="1"
              />
            </g>
          )}
        </g>
      )}
      {curve1 && (
        <path
          className={`angle-curve-path1 stroke-[4px] ${
            showText ? "fill-yellow-200" : "fill-transparent"
          } stroke-yellow-300`}
          d={curve1}
          pathLength="1"
        />
      )}
      {curve2 && (
        <path
          className={`angle-curve-path2 stroke-[4px] ${
            showText ? "fill-violet-200" : "fill-transparent"
          } stroke-violet-300`}
          d={curve2}
          pathLength="1"
        />
      )}
    </>
  );
};
export default AnglesImageLines;
