import { useState, useEffect, useRef } from "react";
const keys = require("../keys.js");

const useSchoolActions = ({
  appCheckToken,
  authData,
  setShowSpinner,
  showSchoolReg,
  setShowSchoolReg,
}) => {
  const saveRef = useRef(null);
  const modalRef = useRef(null);
  const [userType, setUserType] = useState("");
  const [school, setSchool] = useState("");
  const [formReady, setFormReady] = useState(false);
  const [disbledRadio, setDisbledRadio] = useState(false);
  const [saveSchoolFail, setSaveSchoolFail] = useState(false);
  const [schoolNames, setSchoolNames] = useState([]);
  const [saveFailMessage, setSaveFailMessage] = useState("");
  const [completeFetch, setCompleteFetch] = useState(false);

  const saveSchool = async ({ userType, school }) => {
    const sn = [...schoolNames].map((s) => s.toLowerCase().trim());
    if (userType === "teacher" && sn.includes(school.toLowerCase().trim())) {
      setSaveSchoolFail(true);
      setSaveFailMessage(
        "The school name exists, make it unique by adding a room number"
      );
      return;
    }

    try {
      setShowSpinner(true);
      const postData = await appCheckToken();
      postData.body = JSON.stringify({
        email: authData.email,
        role: { type: userType, school: school.trim() },
      });
      const request = new Request(keys.cloudFunctions.saveSchool, postData);
      const response = await fetch(request);
      const saveSchoolResponse = await response.json();
      setShowSpinner(false);
      if (saveSchoolResponse?.email === authData.email) {
        authData.role = saveSchoolResponse?.role;
        setShowSchoolReg(false);
      } else {
        setSaveSchoolFail(true);
        setSaveFailMessage(
          "Unable to save at this time, if problem persists please email superteachergames@gmail.com"
        );
      }
    } catch (error) {
      setSaveSchoolFail(true);
      setSaveFailMessage(
        "Unable to save at this time, if problem persists please email superteachergames@gmail.com"
      );
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    const getSchoolsList = async () => {
      try {
        const postData = await appCheckToken();
        const request = new Request(keys.cloudFunctions.getSchools, postData);
        const response = await fetch(request);
        const getSchoolsResponse = await response.json();
        setSchoolNames(getSchoolsResponse?.schools);
        setCompleteFetch(true);
      } catch (error) {
        setCompleteFetch(true);
      }
    };
    if (showSchoolReg) getSchoolsList();
  }, [showSchoolReg, appCheckToken]);

  useEffect(() => {
    if (completeFetch) {
      if (authData?.role) {
        setUserType(authData?.role.type);
        setDisbledRadio(true);
      } else {
        setUserType("teacher");
      }
    }
  }, [completeFetch, authData]);

  useEffect(() => {
    let timerId;
    if (userType) timerId = setTimeout(() => setFormReady(true), [500]);
    return () => clearTimeout(timerId);
  }, [userType]);

  return [
    saveSchool,
    saveSchoolFail,
    setSaveSchoolFail,
    schoolNames,
    saveFailMessage,
    completeFetch,
    modalRef,
    formReady,
    userType,
    disbledRadio,
    setUserType,
    school,
    setSchool,
    saveRef,
  ];
};
export default useSchoolActions;
