import { useRef, useEffect } from "react";
import LineNumberRadioInput from "../LineNumberRadioInput/LineNumberRadioInput";

const LineNumberInputs = ({
  challengeLevel,
  showDividendLines,
  showDivisorLines,
  equation,
  setQVal,
  showSolution,
  result,
  qVal,
  disableSubmit,
  setScoreChange,
  checkQVal,
}) => {
  const numberInputRef = useRef(null);
  useEffect(() => {
    if (showDividendLines && showDivisorLines) {
      numberInputRef?.current?.focus();
    }
  }, [showDividendLines, showDivisorLines]);

  return (
    <form className="flex flex-wrap place-content-center p-2 max-[1024px]:p-0 max-[1024px]:py-1">
      {challengeLevel === 2 ? (
        showDividendLines &&
        showDivisorLines && (
          <LineNumberRadioInput
            showDividendLines={showDividendLines}
            showDivisorLines={showDivisorLines}
            equation={equation}
            setQVal={setQVal}
            showSolution={showSolution}
          />
        )
      ) : (
        <input
          type="number"
          ref={numberInputRef}
          className={`${
            showSolution &&
            (result === "Incorrect" ? "bg-red-200" : "bg-green-200")
          } ${
            !(showDividendLines && showDivisorLines) &&
            "bg-gray-200 text-gray-200"
          } text-center self-center h-12 w-3/5 pl-3 pr-2 rounded-lg z-0 focus:shadow focus:outline-none`}
          placeholder="quotient?"
          value={qVal}
          onChange={(event) => setQVal(event.target.value)}
          disabled={showSolution || !(showDividendLines && showDivisorLines)}
        />
      )}
      <div className="self-center p-1">
        <button
          type="submit"
          disabled={disableSubmit}
          onClick={(event) => {
            event.preventDefault();
            setScoreChange(true);
            checkQVal();
          }}
          className={`h-fit w-fit border-2 text-sm outline-none p-1
${
  disableSubmit
    ? "bg-gray-200 text-gray-100"
    : "bg-blue-200 focus:bg-blue-400 hover:bg-blue-400"
}
${challengeLevel === 2 && !(showDividendLines && showDivisorLines) && "hidden"}
`}
        >
          Submit
        </button>
      </div>
    </form>
  );
};
export default LineNumberInputs;
