import React from "react";
import DrawNumberLine from "../DrawNumberLine/DrawNumberLine";
import CurrentLevel from "../../CurrentLevel/CurrentLevel";
import LineNumberEquation from "../LineNumberEquation/LineNumberEquation";
import { useGetLineNumberEquations } from "../../../hooks";
import SignUpAndVideoLinks from "../../SignUpAndVideoLinks/SignUpAndVideoLinks";

const NumberLine = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  setChallengeLevel,
  showTabs,
  setShowSignin,
  authData,
  authChecked,
  setLogoutConfirm,
  saveScores,
}) => {
  const [
    handleNext,
    showSolution,
    equation,
    result,
    qVal,
    setQVal,
    disableSubmit,
    checkQVal,
    drawNL,
    divisor,
    divisorNumerator,
    dividend,
    dividendClick,
    divisorClick,
    showDividendLines,
    showDivisorLines,
    disableNext,
    scorePct,
    linesDrawn,
    svgRef,
    showInstructions,
    setShowInstructions,
  ] = useGetLineNumberEquations({
    score,
    setScore,
    challengeLevel,
    solved,
    setSolved,
    saveScores,
  });

  return (
    <div className="absolute top-auto right-0 left-0">
      <CurrentLevel
        challengeLevel={challengeLevel}
        setChallengeLevel={setChallengeLevel}
        handleNext={handleNext}
        disableNext={disableNext}
        linesDrawn={linesDrawn}
        challengeSolved={showSolution}
        showTabs={showTabs}
      />
      <div className="flex flex-col place-content-center">
        <SignUpAndVideoLinks
          vid={"8QlSYu_bNU0"}
          showInstructions={showInstructions}
          setShowInstructions={setShowInstructions}
          setShowSignin={setShowSignin}
          authData={authData}
          authChecked={authChecked}
          setLogoutConfirm={setLogoutConfirm}
        />
        <div className="self-center w-full">
          <LineNumberEquation
            equation={equation}
            showSolution={showSolution}
            result={result}
            qVal={qVal}
            setQVal={setQVal}
            disableSubmit={disableSubmit}
            checkQVal={checkQVal}
            dividendClick={dividendClick}
            divisorClick={divisorClick}
            showDividendLines={showDividendLines}
            showDivisorLines={showDivisorLines}
            challengeLevel={challengeLevel}
            scorePct={scorePct}
            solved={solved}
            score={score}
            linesDrawn={linesDrawn}
          />

          {drawNL && (
            <DrawNumberLine
              divisor={divisor}
              divisorNumerator={divisorNumerator}
              dividend={dividend}
              result={result}
              showDividendLines={showDividendLines}
              showDivisorLines={showDivisorLines}
              ref={svgRef}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NumberLine;
