const ItemButtons = ({ list, setItems }) => (
  <div className="appear self-center flex flex-wrap place-content-evenly">
    {list.map((p) => (
      <svg
        width={200}
        height={200}
        xmlns="http://www.w3.org/2000/svg"
        key={p.label}
      >
        <g>
          <foreignObject x="50" y="40" width="100" height="120">
            <button
              onClick={() => {
                setItems((items) => {
                  items.length === 2 && items.pop();
                  items = [p, ...items];
                  return items;
                });
              }}
              className={`${
                p.label === "Baseball cards" &&
                "border-2 border-sky-600 bg-white"
              } flex flex-col place-content-center self-center text-center font-bold w-full h-full`}
            >
              <div className="flex flex-col place-content-center self-center text-center font-bold w-full h-full">
                {p.mainIcon}
                <p
                  className={`text-Black text-sm font-black ${
                    p.label === "Baseball cards" && "m-2"
                  }`}
                >
                  {p.label}
                </p>
              </div>
            </button>
          </foreignObject>
          {p.label === "Baseball cards" && (
            <g>
              <path
                className="fill-sky-600 stroke-sky-600"
                strokeWidth="1"
                d={`M${50},${40} ${70},${40} Q${70},${60} ${50},${60}`}
                pathLength="1"
              />
              <path
                className="fill-sky-600 stroke-sky-600"
                strokeWidth="1"
                d={`M${50},${160} ${70},${160} Q${70},${140} ${50},${140}`}
                pathLength="1"
              />
              <path
                className="fill-sky-600 stroke-sky-600"
                strokeWidth="1"
                d={`M${150},${40} ${130},${40} Q${130},${60} ${150},${60}`}
                pathLength="1"
              />
              <path
                className="fill-sky-600 stroke-sky-600"
                strokeWidth="1"
                d={`M${150},${160} ${130},${160} Q${130},${140} ${150},${140}`}
                pathLength="1"
              />
            </g>
          )}
        </g>
      </svg>
    ))}
  </div>
);
export default ItemButtons;
