import { forwardRef } from "react";
import { ImHome3 } from "react-icons/im";
import { GiDandelionFlower } from "react-icons/gi";
import "./style.css";

const Header = forwardRef(
  ({ setTabSelect, setShowStudentsData, setShowAdminView }, ref) => {
    return (
      <div ref={ref} className="relative">
        <div className="header absolute top-0 right-0 left-0 h-24 border-b-2 border-neutral-400 flex flex-row place-content-between p-2">
          <div className="self-center flex flex-row place-content-center font-bold h-full">
            <div className="flex flex-wrap place-content-center self-center">
              <button
                onClick={() =>
                  window.open("https://superteacherguy.com/", "_blank")
                }
                className="hover:-translate-y-1 focus:-translate-y-1 p-1 outline-none self-center bg-[#dfcfbe] focus:shadow hover:shadow focus:shadow-[#0eb9e8] hover:shadow-[#0eb9e8]"
              >
                <img
                  className="self-center w-16 max-[1024px]:w-14"
                  alt="superteachergames!"
                  src={`/logo192.png`}
                ></img>
              </button>
              <div className="p-1 text-header-color font-antonio self-center text-2xl max-[1024px]:text-xs font-bold">
                superteachergames!
              </div>
            </div>
          </div>
          <div className="w-48 max-[1024px]:w-24 self-center flex flex-wrap place-content-around font-bold h-full">
            <button
              onClick={() => {
                setTabSelect(null);
                setShowStudentsData(false);
                setShowAdminView(false);
              }}
              className="hover:-translate-y-1 focus:-translate-y-1 outline-none self-center text-[#537263] focus:text-green-900 hover:text-green-900 focus:shadow hover:shadow focus:shadow-green-900 hover:shadow-green-900"
            >
              <ImHome3 className="text-6xl max-[1024px]:text-5xl" />
            </button>
            <div className="self-center font-bold bg-black border border-black">
              <a
                href="https://github.com/marciamoss"
                target="_blank"
                rel="noreferrer"
                className="outline-none text-[#dfcfbe] focus:text-cyan-200 hover:text-cyan-200"
              >
                <div className="leading-none max-[1024px]:text-[8px] flex flex-col place-content-center font-black text-xs italic">
                  <div className="self-center">Built&nbsp;</div>
                  <div className="self-center">by&nbsp;</div>
                  <div className="flex flex-row text-center self-center underline">
                    Marcia Moss
                    <GiDandelionFlower size={10} className="text-[#FFD464]" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default Header;
