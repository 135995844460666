import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import RateColumn from "./RateColumn";
import RateLabelColumn from "./RateLabelColumn";

const UnitRateRow1 = ({ result, challengeLevel, k, kNumerator, unitRate }) => {
  return (
    <Transition
      as={Fragment}
      show={result ? true : false}
      enter="transform transition duration-[1000ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <tr className="text-green-900 bg-cyan-200">
        <td className="w-24 border-2 border-black text-center font-bold">
          {challengeLevel < 2 ? k : 1}
        </td>
        <RateLabelColumn challengeLevel={challengeLevel} />
        <RateColumn
          k={k}
          kNumerator={kNumerator}
          unitRate={unitRate}
          challengeLevel={challengeLevel}
        />
      </tr>
    </Transition>
  );
};
export default UnitRateRow1;
