import MessageModal from "../MessageModal/MessageModal";

const DetailsView = ({
  showTeacherDetails,
  setShowTeacherDetails,
  showStudentDetails,
  setShowStudentDetails,
}) => {
  return (
    <>
      <MessageModal
        openModal={showTeacherDetails}
        closeModal={() => setShowTeacherDetails(false)}
        message={{
          message: (
            <div className="flex flex-wrap place-content-center">
              <div className="self-center text-center">
                <p className="text-center text-lg text-stone-600">Teachers</p>
                <ul className="list-disc text-justify text-blue-900 p-2 font-bold">
                  <li className="p-2">
                    You can register your school to which your students can sign
                    in to.
                  </li>
                  <li className="p-2">
                    Once students register to your school, you can monitor their
                    progress.
                  </li>
                  <li className="p-2">
                    You will have the option to filter their activity by dates.
                    If no dates are provided you will see progress until current
                    date.
                  </li>
                  <li className="p-2">
                    The activities will be detailed by what games are being
                    played, # of games being played and scores breakdown for
                    each game on those dates.
                  </li>
                </ul>
              </div>
            </div>
          ),
          bgColor: "bg-green-100 border-4 border-double border-stone-600",
        }}
      />
      <MessageModal
        openModal={showStudentDetails}
        closeModal={() => setShowStudentDetails(false)}
        message={{
          message: (
            <div className="flex flex-wrap place-content-center">
              <div className="self-center text-center">
                <p className="text-center text-lg text-stone-600">Students</p>
                <ul className="list-disc text-justify text-blue-900 p-2 font-bold">
                  <li className="p-2">
                    You can sign in to your school that your teacher has created
                    for you.
                  </li>
                  <li className="p-2">
                    Make sure you pick the room number that applies to your
                    teacher if there is more than one option with same school
                    name.
                  </li>
                  <li className="p-2">
                    By signing into your school, your teacher will have the
                    ability to track your progress.
                  </li>
                </ul>
              </div>
            </div>
          ),
          bgColor: "bg-green-100 border-4 border-double border-stone-600",
        }}
      />
    </>
  );
};

export default DetailsView;
