import { useState, useEffect, useCallback, useRef } from "react";
import { useTimeoutFn } from "react-use";
import Fraction from "fraction.js";

const useGetLineNumberEquations = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  saveScores,
}) => {
  const [next, setNext] = useState(true);
  const [drawNL, setDrawNL] = useState(false);
  const [qVal, setQVal] = useState("");
  const [equation, setEquation] = useState(null);
  const [divisor, setDivisor] = useState(null);
  const [divisorNumerator, setDivisorNumerator] = useState(null);
  const [dividend, setDividend] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [result, setResult] = useState("");
  const [scorePct, setScorePct] = useState({
    previousScore: "0%",
    currentScore: "0%",
  });
  const [showSolution, setShowSolution] = useState(false);
  const [showDividendLines, setShowDividendLines] = useState(false);
  const [showDivisorLines, setShowDivisorLines] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [linesDrawn, setLinesDrawn] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [, , resetLinesDrawn] = useTimeoutFn(() => {
    setLinesDrawn(true);
    setDisableNext(false);
  }, 2100);
  const svgRef = useRef(null);

  const handleNext = useCallback(() => {
    setNext(true);
    setDivisor(null);
    setDividend(null);
    setEquation(null);
    setDrawNL(false);
    setQVal("");
    setShowSolution(false);
    setResult("");
    setLinesDrawn(false);
    setDisableNext(true);
    setShowInstructions(false);
    resetLinesDrawn();
  }, [resetLinesDrawn]);

  const dividendClick = () => {
    setTimeout(
      () =>
        svgRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      [1]
    );
    setShowDividendLines(true);
  };
  const divisorClick = () => {
    setTimeout(
      () =>
        svgRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      [1]
    );
    setShowDivisorLines(true);
  };
  const checkQVal = () => {
    setDisableSubmit(true);
    setShowSolution(true);
    const tmpSolved = solved;
    const tmpScore = score;
    tmpSolved[`level${challengeLevel + 1}`] =
      tmpSolved[`level${challengeLevel + 1}`] + 1;
    tmpSolved.overAll = tmpSolved.overAll + 1;
    setSolved(tmpSolved);
    if (challengeLevel < 2) {
      setEquation((equation) => {
        return {
          ...equation,
          solution: { w: (divisor * dividend) / divisorNumerator },
        };
      });
    }
    if (
      (challengeLevel === 2 ? qVal : parseFloat(qVal)) ===
      (challengeLevel === 2
        ? new Fraction((divisor * dividend) / divisorNumerator).toFraction(true)
        : (divisor * dividend) / divisorNumerator)
    ) {
      setResult("You Got it!");
      tmpScore[`level${challengeLevel + 1}`] =
        tmpScore[`level${challengeLevel + 1}`] + 1;
      tmpScore.overAll = tmpScore.overAll + 1;
      setScore(tmpScore);
    } else {
      setResult("Incorrect");
    }
    saveScores({ solved: tmpSolved, score: tmpScore, type: "numberLine" });
    setTimeout(
      () =>
        svgRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      [1]
    );
  };
  const splitFraction = (sol) => {
    const solSplit = sol.split(" ");
    const rSplit = solSplit.length > 1 && solSplit[1].split("/");
    return { w: solSplit[0], rn: rSplit[0], rd: rSplit[1] };
  };

  useEffect(() => setDrawNL(equation?.problem), [equation?.problem]);

  useEffect(() => handleNext(), [challengeLevel, handleNext]);

  useEffect(() => {
    if (next) {
      const fractions = [2, 3, 4, 5, 6, 8];
      let dend = Math.floor(Math.random() * (7 - 1) + 1);
      let sor = fractions[Math.floor(Math.random() * fractions.length)];
      let n = 1;
      if (challengeLevel === 1) {
        n = Math.floor(Math.random() * (5 - 2) + 2);
        while (n === sor || !Number.isInteger(dend * (sor / n))) {
          n = Math.floor(Math.random() * (5 - 2) + 2);
          sor = fractions[Math.floor(Math.random() * fractions.length)];
          dend = Math.floor(Math.random() * (7 - 1) + 1);
        }
      }
      if (challengeLevel === 2) {
        n = Math.floor(Math.random() * (5 - 2) + 2);
        while (
          n === sor ||
          Number.isInteger(dend * (sor / n)) ||
          (dend * sor) / n < 1
        ) {
          n = Math.floor(Math.random() * (5 - 2) + 2);
          sor = fractions[Math.floor(Math.random() * fractions.length)];
          dend = Math.floor(Math.random() * (7 - 1) + 1);
        }
      }
      setDividend(dend);
      setDivisor(sor);
      setDivisorNumerator(n);
      setNext(false);
      setShowDivisorLines(false);
      setShowDividendLines(false);
    }
  }, [next, challengeLevel]);

  useEffect(() => {
    let options = [];
    let splitOptions = [];
    if (divisor && dividend) {
      if (challengeLevel === 2) {
        options = [
          new Fraction((divisor * dividend) / divisorNumerator).toFraction(
            true
          ),
          new Fraction(
            (divisor * dividend) / divisorNumerator + 0.25
          ).toFraction(true),
          new Fraction(
            (divisor * dividend) / divisorNumerator + 0.5
          ).toFraction(true),
        ];

        options.sort(() => Math.random() - 0.5);
        options.forEach((o) => splitOptions.push(splitFraction(o)));
      }
      setEquation({
        problem: { divisor, dividend, divisorNumerator },
        solution: splitFraction(
          new Fraction((divisor * dividend) / divisorNumerator).toFraction(true)
        ),
        options,
        splitOptions,
      });
    }
  }, [divisor, dividend, divisorNumerator, challengeLevel]);

  useEffect(
    () =>
      setDisableSubmit(
        qVal && showDividendLines && showDivisorLines ? false : true
      ),
    [qVal, showDividendLines, showDivisorLines]
  );

  useEffect(() => {
    setScorePct(({ currentScore }) => {
      return {
        previousScore: currentScore,
        currentScore: `${
          solved.overAll && Math.round((score.overAll * 100) / solved.overAll)
        }%`,
      };
    });
  }, [solved.overAll, score.overAll]);

  return [
    handleNext,
    showSolution,
    equation,
    result,
    qVal,
    setQVal,
    disableSubmit,
    checkQVal,
    drawNL,
    divisor,
    divisorNumerator,
    dividend,
    dividendClick,
    divisorClick,
    showDividendLines,
    showDivisorLines,
    disableNext,
    scorePct,
    linesDrawn,
    svgRef,
    showInstructions,
    setShowInstructions,
  ];
};
export default useGetLineNumberEquations;
