const PolyText = ({
  setCurrentText,
  currentGroupStatus,
  showSolution,
  groups,
  width,
  groupsStatus,
  type,
}) => {
  const showLineLength = ({ lineLength, displayLineLength, x, y, cn }) =>
    setCurrentText({
      text:
        currentGroupStatus.status || showSolution
          ? lineLength
          : displayLineLength,
      x,
      y,
      cn,
    });
  return groups.map(
    ({ show, group, cn }) =>
      show &&
      group.map(({ text: { x, y, lineLength, displayLineLength } }, index) => (
        <text
          key={index}
          x={x}
          y={y}
          onMouseOver={() =>
            showLineLength({ lineLength, displayLineLength, x, y, cn })
          }
          onMouseOut={() => setCurrentText(null)}
          onFocus={() =>
            showLineLength({ lineLength, displayLineLength, x, y, cn })
          }
          onBlur={() => setCurrentText(null)}
          className={`${
            width < 1024 ? "text-md" : "text-lg"
          } outline-none font-bold ${cn}`}
          tabIndex={
            groupsStatus.filter(
              (gs) => gs.type === type && gs?.showStatus?.status
            ).length > 0
              ? "-1"
              : "0"
          }
        >
          {showSolution || currentGroupStatus.status
            ? lineLength
            : displayLineLength}
        </text>
      ))
  );
};
export default PolyText;
