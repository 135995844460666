import { MdClose } from "react-icons/md";

const GameDetails = ({ details, detailsUser, resetDetails }) =>
  details.length > 0 && (
    <div className="relative self-center m-2 p-2 border border-black bg-blue-100 text-center">
      <button
        onClick={resetDetails}
        className="absolute right-0 top-0 w-fit h-fit bg-slate-300 text-red-400 hover:bg-slate-400 hover:text-red-600 focus:bg-slate-400 focus:text-red-600"
      >
        <MdClose className="h-5 w-5" />
      </button>
      <p className="self-center font-bold mt-2">{detailsUser}</p>

      <table className="bg-blue-200 self-center table-auto border border-black">
        <thead>
          <tr>
            <th className="p-1 border border-black">Games</th>
            <th className="p-1 border border-black">Dates Played</th>
          </tr>
        </thead>
        <tbody>
          {details.map(({ type, datesPlayed }) => (
            <tr key={type} className="border border-black">
              <td className="p-1 border border-black">{type}</td>
              <td className="p-1 border border-black">
                {datesPlayed?.length > 0 &&
                  datesPlayed.map((dp) => <p key={dp + type}>{dp}</p>)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
export default GameDetails;
