import { useState, useEffect, useRef } from "react";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import LevelButtons from "./LevelButtons";

const CurrentLevel = ({
  challengeLevel,
  setChallengeLevel,
  handleNext,
  disableNext,
  linesDrawn,
  challengeSolved,
  hideLevels,
  setBlockFail,
  showTabs,
  levels,
}) => {
  const [levelChangeConfirm, setLevelChangeConfirm] = useState(false);
  const [levelChange, setLevelChange] = useState(null);
  const nextButtonRef = useRef(null);
  useEffect(() => {
    if (challengeSolved && nextButtonRef?.current) {
      nextButtonRef.current.focus();
    }
  }, [challengeSolved]);
  return (
    <div
      onDrop={(event) => {
        event?.preventDefault();
        setBlockFail(true);
      }}
    >
      <LevelButtons
        setLevelChangeConfirm={setLevelChangeConfirm}
        setLevelChange={setLevelChange}
        linesDrawn={linesDrawn}
        challengeLevel={challengeLevel}
        hideLevels={hideLevels}
        showTabs={showTabs}
        levels={levels}
      />
      <div className="flex flex-col place-content-center my-1">
        <div className="self-center flex flex-col place-content-center">
          <button
            ref={nextButtonRef}
            onClick={handleNext}
            disabled={disableNext}
            className={`${
              disableNext
                ? "bg-gray-200 text-gray-100"
                : "bg-sky-900 text-white shadow-2xl outline-none focus:text-black hover:text-black focus:shadow-black hover:shadow-black focus:bg-sky-300 hover:bg-sky-300"
            } mt-1 mb-1 p-2 rounded-xl max-[1024px]:w-32 w-64 font-bold`}
          >
            Next Challenge
          </button>
        </div>
      </div>
      {levelChangeConfirm && (
        <ConfirmModal
          setChangeConfirm={setLevelChangeConfirm}
          restartFn={() => {
            setChallengeLevel(levelChange);
            setLevelChangeConfirm(false);
          }}
          confirmMessage={`Jump to Level ${levelChange + 1}? `}
        />
      )}
    </div>
  );
};
export default CurrentLevel;
