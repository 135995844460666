import { Fragment, forwardRef } from "react";
import { Transition } from "@headlessui/react";
import PolyOutline from "../PolyOutline/PolyOutline";

const PolyImage = forwardRef(
  (
    {
      maxWidth,
      maxHeight,
      width,
      linesDrawn,
      linePosition,
      polyVisible,
      polyPoints,
      currentText,
      setCurrentText,
      selected,
      groups,
      currentGroupStatus,
      showSolution,
      groupsStatus,
      challengeSolved,
    },
    ref
  ) => {
    return (
      <div ref={ref} className="flex flex-col place-content-center">
        <Transition
          as={Fragment}
          show={polyVisible}
          enter="transform transition duration-[400ms]"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
          className="self-center"
        >
          <div>
            <PolyOutline
              polyPoints={polyPoints}
              linePosition={linePosition}
              selected={selected}
              groups={groups}
              groupsStatus={groupsStatus}
              linesDrawn={linesDrawn}
              setCurrentText={setCurrentText}
              currentGroupStatus={currentGroupStatus}
              showSolution={showSolution}
              width={width}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              challengeSolved={challengeSolved}
              currentText={currentText}
            />
          </div>
        </Transition>
      </div>
    );
  }
);
export default PolyImage;
