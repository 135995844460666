import { useState, forwardRef } from "react";
import tabButtons from "../../tabButtons/tabButtons";

const LandingPageTabs = forwardRef(({ setTabSelect, currentTab }, ref) => {
  const [showAbout, setShowAbout] = useState(null);

  return (
    <div className="p-2 h-fit flex flex-wrap place-content-evenly max-[300px]:text-[12px] max-[1024px]:text-xs text-base ">
      {tabButtons.map(({ tab, buttonTitle, about }) => (
        <div
          key={tab}
          className="self-center flex flex-col place-content-center w-72 h-72 relative group"
        >
          <button
            ref={tab === currentTab ? ref : null}
            onClick={() => setTabSelect(tab)}
            onMouseEnter={() => setShowAbout(tab)}
            onMouseLeave={() => setShowAbout(null)}
            onFocus={() => setShowAbout(tab)}
            onBlur={() => setShowAbout(null)}
            className={`outline-none absolute inset-y-0 top-10 rotate-45 max-[1024px]:h-32 max-[1024px]:w-32 h-48 w-48 self-center shadow-2xl shadow-[#0eb9e8] border-8 border-double
            ${
              tab === showAbout
                ? "border-black text-black bg-[#00c6fc]"
                : "border-blue-200 text-[#DFCFBE] bg-black"
            } font-bold `}
          >
            <div className="-rotate-45 overflow-auto">{buttonTitle}</div>
          </button>
          <div
            className={`h-fit overflow-scroll italic ${
              tab === showAbout ? "opacity-100" : "opacity-0"
            } max-[1024px]:opacity-100 duration-300 absolute inset-y-0 top-3/4 left-0 right-0 bg-black border border-cyan-200 text-[#DFCFBE] text-center font-semibold`}
          >
            {about}
          </div>
        </div>
      ))}
    </div>
  );
});
export default LandingPageTabs;
