import orderBy from "lodash.orderby";

const FactorButtons = ({
  factors,
  cn,
  mainNumber,
  checkGcf,
  type,
  firstTreeComplete,
  secondTreeComplete,
  treeComplete,
}) => (
  <div className="border border-black bg-red-100 w-1/4 max-[1024px]:w-full self-center flex flex-wrap place-content-center">
    <div className="self-center flex flex-col place-content-center">
      {firstTreeComplete && secondTreeComplete && (
        <div className="self-center text-xs text-purple-400">
          Select all common factors here that match a common factor in the other
          value’s prime factorization.
        </div>
      )}
      <div className="self-center flex flex-wrap place-content-center w-full">
        <div className="self-center">{mainNumber} =&nbsp;</div>
        <div className="self-center flex flex-wrap place-conten-center">
          {orderBy([...factors], ["number"], ["asc"]).map(
            ({ number, ref, disable, picked, matched }, index) => (
              <div
                key={index}
                className="appear self-center flex flex-row place-content-center"
              >
                <button
                  ref={ref}
                  disabled={disable}
                  onClick={() => {
                    checkGcf({ type, number: number, ref });
                  }}
                  className={`self-center border ${!disable && cn} ${
                    (picked || matched) && "bg-yellow-200"
                  } max-[1024px]:h-5 max-[1024px]:w-5 h-7 w-7 rounded-full`}
                >
                  {number}
                </button>
                {(!treeComplete || index < factors.length - 1) && (
                  <div className="self-center mt-1">&nbsp;*&nbsp;</div>
                )}
              </div>
            )
          )}
          {!treeComplete && <div className="self-center">&nbsp;?</div>}
        </div>
      </div>
    </div>
  </div>
);

export default FactorButtons;
