import { useRef, useEffect } from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";

const ConfirmModal = ({ setChangeConfirm, restartFn, confirmMessage }) => {
  const okRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    okRef?.current?.focus();
  }, []);

  useEffect(() => {
    const clickedOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target))
        setChangeConfirm(false);
    };
    document.addEventListener("mousedown", clickedOutside);
    document.addEventListener("keydown", clickedOutside);

    return () => {
      document.removeEventListener("mousedown", clickedOutside);
      document.removeEventListener("keydown", clickedOutside);
    };
  }, [modalRef, setChangeConfirm]);

  return (
    <div ref={modalRef} className="z-50">
      <div className="bg-gray-900 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0">
        <div className="text-xl max-[640px]:text-xs max-[1024px]:text-sm z-50 bg-yellow-200 border-solid border-slate-400 border-2 rounded px-1 py-14 rounded-2xl text-center flex flex-col place-content-center">
          <h1 className="self-center mb-4 font-bold text-blue-900">
            {confirmMessage}
          </h1>
          <div className="self-center flex flex-row place-content-center w-full">
            <button
              ref={okRef}
              onClick={restartFn}
              className="flex place-content-center w-12 h-12 max-[640px]:w-7 max-[640px]:h-7 bg-gray-400 rounded-full text-3xl max-[640px]:text-lg font-bold text-green-900 focus:text-green-600 hover:text-green-600 focus:bg-gray-200 focus:outline-none"
            >
              <FaThumbsUp />
            </button>
            <button
              onClick={() => setChangeConfirm(false)}
              className="flex place-content-center w-12 h-12 max-[640px]:w-7 max-[640px]:h-7 bg-gray-400 rounded-full ml-5 text-3xl max-[640px]:text-lg font-bold text-red-900 focus:text-red-600 hover:text-red-600 focus:bg-gray-200 focus:outline-none"
            >
              <FaThumbsDown />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
