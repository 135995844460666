import React from "react";
import "./style.css";
import { useGetKEquations, useGetWindowSize } from "../../../hooks";
import CurrentLevel from "../../CurrentLevel/CurrentLevel";
import KEquation from "../KEquation/KEquation";
import Confetti from "react-confetti";
import AntiConfetti from "../../AntiConfetti/AntiConfetti";
import ConfettiEmo from "../../ConfettiEmo/ConfettiEmo";
import DrawKConstant from "../DrawKConstant/DrawKConstant";
import SignUpAndVideoLinks from "../../SignUpAndVideoLinks/SignUpAndVideoLinks";

const ProportionalityConstant = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  setChallengeLevel,
  showTabs,
  setShowSignin,
  authData,
  authChecked,
  setLogoutConfirm,
  saveScores,
}) => {
  const [
    itemCarriers,
    k,
    kNumerator,
    handleNext,
    disableNext,
    setDisableNext,
    selected,
    item,
    setItem,
    carrier,
    setCarrier,
    confirmSelection,
    columnValue,
    checkVal,
    onChangeItem,
    kOptions,
    kVal,
    setKVal,
    verifyKVal,
    result,
    showSolution,
    showConfettiEmo,
    setShowConfettiEmo,
    showAntiConfetti,
    setShowAntiConfetti,
    equation,
    scorePct,
    showEquation,
    itemList,
    setItemList,
    carrierList,
    setCarrierList,
    numberLineRef,
    tableRef,
    equationRef,
    unitRate,
    move,
    setMove,
    expandCollapse,
    showInstructions,
    setShowInstructions,
    transportRef,
    draw,
    setDraw,
    levelRef,
    showItems,
    showTable,
  ] = useGetKEquations({
    score,
    setScore,
    solved,
    setSolved,
    challengeLevel,
    saveScores,
  });
  const [maxWidth, maxHeight, width, height] = useGetWindowSize();
  return (
    <div className="h-screen absolute top-auto right-0 left-0">
      <CurrentLevel
        challengeLevel={challengeLevel}
        setChallengeLevel={setChallengeLevel}
        handleNext={handleNext}
        disableNext={disableNext}
        linesDrawn={!selected}
        challengeSolved={showSolution}
        hideLevels={selected}
        showTabs={showTabs}
      />
      <div className="flex flex-col place-content-center">
        <SignUpAndVideoLinks
          vid={challengeLevel < 2 ? "wPBJ7lHY6Po" : "8H8WqLAa0Mw"}
          showInstructions={showInstructions}
          setShowInstructions={setShowInstructions}
          setShowSignin={setShowSignin}
          authData={authData}
          authChecked={authChecked}
          setLogoutConfirm={setLogoutConfirm}
        />
        <div className="self-center w-full">
          {showEquation && (
            <KEquation
              carrier={carrier}
              item={item}
              kOptions={kOptions}
              kVal={kVal}
              setKVal={setKVal}
              verifyKVal={verifyKVal}
              showSolution={showSolution}
              result={result}
              equation={equation}
              scorePct={scorePct}
              score={score}
              solved={solved}
              challengeLevel={challengeLevel}
              ref={equationRef}
            />
          )}
          <DrawKConstant
            selected={selected}
            confirmSelection={confirmSelection}
            item={item}
            setItem={setItem}
            carrier={carrier}
            setCarrier={setCarrier}
            itemList={itemList}
            setItemList={setItemList}
            carrierList={carrierList}
            setCarrierList={setCarrierList}
            itemCarriers={itemCarriers}
            columnValue={columnValue}
            checkVal={checkVal}
            onChangeItem={onChangeItem}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            k={k}
            kNumerator={kNumerator}
            result={result}
            challengeLevel={challengeLevel}
            levelRef={levelRef}
            numberLineRef={numberLineRef}
            tableRef={tableRef}
            unitRate={unitRate}
            move={move}
            setMove={setMove}
            expandCollapse={expandCollapse}
            transportRef={transportRef}
            setDisableNext={setDisableNext}
            draw={draw}
            setDraw={setDraw}
            showItems={showItems}
            showTable={showTable}
          />
        </div>
      </div>

      {result === "You Got it!" && (
        <>
          <Confetti
            numberOfPieces={200}
            recycle={false}
            width={width}
            height={height}
          />
          <ConfettiEmo
            showConfettiEmo={showConfettiEmo}
            setShowConfettiEmo={setShowConfettiEmo}
          />
        </>
      )}
      {result === "Incorrect" && (
        <AntiConfetti
          showAntiConfetti={showAntiConfetti}
          setShowAntiConfetti={setShowAntiConfetti}
        />
      )}
    </div>
  );
};
export default ProportionalityConstant;
