const Fractions = ({ numerator, denominator, small, dividerColor }) => (
  <div className="flex flex-col place-content-center leading-none">
    <div
      className={`self-center ${
        small && "text-sm max-[1024px]:text-xs max-[300px]:text-[12px]"
      }`}
    >
      {numerator}
    </div>
    {numerator && denominator && (
      <div
        className={`self-center border ${
          dividerColor || "border-blue-900"
        } max-[1024px]:w-3 w-5 h-0`}
      />
    )}
    <div
      className={`self-center ${
        small && "text-sm max-[1024px]:text-xs max-[300px]:text-[12px]"
      }`}
    >
      {denominator}
    </div>
  </div>
);
export default Fractions;
