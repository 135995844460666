const Skeleton = ({ times, cn, transitionColor }) =>
  Array(times)
    .fill(0)
    .map((_, i) => (
      <div key={i} className={`${cn} relative overflow-hidden rounded mb-2.5`}>
        <div
          className={`animate-shimmer absolute inset-0 -translate-x-full bg-gradient-to-r ${transitionColor}`}
        />
      </div>
    ));

export default Skeleton;
