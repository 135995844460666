import React, { useState } from "react";
import { useTimeoutFn } from "react-use";

const ItemCarrierLN = ({
  pathPointsH,
  pathPointsV,
  textPoints,
  label,
  itemCarriers,
  maxWidth,
  vPoints,
  type,
}) => {
  const [showTxt, setShowTxt] = useState(false);
  const [showHPath, setShowHPath] = useState(false);

  useTimeoutFn(() => setShowTxt(true), 1100);
  useTimeoutFn(() => setShowHPath(true), 1000);

  return (
    <g>
      <path
        className={`pathHorizontal ${label.stroke} stroke-[5px] max-[1024px]:stroke-2`}
        d={`M${pathPointsH}z`}
        pathLength="1"
        strokeLinecap="round"
      />
      {showHPath && (
        <path
          className={`${label.stroke} stroke-[5px] max-[1024px]:stroke-2`}
          d={`M${pathPointsH}z`}
          pathLength="1"
          strokeLinecap="round"
        />
      )}
      {showTxt && (
        <text
          x={textPoints[0] - 20}
          y={textPoints[1] - 100}
          className={`font-black ${label.fill} text-xs max-[640px]:text-[10px]`}
        >
          {label.label}
        </text>
      )}
      {showTxt && (
        <text
          x={textPoints[0]}
          y={textPoints[1]}
          className={`font-black ${label.fill} text-xs max-[640px]:text-[10px]`}
        >
          0
        </text>
      )}

      <path
        className={`pathVertical ${label.stroke} stroke-[5px] max-[1024px]:stroke-2`}
        d={`M${pathPointsV}z`}
        pathLength="1"
        strokeLinecap="round"
      />
      {showHPath && (
        <path
          className={`${label.stroke} stroke-[5px] max-[1024px]:stroke-2`}
          d={`M${pathPointsV}z`}
          pathLength="1"
          strokeLinecap="round"
        />
      )}

      {itemCarriers?.length > 0 &&
        itemCarriers.map(
          (ic, index) =>
            ic.status === "solved" && (
              <g key={index}>
                <path
                  className={`${index ? "pathVertical1" : "pathVertical"} ${
                    label.stroke
                  } stroke-[5px] max-[1024px]:stroke-2`}
                  d={`M${(maxWidth - 20) * (ic.carriers / ic.maxC.carriers)},${
                    vPoints[0]
                  } 
              ${(maxWidth - 20) * (ic.carriers / ic.maxC.carriers)},${
                    vPoints[1]
                  }z`}
                  pathLength="1"
                  strokeLinecap="round"
                />
                {showHPath && !index && (
                  <path
                    className={` ${label.stroke} stroke-[5px] max-[1024px]:stroke-2`}
                    d={`M${
                      (maxWidth - 20) * (ic.carriers / ic.maxC.carriers)
                    },${vPoints[0]}
                ${(maxWidth - 20) * (ic.carriers / ic.maxC.carriers)},${
                      vPoints[1]
                    }z`}
                    pathLength="1"
                    strokeLinecap="round"
                  />
                )}
                {showTxt && (
                  <text
                    x={(maxWidth - 35) * (ic.carriers / ic.maxC.carriers)}
                    y={textPoints[1] - (ic.order % 2 === 0 ? 0 : 80)}
                    className={`${label.fill} font-black text-xs max-[640px]:text-[10px]`}
                  >
                    {ic[type]}
                  </text>
                )}
              </g>
            )
        )}
    </g>
  );
};
export default ItemCarrierLN;
