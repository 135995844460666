import { useState, useEffect, useRef } from "react";

const useGetDestroy = () => {
  const [coords, setCoords] = useState("");
  const [touchEleCoords, setTouchEleCoords] = useState("");
  const [blockFail, setBlockFail] = useState(false);
  const plopRef = useRef(null);
  const fractionBarRef = useRef(null);
  const [mute, setMute] = useState(true);

  const dragStart = () => setCoords("");
  const drop = (event) => {
    event?.preventDefault();
    if (event.target.contains(fractionBarRef?.current)) {
      setBlockFail(true);
      setTouchEleCoords("");
    }

    setCoords({
      left: event.clientX,
      top: event.clientY - 130,
    });
  };

  useEffect(() => {
    let timerId;
    if (blockFail) {
      let playPromise = plopRef?.current?.play();
      if (playPromise !== undefined) {
        playPromise.then((_) => {}).catch((error) => {});
      }
      timerId = setTimeout(() => {
        setBlockFail(false);
        setCoords("");
      }, [500]);
    }
    return () => clearTimeout(timerId);
  }, [blockFail, coords]);

  return [
    blockFail,
    setBlockFail,
    coords,
    dragStart,
    drop,
    plopRef,
    fractionBarRef,
    setCoords,
    touchEleCoords,
    setTouchEleCoords,
    mute,
    setMute,
  ];
};

export default useGetDestroy;
