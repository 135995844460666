import FactorButtons from "./FactorButtons";
import GcfCheck from "./GcfCheck";
import ScoreCard from "../../ScoreCard/ScoreCard";

const GCFEquation = ({
  firstNumber,
  finalFactors,
  secondNumber,
  checkGcf,
  firstTreeComplete,
  secondTreeComplete,
  gcf,
  showSolution,
  solution,
  score,
  solved,
  scorePct,
  checkSolution,
}) => {
  return (
    <div className="flex flex-row place-content-center p-5 text-base max-[1024px]:text-xs">
      <div className="flex flex-wrap place-content-evenly text-blue-900 self-center border-2 border-black font-bold text-black p-2 bg-lime-100 text-center w-full">
        <div className="flex flex-col place-content-center w-full">
          <div className="self-center">
            Determine the{" "}
            <u>
              <b>most number of party bags</b>
            </u>{" "}
            you can make with
            <i className="text-blue-600"> {firstNumber} </i> baseball cards and
            <i className="text-red-600"> {secondNumber} </i>
            cookies so that each bag has the same number of baseball cards and
            cookies.{" "}
            <p className="text-sm max-[1024px]:text-xs">
              (For example: You have 4 cards and 6 cookies, you can make total
              of 2 bags, each bag can have 2 cards and 3 cookies)
            </p>
          </div>
          <div className="flex flex-wrap place-content-evenly">
            {firstNumber && (
              <FactorButtons
                mainNumber={firstNumber}
                factors={finalFactors.firstTree}
                checkGcf={checkGcf}
                type={"firstTree"}
                treeComplete={firstTreeComplete}
                firstTreeComplete={firstTreeComplete}
                secondTreeComplete={secondTreeComplete}
                cn={`border-blue-600 bg-blue-200 hover:bg-slate-100 focus:bg-slate-100`}
              />
            )}
            <GcfCheck
              gcf={gcf}
              showSolution={showSolution}
              checkSolution={checkSolution}
              solution={solution}
            />
            {secondNumber && (
              <FactorButtons
                mainNumber={secondNumber}
                factors={finalFactors.secondTree}
                checkGcf={checkGcf}
                type={"secondTree"}
                treeComplete={secondTreeComplete}
                firstTreeComplete={firstTreeComplete}
                secondTreeComplete={secondTreeComplete}
                cn={
                  "border-red-600 bg-red-200 hover:bg-slate-100 focus:bg-slate-100"
                }
              />
            )}
            <ScoreCard
              type="gcf"
              showSolution={showSolution}
              result={"You Got it!"}
              equation={{ problem: "gcf", solution: "solved" }}
              scorePct={scorePct}
              score={score}
              solved={solved}
              setScoreChange={() => {}}
              scoreChange={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GCFEquation;
