import { useState, forwardRef } from "react";
import ScoreCard from "../../ScoreCard/ScoreCard";
import KEquationInputs from "../KEquationInputs/KEquationInputs";

const KEquation = forwardRef(
  (
    {
      carrier,
      item,
      kOptions,
      kVal,
      setKVal,
      verifyKVal,
      showSolution,
      result,
      equation,
      scorePct,
      score,
      solved,
      challengeLevel,
    },
    ref
  ) => {
    const [scoreChange, setScoreChange] = useState(false);
    return (
      <div className="flex flex-row place-content-center p-5">
        <div className="flex flex-wrap place-content-evenly text-blue-900 self-center border-2 border-black font-bold text-black text-lg p-2 bg-lime-100 text-center w-full max-[1024px]:text-xs">
          <div className="self-center">
            <p>What is the k Value?</p>
            <div className="flex flex-row place-content-center">
              <div className="self-center flex flex-row place-content-center">
                <div className="self-center h-7 mr-1 text-base">y</div>
                <div className="self-center h-7">
                  {challengeLevel === 0 ? item?.iconSmall : carrier?.iconSmall}
                </div>
                <div className="self-center h-7 mr-1 ml-1 text-base">=</div>
              </div>
              <div className="self-center flex flex-row place-content-center">
                <div className="self-center h-7 mr-1 text-base">kx</div>
                <div className="self-center h-7">
                  {challengeLevel === 0 ? carrier?.iconSmall : item?.iconSmall}
                </div>
              </div>
            </div>

            {challengeLevel > 0 && (
              <div className="p-1 self-center">Select an answer</div>
            )}
            <KEquationInputs
              challengeLevel={challengeLevel}
              showSolution={showSolution}
              kVal={kVal}
              setKVal={setKVal}
              kOptions={kOptions}
              setScoreChange={setScoreChange}
              verifyKVal={verifyKVal}
              result={result}
              ref={ref}
            />
          </div>
          <ScoreCard
            type="k"
            showSolution={showSolution}
            result={result}
            equation={equation}
            scorePct={scorePct}
            score={score}
            solved={solved}
            setScoreChange={setScoreChange}
            scoreChange={scoreChange}
          />
        </div>
      </div>
    );
  }
);
export default KEquation;
