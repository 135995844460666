import { useRef, useEffect } from "react";
import { CgMathDivide } from "react-icons/cg";
import Fractions from "../../Fractions/Fractions";

const LineNumberButtons = ({
  linesDrawn,
  dividendClick,
  divisorClick,
  showDividendLines,
  showDivisorLines,
  equation,
}) => {
  const dividendButtonRef = useRef(null);
  const divisorButtonRef = useRef(null);

  useEffect(() => {
    if (linesDrawn) {
      dividendButtonRef.current.focus();
    }
  }, [linesDrawn]);

  useEffect(() => {
    let timerId;
    if (showDividendLines) {
      timerId = setTimeout(() => {
        divisorButtonRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        divisorButtonRef.current.focus();
      }, [1000]);
    }
    return () => clearTimeout(timerId);
  }, [showDividendLines]);
  return (
    <div className="w-full self-center border border-black bg-cyan-200">
      <div className="flex flex-col place-content-center">
        <div className="w-full self-center flex flex-row place-content-center">
          <div className="self-center w-1/2 pl-2 pr-2">Dividend</div>
          <div className="self-center w-1/2 pl-2 pr-2">Divisor</div>
        </div>
        <div className="w-full h-12 self-center flex flex-row place-content-center">
          <div className="self-center w-5/12">{equation?.problem.dividend}</div>
          <CgMathDivide className="w-1/12 self-center font-bold" />
          <div className="self-center w-5/12">
            <Fractions
              numerator={equation?.problem.divisorNumerator}
              denominator={equation?.problem.divisor}
            />
          </div>
        </div>
        <div className="w-full self-center flex flex-row place-content-center">
          <div className="self-center w-1/2 pl-2 pr-2">
            <button
              ref={dividendButtonRef}
              onClick={dividendClick}
              disabled={showDividendLines}
              className={`italic text-blue-800 p-1 outline-none focus:bg-green-300 hover:bg-green-300 focus:text-green-800 hover:text-green-800 ${
                showDividendLines && "opacity-0"
              }`}
            >
              Click!
            </button>
          </div>
          <div className="self-center w-1/2 pl-2 pr-2">
            <button
              ref={divisorButtonRef}
              onClick={divisorClick}
              disabled={showDivisorLines || !showDividendLines}
              className={`italic text-red-800 p-1 outline-none focus:bg-green-300 hover:bg-green-300 focus:text-green-800 hover:text-green-800 ${
                !(showDividendLines && !showDivisorLines) && "opacity-0"
              }`}
            >
              Click!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LineNumberButtons;
