import { createRef } from "react";
import { SiFoodpanda } from "react-icons/si";
import { GiSloth, GiPolarBear } from "react-icons/gi";
import { FaDog, FaCat } from "react-icons/fa";
import { TbDog } from "react-icons/tb";
import { IoLogoOctocat } from "react-icons/io5";
import { GiBearFace } from "react-icons/gi";

const animals = [
  {
    mainIcon: <SiFoodpanda className="text-black text-7xl" />,
    ref: createRef(),
    iconRegular: <SiFoodpanda className="text-black text-3xl" />,
    iconSmall: (
      <SiFoodpanda className="text-black text-base max-[1024px]:text-xs" />
    ),
    label: "Pandas",
    color: "text-black",
    fill: "fill-black",
    stroke: "stroke-black",
  },
  {
    mainIcon: <GiSloth className="text-yellow-800 text-7xl" />,
    ref: createRef(),
    iconRegular: <GiSloth className="text-yellow-800 text-3xl" />,
    iconSmall: (
      <GiSloth className="text-yellow-800 text-base max-[1024px]:text-xs" />
    ),
    label: "Sloths",
    color: "text-yellow-800",
    fill: "fill-yellow-800",
    stroke: "stroke-yellow-800",
  },
  {
    mainIcon: <GiPolarBear className="text-black text-7xl" />,
    ref: createRef(),
    iconRegular: <GiBearFace className="text-black text-3xl" />,
    iconSmall: (
      <GiPolarBear className="text-black text-base max-[1024px]:text-xs" />
    ),
    label: "Bears",
    color: "text-black",
    fill: "fill-black",
    stroke: "stroke-black",
  },
  {
    mainIcon: <FaDog className="text-black text-7xl" />,
    ref: createRef(),
    iconRegular: <TbDog className="text-black text-3xl" />,
    iconSmall: <FaDog className="text-black text-base max-[1024px]:text-xs" />,
    label: "Dogs",
    color: "text-black",
    fill: "fill-black",
    stroke: "stroke-black",
  },
  {
    mainIcon: <FaCat className="text-orange-600 text-7xl" />,
    ref: createRef(),
    iconRegular: <IoLogoOctocat className="text-orange-600 text-3xl" />,
    iconSmall: (
      <FaCat className="text-orange-600 text-base max-[1024px]:text-xs" />
    ),
    label: "Cats",
    color: "text-orange-600",
    fill: "fill-orange-600",
    stroke: "stroke-orange-600",
  },
];

export default animals;
