import { useGetAdminsData } from "../../hooks";
import Skeleton from "../Loader/Skeleton";
import GameDetails from "./GameDetails";
import Users from "./Users";

const AdminView = ({ appCheckToken, authData }) => {
  const [
    getGameDetails,
    details,
    detailsUser,
    nullDateAlerts,
    showLoader,
    setFilterSchool,
    setFilterRole,
    userDataFiltered,
    schoolNames,
    resetDetails,
  ] = useGetAdminsData({
    appCheckToken,
    authData,
  });
  return (
    <div className="w-screen absolute top-24 right-0 left-0 max-[1024px]:text-base max-[640px]:text-sm max-[320px]:text-xs">
      {showLoader ? (
        <div className="flex flex-col place-content-center my-5">
          <Skeleton
            times={5}
            cn={"self-center border border-black w-3/4 h-10 p-5 bg-gray-200"}
            transitionColor={"from-gray-200 via-green-200 to-gray-200"}
          />
        </div>
      ) : (
        <div className="flex flex-col place-content-center p-2">
          {nullDateAlerts.length > 0 && (
            <div className="mt-2 p-2 self-center bg-black text-center">
              <p className="break-all text-red-600 font-bold">
                Missing Date Alerts:
              </p>
              {nullDateAlerts.map(({ user, game }) => (
                <p key={user} className="break-all self-center text-yellow-400">
                  {user},&nbsp;Game: {game}
                </p>
              ))}
            </div>
          )}
          <GameDetails
            resetDetails={resetDetails}
            details={details}
            detailsUser={detailsUser}
          />

          <Users
            setFilterSchool={setFilterSchool}
            setFilterRole={setFilterRole}
            userDataFiltered={userDataFiltered}
            getGameDetails={getGameDetails}
            schoolNames={schoolNames}
          />
        </div>
      )}
    </div>
  );
};
export default AdminView;
