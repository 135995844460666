import { useState, useEffect, useMemo, useCallback } from "react";
import { useTimeoutFn } from "react-use";

const usePolyLevels = ({ challengeLevel }) => {
  const [next, setNext] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [randomPoly, setRandomPoly] = useState(null);
  const [linesDrawn, setLinesDrawn] = useState(false);
  const [groupsStatus, setGroupsStatus] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);
  const [challengeSolved, setChallengeSolved] = useState(false);

  const level = useMemo(
    () => [
      { max: 19, min: 6, short: 2 },
      { max: 99, min: 60, short: 20 },
      { max: 999, min: 300, short: 100 },
    ],
    []
  );
  const [, , resetLinesDrawn] = useTimeoutFn(() => {
    setLinesDrawn(true);
    setDisableNext(false);
  }, 2100);
  const handleNext = useCallback(() => {
    setNext(true);
    setRandomPoly(null);
    setLinesDrawn(false);
    setDisableNext(true);
    setGroupsStatus([]);
    setShowInstructions(false);
    setChallengeSolved(false);
    resetLinesDrawn();
  }, [resetLinesDrawn]);

  useEffect(() => handleNext(), [challengeLevel, handleNext]);

  useEffect(() => {
    if (next) {
      setRandomPoly(Math.floor(Math.random() * (5 - 1) + 1));
      setNext(false);
    }
  }, [next]);

  return [
    randomPoly,
    level,
    handleNext,
    disableNext,
    linesDrawn,
    groupsStatus,
    setGroupsStatus,
    showInstructions,
    setShowInstructions,
    setChallengeSolved,
    challengeSolved,
  ];
};
export default usePolyLevels;
