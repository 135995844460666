import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import maxby from "lodash.maxby";
import orderBy from "lodash.orderby";
import Fraction from "fraction.js";
import fruits from "../icons/fruits";
import carriers from "../icons/carriers";
import animals from "../icons/animals";
import foods from "../icons/foods";

const useGetKEquations = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  saveScores,
}) => {
  const [next, setNext] = useState(false);
  const [itemCarriers, setItemCarriers] = useState([]);
  const [k, setK] = useState(null);
  const [kNumerator, setKNumerator] = useState(null);
  const [disableNext, setDisableNext] = useState(false);
  const [item, setItem] = useState([]);
  const [carrier, setCarrier] = useState([]);
  const [selected, setSelected] = useState(false);
  const [columnValue, setColumnValue] = useState({ val: "", index: "" });
  const [kOptions, setKOptions] = useState(null);
  const [kVal, setKVal] = useState("");
  const [result, setResult] = useState(null);
  const [showSolution, setShowSolution] = useState(false);
  const [showConfettiEmo, setShowConfettiEmo] = useState(false);
  const [showAntiConfetti, setShowAntiConfetti] = useState(false);
  const [equation, setEquation] = useState(null);
  const [scorePct, setScorePct] = useState({
    previousScore: "0%",
    currentScore: "0%",
  });
  const [showEquation, setShowEquation] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [carrierList, setCarrierList] = useState([]);
  const numberLineRef = useRef(null);
  const tableRef = useRef(null);
  const equationRef = useRef(null);
  const [unitRate, setUnitRate] = useState([]);
  const [move, setMove] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [draw, setDraw] = useState(false);
  const [showItems, setShowItems] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const row1InputRef = useRef(null);
  const row2InputRef = useRef(null);
  const row3InputRef = useRef(null);
  const transportRef = useRef(null);
  const levelRef = useRef(challengeLevel);

  const f = useMemo(
    () => [
      { rn: 2, rd: 3 },
      { rn: 3, rd: 2 },
      { rn: 3, rd: 5 },
      { rn: 5, rd: 3 },
      { rn: 5, rd: 8 },
      { rn: 8, rd: 5 },
      { rn: 3, rd: 8 },
      { rn: 8, rd: 3 },
    ],
    []
  );

  const handleNext = useCallback(() => {
    setMove(true);
    setShowTable(false);
    setNext(true);
    setK(null);
    setKNumerator(null);
    setItem([]);
    setCarrier([]);
    setSelected(false);
    setColumnValue({ val: "", index: "" });
    setKVal("");
    setResult(null);
    setShowSolution(false);
    setEquation(null);
    setShowEquation(false);
    setItemList(levelRef.current < 2 ? [...fruits] : [...foods]);
    setCarrierList(levelRef.current < 2 ? [...carriers] : [...animals]);
    setDisableNext(true);
    setUnitRate([]);
    setShowInstructions(false);
  }, []);

  const confirmSelection = (event) => {
    event.preventDefault();
    setSelected(true);
  };

  const onChangeItem = ({ event, currentIndex }) => {
    setColumnValue({ val: event.target.value, index: currentIndex });
    let temp = [...itemCarriers];
    temp[currentIndex] = { ...temp[currentIndex], status: "unsolved" };
    setItemCarriers([...temp]);
  };

  const expandCollapse = ({ currentIndex }) => {
    const temp = [...itemCarriers];
    temp.map(
      (t, index) => (t.expand = currentIndex === index ? !t.expand : t.expand)
    );
    setItemCarriers([...temp]);
  };

  const checkVal = ({ currentIndex, type, event }) => {
    event.preventDefault();
    let temp = [...itemCarriers].map(({ estimate, ...rest }) => rest);

    if (parseInt(columnValue.val) === itemCarriers[currentIndex][type]) {
      temp.map((t) => (t.expand = false));
      temp[currentIndex] = {
        items: temp[currentIndex].items,
        carriers: temp[currentIndex].carriers,
        itemsQ: temp[currentIndex].items,
        carriersQ: temp[currentIndex].carriers,
        maxC: temp[currentIndex].maxC,
        status: "solved",
        expand: true,
        order: temp[currentIndex].order,
      };
      ![...temp].filter((t) => ["unsolved", "Incorrect"].indexOf(t.status) > -1)
        .length && setShowEquation(true);
      setTimeout(
        () =>
          numberLineRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          }),
        [1]
      );
    } else {
      temp[currentIndex] = {
        ...temp[currentIndex],
        status: "Incorrect",
        estimate:
          parseInt(columnValue.val) < itemCarriers[currentIndex][type]
            ? "Too Low"
            : "Too High",
      };
    }
    setItemCarriers([...temp]);
  };

  const verifyKVal = () => {
    const tmpSolved = solved;
    const tmpScore = score;
    tmpSolved[`level${challengeLevel + 1}`] =
      tmpSolved[`level${challengeLevel + 1}`] + 1;
    tmpSolved.overAll = tmpSolved.overAll + 1;
    setSolved(tmpSolved);
    setTimeout(
      () =>
        tableRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      [1]
    );
    if (
      (challengeLevel === 0 && parseFloat(kVal) === k) ||
      (challengeLevel > 0 && kVal === `${kNumerator}/${k}`)
    ) {
      tmpScore[`level${challengeLevel + 1}`] =
        tmpScore[`level${challengeLevel + 1}`] + 1;
      tmpScore.overAll = score.overAll + 1;
      setScore(tmpScore);
      setResult("You Got it!");
      setTimeout(() => setShowConfettiEmo(true), [1500]);
    } else {
      setResult("Incorrect");
      setTimeout(() => setShowAntiConfetti(true), [1500]);
    }
    setShowSolution(true);
    saveScores({ solved: tmpSolved, score: tmpScore, type: "kConstant" });
  };

  const splitFraction = (sol) => ({
    rn: sol.split("/")[0],
    rd: sol.split("/")[1],
  });

  const getUnitRate = useCallback((k, kNumerator) => {
    const ur = new Fraction(k / kNumerator).toFraction(true).split(" ");

    return ur.length > 1
      ? {
          ...splitFraction(ur[1]),
          w: ur[0],
        }
      : "";
  }, []);

  useEffect(() => {
    if (showItems) {
      if ("iconRegular" in item && !("iconRegular" in carrier)) {
        carrierList[0].ref.current.focus();
      } else if (!("iconRegular" in item) && "iconRegular" in carrier) {
        itemList[0].ref.current.focus();
      } else if (
        itemList.length > 0 &&
        !("iconRegular" in item && "iconRegular" in carrier)
      ) {
        itemList[0].ref?.current?.focus();
      }
    }
  }, [item, carrier, itemList, carrierList, showItems]);

  useEffect(() => {
    let timerId;
    if (showEquation) {
      timerId = setTimeout(() => {
        equationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        equationRef.current.focus();
      }, [2000]);
    }
    return () => clearTimeout(timerId);
  }, [showEquation]);

  useEffect(() => {
    setScorePct(({ currentScore }) => {
      return {
        previousScore: currentScore,
        currentScore: `${
          solved.overAll && Math.round((score.overAll * 100) / solved.overAll)
        }%`,
      };
    });
  }, [solved.overAll, score.overAll]);

  useEffect(() => {
    let kVal;
    let n = 1;
    let c = [];
    const generateList = ({ c, i }) => {
      let o;
      if (i % 2 === 0) {
        o = {
          items: kVal * i,
          carriers: i * n,
          itemsQ: "?",
          carriersQ: i * n,
          status: "unsolved",
        };
      } else {
        o = {
          items: kVal * i,
          carriers: i * n,
          itemsQ: kVal * i,
          carriersQ: "?",
          status: "unsolved",
        };
      }
      c.push(o);
    };
    if (next && selected) {
      if (challengeLevel === 0) {
        kVal = Math.floor(Math.random() * (7 - 2) + 2);
        let i = 1;
        while (kVal * i <= 40) {
          generateList({ c, i });
          i = i + 1;
        }
      } else if (challengeLevel === 1) {
        kVal = Math.floor(Math.random() * (10 - 2) + 2);
        for (let i = 1; i < 11; i++) generateList({ c, i });
      } else if (challengeLevel === 2) {
        const r = Math.floor(Math.random() * 8);
        kVal = f[r].rd;
        n = f[r].rn;
        for (let i = 1; i < 11; i++) generateList({ c, i });
      }
      const small = [c.shift(), c.shift()];
      c.sort(() => Math.random() - 0.5);
      small.sort(() => Math.random() - 0.5);

      let ic = [
        {
          items: small[0].items,
          carriers: small[0].carriers,
          itemsQ: small[0].items,
          carriersQ: small[0].carriers,
          status: "solved",
          expand: true,
        },
        {
          ...(challengeLevel === 2 ? small[1] : c[1]),
          expand: false,
          ref: row1InputRef,
        },
        { ...c[2], expand: false, ref: row2InputRef },
        { ...c[3], expand: false, ref: row3InputRef },
      ];
      const temp = orderBy([...ic], ["carriers"], ["asc"]);
      temp.map((t, index) => (t.order = index + 1));
      ic.map(
        (i) =>
          (i.order = temp.filter((t) => t.carriers === i.carriers)[0].order)
      );
      ic.map((i) => (i.maxC = maxby([...ic], (x) => x.carriers)));
      setItemCarriers([...ic]);
      setK(kVal);
      setKNumerator(n);
      setNext(false);
    }
  }, [next, challengeLevel, selected, f]);

  useEffect(() => {
    if (kNumerator && k) {
      let options = [];
      let splitOptions = [];
      if (challengeLevel < 2) {
        options = [new Fraction(kNumerator / k).toFraction(true)];

        setEquation({
          problem: k,
          solution:
            challengeLevel === 0
              ? { rn: k, rd: null }
              : splitFraction(options[0]),
        });
        let f = new Fraction(
          kNumerator / Math.floor(Math.random() * (10 - 2) + 2)
        ).toFraction(true);

        while (options.length < 3) {
          options = [...options, f];
          f = new Fraction(
            kNumerator / Math.floor(Math.random() * (10 - 2) + 2)
          ).toFraction(true);
          options = [...new Set(options)];
        }

        options.sort(() => Math.random() - 0.5);

        options.forEach((o) => splitOptions.push(splitFraction(o)));
        setKOptions({ options, splitOptions });
      } else {
        let o = [...f]
          .filter((r) => r.rn / r.rd !== kNumerator / k)
          .sort(() => Math.random() - 0.5);
        splitOptions = [{ rn: kNumerator, rd: k }, o[0], o[1]].sort(
          () => Math.random() - 0.5
        );
        options = [
          `${splitOptions[0].rn}/${splitOptions[0].rd}`,
          `${splitOptions[1].rn}/${splitOptions[1].rd}`,
          `${splitOptions[2].rn}/${splitOptions[2].rd}`,
        ];
        setUnitRate([getUnitRate(k, kNumerator), getUnitRate(kNumerator, k)]);
        setEquation({
          problem: k,
          solution: { rn: kNumerator, rd: k },
        });
        setKOptions({ options, splitOptions });
      }
    }
  }, [kNumerator, k, challengeLevel, f, getUnitRate]);

  useEffect(() => {
    if (levelRef.current !== challengeLevel) setDraw(false);
    else setDraw(true);
  }, [challengeLevel]);

  useEffect(() => {
    if (draw) handleNext();
  }, [draw, handleNext]);

  useEffect(() => {
    let timerId;
    if (itemCarriers.length > 0) {
      let tmp = [itemCarriers[1], itemCarriers[2], itemCarriers[3]];
      const timer = [...tmp].every((ic) => ic.status !== "solved") ? 3500 : 500;
      const moveFocus = [...tmp].every((ic) => !ic.estimate);
      if (moveFocus) {
        if (itemCarriers[1].status !== "solved") {
          timerId = setTimeout(
            () => itemCarriers[1].ref.current?.focus(),
            [timer]
          );
        } else if (itemCarriers[2].status !== "solved") {
          timerId = setTimeout(
            () => itemCarriers[2].ref.current?.focus(),
            [500]
          );
        } else if (itemCarriers[3].status !== "solved") {
          timerId = setTimeout(
            () => itemCarriers[3].ref.current?.focus(),
            [500]
          );
        }
      }
    }
    return () => clearTimeout(timerId);
  }, [itemCarriers]);

  useEffect(() => {
    let timerId;
    if (selected) {
      setMove(true);
      timerId = setTimeout(() => {
        setShowItems(false);
        setShowTable(true);
      }, [1500]);
    }
    return () => clearTimeout(timerId);
  }, [selected]);

  useEffect(() => {
    let timerId;
    if (showTable) {
      setDisableNext(false);
      setShowItems(false);
      setMove(false);
    } else {
      timerId = setTimeout(() => {
        setShowItems(true);
      }, [1700]);
    }
    return () => clearTimeout(timerId);
  }, [showTable, setDisableNext, setShowItems]);

  useEffect(() => {
    if (draw) {
      setShowItems(true);
      setShowTable(false);
    } else {
      levelRef.current = challengeLevel;
      setDraw(true);
    }
  }, [draw, challengeLevel, levelRef, setDraw, setShowItems]);

  useEffect(() => {
    let timerId;
    if ("iconRegular" in item && "iconRegular" in carrier) {
      timerId = setTimeout(() => {
        setMove(false);
        transportRef.current.focus();
      }, [1700]);
    }
    return () => clearTimeout(timerId);
  }, [item, carrier]);

  return [
    itemCarriers,
    k,
    kNumerator,
    handleNext,
    disableNext,
    setDisableNext,
    selected,
    item,
    setItem,
    carrier,
    setCarrier,
    confirmSelection,
    columnValue,
    checkVal,
    onChangeItem,
    kOptions,
    kVal,
    setKVal,
    verifyKVal,
    result,
    showSolution,
    showConfettiEmo,
    setShowConfettiEmo,
    showAntiConfetti,
    setShowAntiConfetti,
    equation,
    scorePct,
    showEquation,
    itemList,
    setItemList,
    carrierList,
    setCarrierList,
    numberLineRef,
    tableRef,
    equationRef,
    unitRate,
    move,
    setMove,
    expandCollapse,
    showInstructions,
    setShowInstructions,
    transportRef,
    draw,
    setDraw,
    levelRef,
    showItems,
    showTable,
  ];
};
export default useGetKEquations;
