import { useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";

const usePolyAnimation = ({ level, result }) => {
  const [polyVisible, setPolyVisible] = useState(false);
  const [showAntiConfetti, setShowAntiConfetti] = useState(true);
  const [showConfettiEmo, setShowConfettiEmo] = useState(true);
  const [, , resetPolyVisible] = useTimeoutFn(() => setPolyVisible(true), 5000);

  useEffect(() => {
    setPolyVisible(true);
    resetPolyVisible();
  }, [resetPolyVisible]);

  useEffect(() => {
    setShowConfettiEmo(result === "You Got it!" ? true : false);
    setShowAntiConfetti(result === "Incorrect" ? true : false);
  }, [result]);

  return [
    showAntiConfetti,
    setShowAntiConfetti,
    showConfettiEmo,
    setShowConfettiEmo,
    polyVisible,
  ];
};

export default usePolyAnimation;
