import "./App.css";
import React from "react";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import PageLayout from "./components/PageLayout/PageLayout";

const App = () => {
  return (
    <>
      <PageLayout />
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
