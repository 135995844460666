import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";

const ColumnDataK = ({ carriersQ, itemsQ, kNumerator, k, result }) => (
  <Transition
    as={Fragment}
    show={result ? true : false}
    enter="transform transition duration-[1000ms]"
    enterFrom="opacity-0"
    enterTo="opacity-100"
  >
    <td className="relative w-28 h-20 border-2 border-black text-center font-bold text-green-900 bg-slate-50">
      <div className="absolute top-0 bottom-0 left-0 w-full">
        <div className="flex flex-wrap place-content-center h-20">
          <div className="self-center text-sm max-[1024px]:text-xs ">
            {carriersQ}&nbsp;=&nbsp;
          </div>
          {kNumerator ? (
            <div className="flex flex-row place-content-center text-xs max-[1024px]:text-[10px]">
              <div className="flex flex-col place-content-center">
                <div className="self-center">{kNumerator}</div>
                <div className="self-center border border-blue-900 max-[1024px]:w-3 w-5 h-0" />
                <div className="self-center">{k}</div>
              </div>
            </div>
          ) : (
            <div className="self-center text-sm max-[1024px]:text-xs">{k}</div>
          )}
          <div className="self-center text-xl mt-2 max-[1024px]:text-xs">
            &nbsp;*
          </div>

          <div className="self-center text-sm max-[1024px]:text-xs">
            &nbsp;{itemsQ}
          </div>
        </div>
      </div>
    </td>
  </Transition>
);
export default ColumnDataK;
