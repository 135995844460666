import React, { Fragment, forwardRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import AnglesImageLines from "../AnglesImageLines/AnglesImageLines";
import AnglesImageText from "../AnglesImageText/AnglesImageText";

const AnglesImage = forwardRef(
  (
    {
      angle,
      angleType,
      r,
      curve1,
      curve2,
      equation,
      showText,
      maxWidth,
      supplementaryType,
      quadrant,
      draw,
      setDraw,
      levelRef,
      challengeLevel,
      setShowText,
      setDisableNext,
    },
    ref
  ) => {
    useEffect(() => {
      let timerId = setTimeout(
        () =>
          ref?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          }),
        [500]
      );
      return () => clearTimeout(timerId);
    }, [ref, maxWidth, equation]);

    return (
      <Transition
        as={Fragment}
        show={draw}
        beforeLeave={() => {
          levelRef.current = challengeLevel;
          setShowText(false);
        }}
        afterLeave={() => setDraw(true)}
      >
        <div
          ref={ref}
          className="w-full self-center flex flex-col place-content-center"
        >
          <svg
            width={r}
            height={r}
            xmlns="http://www.w3.org/2000/svg"
            className="self-center bg-[#63856a] border-[#d19f57] border-4"
          >
            <AnglesImageLines
              r={r}
              angle={angle}
              angleType={angleType}
              curve1={curve1}
              curve2={curve2}
              showText={showText}
              quadrant={quadrant}
            />
            <AnglesImageText
              equation={equation}
              showText={showText}
              angleType={angleType}
              supplementaryType={supplementaryType}
              r={r}
              quadrant={quadrant}
              setDisableNext={setDisableNext}
            />
          </svg>
        </div>
      </Transition>
    );
  }
);
export default AnglesImage;
