import LevelRows from "./LevelRows";
import TableHeaderRows from "./TableHeaderRows";

const DataTable = ({ scores, gameLabels, toggleUser, showPercent }) => (
  <div className="w-full p-2 flex flex-wrap place-content-evenly">
    {scores.map(({ user, show, games }) =>
      [{ label: user }, ...gameLabels].map(
        (
          { label, bgScoreColor, bgColor, bgLevelColor, keyName },
          mainIndex
        ) => (
          <div
            key={label}
            className={` ${
              mainIndex === 0 && "sticky top-5"
            } w-full ${bgScoreColor} ${
              !show && mainIndex > 0 ? "border-0" : "border border-black"
            } self-center flex flex-wrap place-content-center text-xs`}
          >
            <div
              className={`w-full self-center text-center flex flex-col place-content-center`}
            >
              <TableHeaderRows
                bgColor={bgColor}
                show={show}
                label={label}
                user={user}
                mainIndex={mainIndex}
                toggleUser={toggleUser}
              />

              {mainIndex > 0 && (
                <LevelRows
                  user={user}
                  games={games}
                  bgLevelColor={bgLevelColor}
                  showPercent={showPercent}
                  keyName={keyName}
                  show={show}
                />
              )}
            </div>
          </div>
        )
      )
    )}
  </div>
);
export default DataTable;
