import { BiShow, BiHide } from "react-icons/bi";

const TableHeaderRows = ({
  bgColor,
  show,
  label,
  user,
  mainIndex,
  toggleUser,
}) => (
  <div
    className={`overflow-auto self-center w-full font-bold break-all ${
      mainIndex > 0
        ? `border-b border-black ${bgColor} ${!show && "hidden"}`
        : "font-medium bg-cyan-300 py-3 px-1"
    }`}
  >
    <div className="flex flex-row place-content-center max-[1024px]:place-content-center">
      <div className="self-center">{label}</div>

      <button
        onClick={() => toggleUser({ user, show: !show })}
        className={`self-center px-2 text-xl max-[1024px]:text-sm ${
          show
            ? "text-red-500 hover:text-red-800"
            : "text-blue-500 hover:text-blue-800"
        }`}
      >
        {mainIndex === 0 && (show ? <BiHide /> : <BiShow />)}
      </button>
    </div>
  </div>
);

export default TableHeaderRows;
