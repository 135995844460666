import React, { forwardRef } from "react";
import "./style.css";
import { useGetAngleLines } from "../../../hooks";
import AnglesImage from "../AnglesImage/AnglesImage";
import Confetti from "react-confetti";
import AntiConfetti from "../../AntiConfetti/AntiConfetti";
import ConfettiEmo from "../../ConfettiEmo/ConfettiEmo";

const DrawAngles = forwardRef(
  (
    {
      maxWidth,
      angle,
      r,
      curve1,
      setCurve1,
      curve2,
      setCurve2,
      angleType,
      supplementaryType,
      equation,
      showText,
      result,
      width,
      height,
      showConfettiEmo,
      setShowConfettiEmo,
      showAntiConfetti,
      setShowAntiConfetti,
      quadrant,
      curvePoints,
      draw,
      setDraw,
      levelRef,
      challengeLevel,
      setShowText,
      setDisableNext,
    },
    ref
  ) => {
    useGetAngleLines({
      angle,
      r,
      setCurve1,
      setCurve2,
      angleType,
      supplementaryType,
      curvePoints,
    });

    return (
      <div className="flex flex-row place-content-center p-5">
        <AnglesImage
          angle={angle}
          angleType={angleType}
          supplementaryType={supplementaryType}
          r={r}
          curve1={curve1}
          curve2={curve2}
          equation={equation}
          showText={showText}
          ref={ref}
          maxWidth={maxWidth}
          quadrant={quadrant}
          draw={draw}
          setDraw={setDraw}
          levelRef={levelRef}
          challengeLevel={challengeLevel}
          setShowText={setShowText}
          setDisableNext={setDisableNext}
        />
        {result === "You Got it!" && (
          <>
            <Confetti
              numberOfPieces={200}
              recycle={false}
              width={width}
              height={height}
            />
            <ConfettiEmo
              showConfettiEmo={showConfettiEmo}
              setShowConfettiEmo={setShowConfettiEmo}
              afterEffects={() =>
                ref.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            />
          </>
        )}
        {result === "Incorrect" && (
          <AntiConfetti
            showAntiConfetti={showAntiConfetti}
            setShowAntiConfetti={setShowAntiConfetti}
            afterEffects={() =>
              ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          />
        )}
      </div>
    );
  }
);

export default DrawAngles;
