import "./style.css";
import { useEffect, useState } from "react";
import {
  useGetWindowSize,
  useGetEquations,
  useLineGroups,
  usePolyAnimation,
} from "../../../hooks";
import Confetti from "react-confetti";
import PolyImage from "../PolyImage/PolyImage";
import Equation from "../Equation/Equation";
import AntiConfetti from "../../AntiConfetti/AntiConfetti";
import ConfettiEmo from "../../ConfettiEmo/ConfettiEmo";

const DrawPolygon = ({
  level,
  challengeLevel,
  getLines,
  solved,
  setSolved,
  score,
  setScore,
  linesDrawn,
  groupsStatus,
  setGroupsStatus,
  setChallengeSolved,
  challengeSolved,
  saveScores,
}) => {
  const [currentText, setCurrentText] = useState(null);
  const [maxWidth, maxHeight, width, height, zoomLevel] = useGetWindowSize();
  const [
    polyColorPoints,
    linePoints,
    vgLeft,
    hgTop,
    hgBelow,
    hg2,
    vgRight,
    vg2,
  ] = getLines({
    maxWidth,
    maxHeight,
    level,
    width,
  });
  const [
    linePosition,
    verticalGroupLeft,
    verticalGroupRight,
    verticalGroup2,
    horizontalGroupBelow,
    horizontalGroupTop,
    horizontalGroup2,
    polyPoints,
  ] = useLineGroups({
    polyColorPoints,
    linePoints,
    vgLeft,
    hgTop,
    hgBelow,
    hg2,
    vgRight,
    vg2,
  });
  const [
    groups,
    equation,
    equationVariant,
    equationVariant2,
    checkResult,
    showSolution,
    result,
    scorePct,
    xVal,
    setXVal,
    disableSubmit,
    currentGroupStatus,
    selected,
    svgRef,
    xRef,
  ] = useGetEquations({
    verticalGroupLeft,
    verticalGroupRight,
    verticalGroup2,
    horizontalGroupBelow,
    horizontalGroupTop,
    horizontalGroup2,
    solved,
    setSolved,
    score,
    setScore,
    challengeLevel,
    maxWidth,
    zoomLevel,
    groupsStatus,
    setGroupsStatus,
    setChallengeSolved,
    linesDrawn,
    saveScores,
  });
  const [
    showAntiConfetti,
    setShowAntiConfetti,
    showConfettiEmo,
    setShowConfettiEmo,
    polyVisible,
  ] = usePolyAnimation({ level, result });

  useEffect(() => setCurrentText(null), [linePoints]);

  const lineProps = {
    maxWidth,
    maxHeight,
    width,
    linesDrawn,
    linePosition,
    polyVisible,
    polyPoints,
    currentText,
    setCurrentText,
    selected,
    groups,
    currentGroupStatus,
    showSolution,
    groupsStatus,
    challengeSolved,
  };

  return (
    <div className="flex flex-col place-content-center">
      <Equation
        equation={equation}
        currentGroupStatus={currentGroupStatus}
        equationVariant={equationVariant}
        equationVariant2={equationVariant2}
        ref={xRef}
        showSolution={showSolution}
        result={result}
        xVal={xVal}
        setXVal={setXVal}
        disableSubmit={disableSubmit}
        checkResult={checkResult}
        scorePct={scorePct}
        solved={solved}
        score={score}
        challengeSolved={challengeSolved}
      />
      <PolyImage {...lineProps} ref={svgRef} />
      {result === "You Got it!" && (
        <>
          <Confetti
            numberOfPieces={200}
            recycle={false}
            width={width}
            height={height}
          />
          <ConfettiEmo
            showConfettiEmo={showConfettiEmo}
            setShowConfettiEmo={setShowConfettiEmo}
            afterEffects={() =>
              svgRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          />
        </>
      )}
      {result === "Incorrect" && (
        <AntiConfetti
          showAntiConfetti={showAntiConfetti}
          setShowAntiConfetti={setShowAntiConfetti}
          afterEffects={() =>
            svgRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        />
      )}
    </div>
  );
};
export default DrawPolygon;
