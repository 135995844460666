const ItemsImageCount = ({
  challengeLevel,
  item,
  items,
  carriers,
  index,
  index2,
}) => (
  <div className={`self-center ${challengeLevel === 2 && "ml-1"}`}>
    {challengeLevel < 2 ? (
      item?.iconSmall
    ) : index2 < Math.ceil(items / carriers) - 1 ? (
      item?.iconSmall
    ) : (
      <svg
        className={` ${item?.fill} text-base`}
        height="1em"
        width="1em"
        viewBox={`${0} ${0} ${item?.viewBox?.width || 0} ${
          item?.viewBox?.height || 0
        }`}
        strokeWidth="0"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={item?.d}></path>
        <rect
          className={`${index % 2 === 0 ? "fill-green-300" : "fill-green-200"}`}
          x={
            item?.viewBox?.width *
              (items / carriers - Math.floor(items / carriers)) || 0
          }
          y="0"
          width="100%"
          height="100%"
        />
      </svg>
    )}
  </div>
);
export default ItemsImageCount;
