import SchoolFilter from "./SchoolFilter";
import RoleFilter from "./RoleFilter";

const Users = ({
  setFilterSchool,
  setFilterRole,
  userDataFiltered,
  getGameDetails,
  schoolNames,
}) => {
  const roleDisplay = { teacher: "Teacher", student: "Student" };

  return (
    <table className="w-full bg-green-200 self-center m-2 table-fixed border border-black">
      <thead>
        <tr className="break-words">
          {["User", "User Signed In", "Role", "School", "Game Details"].map(
            (label) => (
              <th key={label} className="border border-black p-1 text-center">
                {label}
              </th>
            )
          )}
        </tr>
        <tr className="break-words">
          {["User", "User Signed In", "Role", "School", "Game Details"].map(
            (label) => (
              <th key={label} className="border border-black p-1 text-center">
                {label === "User" ? (
                  `${userDataFiltered?.length} users`
                ) : label === "User Signed In" ? (
                  `${
                    userDataFiltered.filter(({ registered }) => registered)
                      .length
                  } users`
                ) : label === "School" ? (
                  <SchoolFilter
                    label={label}
                    setFilterSchool={setFilterSchool}
                    schoolNames={schoolNames}
                  />
                ) : (
                  label === "Role" && (
                    <RoleFilter
                      label={label}
                      setFilterRole={setFilterRole}
                      schoolNames={schoolNames}
                    />
                  )
                )}
              </th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {userDataFiltered?.length > 0 &&
          userDataFiltered.map(({ user, registered, role }) => (
            <tr key={user} className="break-words border border-black">
              <td className="p-1 text-center border border-black">{user}</td>
              <td className="p-1 text-center border border-black">
                {registered ? "true" : "false"}
              </td>
              <td className="p-1 text-center border border-black">
                {role ? (
                  <p className="font-medium">{roleDisplay[role.type]}</p>
                ) : (
                  "N/A"
                )}
              </td>
              <td className="p-1 text-center border border-black">
                {role ? <p className="font-medium">{role.school}</p> : "N/A"}
              </td>
              <td className="break-all p-1 text-center border border-black">
                <button
                  onClick={() => getGameDetails({ user })}
                  className="text-blue-600"
                >
                  Game Details
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
export default Users;
