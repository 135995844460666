import { useEffect, useState } from "react";

const useGetAngleText = ({ quadrant, angleType, r, supplementaryType }) => {
  const [rightAngle, setRightAngle] = useState("");
  const [textYellow, setTextYellow] = useState("");
  const [textViolet, setTextViolet] = useState("");
  useEffect(() => {
    if (quadrant && angleType) {
      if (quadrant === 4) {
        setRightAngle(
          angleType === 2 &&
            `M${r / 2 + (r > 300 ? 15 : 10)},${r / 2} ${
              r / 2 + (r > 300 ? 15 : 10)
            },${r / 2 - (r > 300 ? 15 : 10)} ${r / 2},${
              r / 2 - (r > 300 ? 15 : 10)
            }`
        );
        setTextYellow({ x: r / 2 - 15, y: r / 4 });
        setTextViolet(
          angleType === 1 && supplementaryType === 2
            ? { x: r / 2 - 15, y: (r * 3) / 4 }
            : { x: (r * 3) / 4, y: r / 2 + 15 }
        );
      } else if (quadrant === 3) {
        setRightAngle(
          angleType === 2 &&
            `M${r / 2 - (r > 300 ? 15 : 10)},${r / 2} ${
              r / 2 - (r > 300 ? 15 : 10)
            },${r / 2 - (r > 300 ? 15 : 10)} ${r / 2},${
              r / 2 - (r > 300 ? 15 : 10)
            }`
        );
        setTextYellow({ x: r / 4 - 5, y: r / 2 + 20 });
        setTextViolet(
          angleType === 1 && supplementaryType === 2
            ? { x: r / 2 + 5 + r / 4, y: r / 2 + 20 }
            : { x: r / 2 + 5, y: r / 4 }
        );
      } else if (quadrant === 2) {
        setRightAngle(
          angleType === 2 &&
            `M${r / 2},${r / 2 + (r > 300 ? 15 : 10)} ${
              r / 2 - (r > 300 ? 15 : 10)
            },${r / 2 + (r > 300 ? 15 : 10)} ${r / 2 - (r > 300 ? 15 : 10)},${
              r / 2
            }`
        );
        setTextYellow({ x: r / 2 + 10, y: (r * 3) / 4 - 5 });
        setTextViolet(
          angleType === 1 && supplementaryType === 2
            ? { x: r / 2 + 10, y: r / 4 - 5 }
            : { x: r / 4 - 5, y: r / 2 - 10 }
        );
      } else if (quadrant === 1) {
        setRightAngle(
          angleType === 2 &&
            `M${r / 2},${r / 2 + (r > 300 ? 15 : 10)} ${
              r / 2 + (r > 300 ? 15 : 10)
            },${r / 2 + (r > 300 ? 15 : 10)} ${r / 2 + (r > 300 ? 15 : 10)},${
              r / 2
            }`
        );
        setTextYellow({ x: (r * 3) / 4 - 5, y: r / 2 - 10 });
        setTextViolet(
          angleType === 1 && supplementaryType === 2
            ? { x: r / 4 - 5, y: r / 2 - 10 }
            : { x: r / 2 - 20, y: (r * 3) / 4 - 5 }
        );
      }
    }
  }, [quadrant, angleType, r, supplementaryType]);

  return [rightAngle, textYellow, textViolet];
};
export default useGetAngleText;
