const FactorButtons = ({
  svgDim,
  pos,
  row,
  checkFactor,
  factors,
  mainNumberType,
  coords,
  factorType,
}) => (
  <g>
    <foreignObject
      x={
        svgDim.width *
          ((pos + (factorType === "factor1" ? pos - 1 : pos + 1)) /
            Math.pow(2, row + 1 + 1)) -
        35 -
        10
      }
      y={(row + 1) * 100}
      width="30"
      height="30"
    >
      <button
        onClick={() => {
          checkFactor({
            number: factors[factorType].number,
            mainNumberType,
            row,
            type: factorType,
            btnType: "p",
            coords: {
              x:
                svgDim.width *
                  ((pos + (factorType === "factor1" ? pos - 1 : pos + 1)) /
                    Math.pow(2, row + 1 + 1)) -
                35,
              y: (row + 1) * 100,
            },
            parentCoords: {
              x: coords.x,
              y: coords.y,
            },
          });
        }}
        className="font-bold text-purple-600 focus:text-purple-800 hover:text-purple-800 w-full h-full bg-slate-100 border-black border focus:bg-slate-300 hover:bg-slate-300 rounded-full"
      >
        P
      </button>
    </foreignObject>
    <foreignObject
      x={
        svgDim.width *
          ((pos + (factorType === "factor1" ? pos - 1 : pos + 1)) /
            Math.pow(2, row + 1 + 1)) -
        35 -
        10
      }
      y={(row + 1) * 100 + 45}
      width="30"
      height="30"
    >
      <button
        onClick={() => {
          checkFactor({
            number: factors[factorType].number,
            mainNumberType,
            row,
            type: factorType,
            btnType: "c",
            coords: {
              x:
                svgDim.width *
                  ((pos + (factorType === "factor1" ? pos - 1 : pos + 1)) /
                    Math.pow(2, row + 1 + 1)) -
                35,
              y: (row + 1) * 100,
            },
            parentCoords: {
              x: coords.x,
              y: coords.y,
            },
          });
        }}
        className="font-bold text-purple-600 focus:text-purple-800 hover:text-purple-800 w-full h-full bg-slate-100 border-black border focus:bg-slate-300 hover:bg-slate-300 rounded-full"
      >
        C
      </button>
    </foreignObject>
  </g>
);
export default FactorButtons;
