import PolyText from "../PolyText/PolyText";

const PolyLines = ({
  linePosition,
  selected,
  groups,
  groupsStatus,
  linesDrawn,
  setCurrentText,
  currentGroupStatus,
  showSolution,
  width,
}) => {
  return linePosition.map(({ ref, x1, y1, x2, y2, type, line }, index) => (
    <g key={index}>
      <line
        style={{
          visibility:
            line === "placeHolder"
              ? "hidden"
              : linesDrawn
              ? "visible"
              : "hidden",
          cursor: line === "placeHolder" ? "auto" : "pointer",
          outline: "2px solid transparent",
          outlineOffset: "2px",
        }}
        onClick={() => selected({ lineRef: ref.current, type })}
        onFocus={() => selected({ lineRef: ref.current, type })}
        key={index}
        ref={ref}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke="black"
        strokeWidth={line === "placeHolder" ? "5" : "15"}
        strokeLinecap="round"
        strokeDasharray={line === "placeHolder" ? "7,7" : ""}
        tabIndex={
          groupsStatus.filter(
            (gs) => gs.type === type && gs?.showStatus?.status
          ).length > 0
            ? "-1"
            : "0"
        }
      />
      <PolyText
        setCurrentText={setCurrentText}
        currentGroupStatus={currentGroupStatus}
        showSolution={showSolution}
        groups={groups}
        width={width}
        groupsStatus={groupsStatus}
        type={type}
      />
    </g>
  ));
};
export default PolyLines;
