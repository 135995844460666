import { useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";

const useLineAnimation = ({ result }) => {
  const [linesDrawn, setLinesDrawn] = useState(false);
  const [showAntiConfetti, setShowAntiConfetti] = useState(true);
  const [showConfettiEmo, setShowConfettiEmo] = useState(true);
  const [, , resetLinesDrawn] = useTimeoutFn(() => {
    if (result) {
      setLinesDrawn(true);
    }
  }, 3100);

  useEffect(() => {
    setLinesDrawn(false);
    if (result) {
      resetLinesDrawn();
    }
  }, [result, resetLinesDrawn]);

  useEffect(() => {
    resetLinesDrawn();
  }, [resetLinesDrawn]);

  useEffect(() => {
    setShowConfettiEmo(result === "You Got it!" ? true : false);
    setShowAntiConfetti(result === "Incorrect" ? true : false);
  }, [result]);

  return [
    showAntiConfetti,
    setShowAntiConfetti,
    showConfettiEmo,
    setShowConfettiEmo,
    linesDrawn,
  ];
};

export default useLineAnimation;
