const LevelRows = ({
  user,
  games,
  bgLevelColor,
  showPercent,
  keyName,
  show,
}) => (
  <div
    key={user}
    className={`${
      !show && "hidden"
    } w-full self-center flex flex-wrap place-content-evenly`}
  >
    {[
      { levelLabel: "Level 1", levelKeyName: "level1" },
      { levelLabel: "Level 2", levelKeyName: "level2" },
      { levelLabel: "Level 3", levelKeyName: "level3" },
    ].map(({ levelLabel, levelKeyName }, index) => (
      <div
        key={levelLabel}
        className="font-medium text-xs max-[1024px]:w-full w-1/3 self-center flex flex-col place-content-center"
      >
        <p className={`${bgLevelColor} border border-black`}>{levelLabel}</p>
        <div className="text-xs border border-black flex flex-wrap place-content-evenly">
          <div className="self-center p-1">
            <p className="break-all">Solved:</p>
            <p>{games[keyName]?.solved[levelKeyName] || 0}</p>
          </div>
          <div className="self-center p-1">
            <p className="break-all">Score:</p>
            <p>{games[keyName]?.score[levelKeyName] || 0}</p>
          </div>
        </div>
        {showPercent && (
          <div className="text-xs border border-black flex flex-wrap place-content-evenly">
            <div className="self-center p-1">
              <p className="font-medium break-all">
                {levelLabel}:{" "}
                {Math.round(
                  (games[keyName]?.score[levelKeyName] * 100) /
                    games[keyName]?.solved[levelKeyName]
                ) || 0}
                %
              </p>
            </div>
          </div>
        )}
      </div>
    ))}
    {showPercent && (
      <div className="w-full text-xs border border-black flex flex-wrap place-content-evenly">
        <div className="self-center p-1">
          <p className="font-medium break-all">
            overall:{" "}
            {Math.round(
              ((games[keyName]?.score.level1 +
                games[keyName]?.score.level2 +
                games[keyName]?.score.level3) *
                100) /
                (games[keyName]?.solved.level1 +
                  games[keyName]?.solved.level2 +
                  games[keyName]?.solved.level3)
            ) || 0}
            %
          </p>
        </div>
      </div>
    )}
  </div>
);
export default LevelRows;
