import { useEffect, useState } from "react";
const HoverText = ({ currentText, maxWidth, maxHeight }) => {
  const [xCoord, setXCoord] = useState("");
  const [yCoord, setYCoord] = useState("");

  useEffect(() => {
    if (currentText?.text) {
      let digits = currentText?.text.toString().split("").length;
      let x, y;
      x =
        Math.abs(parseInt(currentText?.x) - maxWidth) < 100
          ? parseInt(currentText?.x) - 100
          : parseInt(currentText?.x);
      if (digits === 3 && currentText.cn === "uprightText fill-red-600") {
        y =
          Math.abs(parseInt(currentText?.y) - maxHeight) < 150
            ? parseInt(currentText?.y) - 150
            : parseInt(currentText?.y) + 150;
      } else {
        y =
          Math.abs(parseInt(currentText?.y) - maxHeight) < 100
            ? parseInt(currentText?.y) - 100
            : parseInt(currentText?.y) + 100;
      }
      setXCoord(x);
      setYCoord(y);
    }
  }, [currentText, maxWidth, maxHeight]);
  return (
    <text
      x={xCoord}
      y={yCoord}
      className={`z-40 font-black ${
        currentText.cn === "fill-blue-600" ? "fill-orange-600" : "fill-blue-600"
      }  text-3xl `}
    >
      {currentText.text}
    </text>
  );
};

export default HoverText;
