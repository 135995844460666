import PolyLines from "../PolyLines/PolyLines";
import HoverText from "../HoverText/HoverText";

const PolyOutline = ({
  polyPoints,
  linePosition,
  selected,
  groups,
  groupsStatus,
  linesDrawn,
  setCurrentText,
  currentGroupStatus,
  showSolution,
  width,
  maxWidth,
  maxHeight,
  challengeSolved,
  currentText,
}) => (
  <svg
    width={maxWidth}
    height={maxHeight}
    viewBox={`0 0 ${maxWidth} ${maxHeight}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    {!linesDrawn && polyPoints.length > 0 && (
      <path
        className="path fill-slate-200"
        stroke="black"
        strokeWidth="15"
        d={`M${polyPoints}z`}
        pathLength="1"
        strokeLinecap="round"
      />
    )}

    {linesDrawn && (
      <polyline
        points={polyPoints}
        className={`${challengeSolved ? "fill-purple-300" : "fill-cyan-200"}`}
      />
    )}
    {linePosition?.length > 0 && (
      <PolyLines
        linePosition={linePosition}
        selected={selected}
        groups={groups}
        groupsStatus={groupsStatus}
        linesDrawn={linesDrawn}
        setCurrentText={setCurrentText}
        currentGroupStatus={currentGroupStatus}
        showSolution={showSolution}
        width={width}
      />
    )}

    {currentText?.text && (
      <HoverText
        currentText={currentText}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      />
    )}
  </svg>
);
export default PolyOutline;
