import { BiSolidVolumeMute } from "react-icons/bi";
import { GoUnmute } from "react-icons/go";

const MuteButtons = ({ mute, setMute }) => (
  <div className="m-1 p-1 self-center flex flex-row place-content-center">
    <button
      onClick={() => setMute(true)}
      className={`${
        mute ? "text-slate-500" : "text-red-600 bg-yellow-300 "
      } self-center text-2xl max-[1024px]:text-lg`}
      disabled={mute}
    >
      <BiSolidVolumeMute />
    </button>
    <button
      onClick={() => setMute(false)}
      className={`${
        !mute ? "text-slate-500" : "text-red-600 bg-yellow-300 "
      } self-center text-2xl max-[1024px]:text-lg ml-2`}
      disabled={!mute}
    >
      <GoUnmute />
    </button>
  </div>
);
export default MuteButtons;
