import React from "react";
import CurrentLevel from "../../CurrentLevel/CurrentLevel";
import { useGetAngleEquations, useGetWindowSize } from "../../../hooks";
import DrawAngles from "../DrawAngles/DrawAngles";
import AnglesEquation from "../AnglesEquation/AnglesEquation";
import SignUpAndVideoLinks from "../../SignUpAndVideoLinks/SignUpAndVideoLinks";

const Angles = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  setChallengeLevel,
  showTabs,
  setShowSignin,
  authData,
  authChecked,
  setLogoutConfirm,
  saveScores,
}) => {
  const [maxWidth, maxHeight, width, height] = useGetWindowSize();
  const [
    disableNext,
    angle,
    r,
    curve1,
    setCurve1,
    curve2,
    setCurve2,
    angleType,
    equation,
    showText,
    svgRef,
    showSolution,
    result,
    aVal,
    setAVal,
    checkAVal,
    disableSubmit,
    scorePct,
    showConfettiEmo,
    setShowConfettiEmo,
    showAntiConfetti,
    setShowAntiConfetti,
    supplementaryType,
    showInstructions,
    setShowInstructions,
    quadrant,
    curvePoints,
    answerRef,
    draw,
    setDraw,
    levelRef,
    setShowText,
    setDisableNext,
  ] = useGetAngleEquations({
    maxWidth,
    score,
    setScore,
    challengeLevel,
    solved,
    setSolved,
    saveScores,
  });

  return (
    <div className="absolute top-auto right-0 left-0">
      <CurrentLevel
        challengeLevel={challengeLevel}
        setChallengeLevel={setChallengeLevel}
        handleNext={() => setDraw(false)}
        disableNext={disableNext}
        linesDrawn={!disableNext}
        showTabs={showTabs}
      />
      <div className="flex flex-col place-content-center">
        <SignUpAndVideoLinks
          vid={"6PCEJQu4Sm4"}
          showInstructions={showInstructions}
          setShowInstructions={setShowInstructions}
          setShowSignin={setShowSignin}
          authData={authData}
          authChecked={authChecked}
          setLogoutConfirm={setLogoutConfirm}
        />
        <div className="self-center w-full">
          <AnglesEquation
            equation={equation}
            showSolution={showSolution}
            result={result}
            aVal={aVal}
            setAVal={setAVal}
            disableSubmit={disableSubmit}
            challengeLevel={challengeLevel}
            scorePct={scorePct}
            checkAVal={checkAVal}
            solved={solved}
            score={score}
            showText={showText}
            ref={answerRef}
            disableNext={disableNext}
          />

          <DrawAngles
            maxWidth={maxWidth}
            width={width}
            maxHeight={maxHeight}
            height={height}
            angle={angle}
            r={r}
            curve1={curve1}
            setCurve1={setCurve1}
            curve2={curve2}
            setCurve2={setCurve2}
            angleType={angleType}
            supplementaryType={supplementaryType}
            equation={equation}
            showText={showText}
            ref={svgRef}
            result={result}
            showConfettiEmo={showConfettiEmo}
            setShowConfettiEmo={setShowConfettiEmo}
            showAntiConfetti={showAntiConfetti}
            setShowAntiConfetti={setShowAntiConfetti}
            quadrant={quadrant}
            curvePoints={curvePoints}
            draw={draw}
            setDraw={setDraw}
            levelRef={levelRef}
            challengeLevel={challengeLevel}
            setShowText={setShowText}
            setDisableNext={setDisableNext}
          />
        </div>
      </div>
    </div>
  );
};

export default Angles;
