const PartyItems = ({ items, coords }) =>
  items.map(({ mainIcon, label }, index) => (
    <g key={index}>
      <foreignObject
        x={coords[index].x + 100}
        y={coords[index].y}
        width="50"
        height="70"
      >
        <div
          className={`${
            label === "Baseball cards" && "border-2 border-sky-600 bg-white"
          }  flex flex-col place-content-center self-center text-center font-bold w-full h-full`}
        >
          <div className="flex flex-col place-content-center self-center text-center font-bold w-full h-full">
            {mainIcon}
            <p className="text-sky-700 text-[10px] mb-1">{label}</p>
          </div>
        </div>
      </foreignObject>
      {label === "Baseball cards" && (
        <g>
          <path
            className="fill-sky-600 stroke-sky-600"
            strokeWidth="2"
            d={`M${coords[index].x + 100},${coords[index].y + 2} ${
              coords[index].x + 110
            },${coords[index].y + 2}  Q${coords[index].x + 110},${
              coords[index].y + 10
            } ${coords[index].x + 100},${coords[index].y + 10}`}
            pathLength="1"
          />
          <path
            className="fill-sky-600 stroke-sky-600"
            strokeWidth="2"
            d={`M${coords[index].x + 150},${coords[index].y + 2} ${
              coords[index].x + 140
            },${coords[index].y + 2}  Q${coords[index].x + 140},${
              coords[index].y + 10
            } ${coords[index].x + 150},${coords[index].y + 10}`}
            pathLength="1"
          />
          <path
            className="fill-sky-600 stroke-sky-600"
            strokeWidth="2"
            d={`M${coords[index].x + 100},${coords[index].y + 68} ${
              coords[index].x + 110
            },${coords[index].y + 68}  Q${coords[index].x + 110},${
              coords[index].y + 60
            } ${coords[index].x + 100},${coords[index].y + 60}`}
            pathLength="1"
          />
          <path
            className="fill-sky-600 stroke-sky-600"
            strokeWidth="2"
            d={`M${coords[index].x + 150},${coords[index].y + 68} ${
              coords[index].x + 140
            },${coords[index].y + 68}  Q${coords[index].x + 140},${
              coords[index].y + 60
            } ${coords[index].x + 150},${coords[index].y + 60}`}
            pathLength="1"
          />
        </g>
      )}
    </g>
  ));
export default PartyItems;
