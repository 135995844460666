const DateFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setScores,
  clear,
}) => (
  <div className="w-full self-center p-2 flex flex-col place-content-center">
    <p className="self-center text-center font-medium">
      Filter by Date, if only start is provided, the data displayed will be for
      that particular date.
    </p>
    <div className="w-full self-center flex flex-wrap place-content-center">
      <div className="self-center text-center p-2 flex flex-wrap place-content-center">
        <p className="font-bold self-center text-center w-20">Start</p>
        <input
          className="h-10 p-2 bg-green-100 rounded-lg"
          type="date"
          name="start"
          value={startDate}
          onChange={(event) => {
            setStartDate(event.target.value);
            setScores([]);
          }}
        />
      </div>
      <div className="self-center text-center p-2 flex flex-wrap place-content-center">
        <p className="font-bold self-center text-center w-20">End (optional)</p>
        <input
          className={`h-10 p-2 rounded-lg ${
            !startDate ? "bg-slate-300 text-slate-300" : "bg-green-100"
          }`}
          type="date"
          name="end"
          value={endDate}
          onChange={(event) => {
            setEndDate(event.target.value);
            setScores([]);
          }}
          disabled={!startDate}
        />
      </div>
      <div className="w-40 self-center text-center p-2 flex flex-wrap place-content-center">
        <button
          className="w-fit border rounded-full bg-yellow-300 focus:outline-none focus:bg-yellow-500 focus:text-white hover:bg-yellow-600 hover:text-white p-2 font-bold"
          onClick={clear}
        >
          Clear
        </button>
      </div>
    </div>
  </div>
);
export default DateFilter;
