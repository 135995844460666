import { useEffect, useState } from "react";

const useLineGroups = ({
  polyColorPoints,
  linePoints,
  vgLeft,
  hgTop,
  hgBelow,
  hg2,
  vgRight,
  vg2,
}) => {
  const [linePosition, setLinePosition] = useState([]);
  const [verticalGroupLeft, setVerticalGroupLeft] = useState([]);
  const [verticalGroupRight, setVerticalGroupRight] = useState([]);
  const [verticalGroup2, setVerticalGroup2] = useState([]);
  const [horizontalGroupBelow, setHorizontalGroupBelow] = useState([]);
  const [horizontalGroupTop, setHorizontalGroupTop] = useState([]);
  const [horizontalGroup2, setHorizontalGroup2] = useState([]);
  const [polyPoints, setPolyPoints] = useState("");

  useEffect(() => setPolyPoints(`${polyColorPoints}`), [polyColorPoints]);
  useEffect(() => setLinePosition(linePoints), [linePoints]);
  useEffect(() => setVerticalGroupLeft(vgLeft), [vgLeft]);
  useEffect(() => setHorizontalGroupTop(hgTop), [hgTop]);
  useEffect(() => setHorizontalGroupBelow(hgBelow), [hgBelow]);
  useEffect(() => setHorizontalGroup2(hg2), [hg2]);
  useEffect(() => setVerticalGroupRight(vgRight), [vgRight]);
  useEffect(() => setVerticalGroup2(vg2), [vg2]);

  return [
    linePosition,
    verticalGroupLeft,
    verticalGroupRight,
    verticalGroup2,
    horizontalGroupBelow,
    horizontalGroupTop,
    horizontalGroup2,
    polyPoints,
  ];
};

export default useLineGroups;
