import { useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";
const useScoreAnimation = ({ scorePct, scoreChange, setScoreChange }) => {
  const [previousShowing, setPreviousShowing] = useState(true);
  const [newShowing, setNewShowing] = useState(false);

  const [, , resetPreviousShowing] = useTimeoutFn(() => {
    setPreviousShowing(false);
  }, 10);
  const [, , resetNewShowing] = useTimeoutFn(() => {
    if (!previousShowing) {
      setNewShowing(true);
    }
  }, 500);

  useEffect(() => {
    setNewShowing(false);
    resetNewShowing();
    if (scorePct.currentScore !== "0%") {
      setPreviousShowing(true);
      resetPreviousShowing();
    }
  }, [scorePct.currentScore, resetPreviousShowing, resetNewShowing]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setScoreChange(false);
    }, [1000]);
    return () => clearTimeout(timerId);
  }, [scoreChange, setScoreChange]);

  return [previousShowing, newShowing];
};
export default useScoreAnimation;
