import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import Fractions from "../../Fractions/Fractions";
import RateColumn from "./RateColumn";
import RateLabelColumn from "./RateLabelColumn";

const UnitRateRow2 = ({ result, challengeLevel, k, kNumerator, unitRate }) => {
  return (
    <Transition
      as={Fragment}
      show={result ? true : false}
      enter="transform transition duration-[1000ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <tr className="text-green-900 bg-cyan-200">
        <RateColumn
          k={k}
          kNumerator={kNumerator}
          unitRate={unitRate}
          challengeLevel={challengeLevel}
          reverse={true}
        />
        <RateLabelColumn challengeLevel={challengeLevel} reverse={true} />
        <td className="w-24 border-2 border-black text-center font-bold">
          {challengeLevel < 2 ? (
            <Fractions numerator={kNumerator} denominator={k} small={false} />
          ) : (
            1
          )}
        </td>
      </tr>
    </Transition>
  );
};
export default UnitRateRow2;
