import clsx from "clsx";
import { Field, Select } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";

const SchoolFilter = ({ label, setFilterSchool, schoolNames }) => (
  <div className="w-full">
    <Field>
      <div className="relative">
        <Select
          onChange={(event) => setFilterSchool(event.target.value)}
          className={clsx(
            "mt-3 block w-full appearance-none rounded-lg border-none py-1.5 px-3 text-sm/6",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2",
            "*:text-black text-center"
          )}
        >
          <option value="">{schoolNames.length > 0 && "All Schools"}</option>
          {schoolNames?.length > 0 &&
            [...schoolNames, "N/A"].map((school, index) => (
              <option key={index} value={school}>
                {school}
              </option>
            ))}
        </Select>
        <BiChevronDown
          className="group pointer-events-none absolute top-2.5 right-2.5 size-4"
          aria-hidden="true"
        />
      </div>
    </Field>
  </div>
);
export default SchoolFilter;
