import Fractions from "../../Fractions/Fractions";
import DragImage from "../DragImage/DragImage";

const Block = ({
  id,
  tabIndex,
  f,
  dragStart = () => {},
  dragEnd = () => {},
  touchStart,
  touchEnd,
  touchMove,
  touchEleCoords,
  boxWidth,
  notDraggable,
  cn,
  keyDown,
  focussed = () => {},
  blurred = () => {},
  dragged,
  blockHeight,
}) => (
  <>
    <div
      id={id}
      tabIndex={tabIndex}
      onDragStart={dragStart}
      onDragEnd={dragEnd}
      draggable={notDraggable ? false : true}
      className={`${cn} flex place-content-center`}
      style={{ width: f ? boxWidth / f : 0, height: blockHeight }}
      onKeyDown={keyDown}
      onTouchStart={touchStart}
      onTouchMove={touchMove}
      onTouchEnd={touchEnd}
      onFocus={focussed}
      onBlur={blurred}
    >
      {f > 1 ? (
        <Fractions
          small={false}
          numerator={1}
          denominator={f}
          dividerColor={"border-black self-center"}
        />
      ) : (
        <p className="text-3xl max-[1024px]:text-xl text-center self-center">
          1
        </p>
      )}
    </div>
    {touchEleCoords && f === dragged?.f && (
      <DragImage
        cn={cn}
        f={f}
        touchEleCoords={touchEleCoords}
        boxWidth={boxWidth}
        blockHeight={blockHeight}
      />
    )}
  </>
);
export default Block;
