import clsx from "clsx";
import { Field, Select } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";

const RoleFilter = ({ label, setFilterRole }) => {
  const roleValue = { Teacher: "teacher", Student: "student", "N/A": "N/A" };

  return (
    <div className="w-full">
      <Field>
        <div className="relative">
          <Select
            onChange={(event) => setFilterRole(roleValue[event.target.value])}
            className={clsx(
              "mt-3 block w-full appearance-none rounded-lg border-none py-1.5 px-3 text-sm/6",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2",
              "*:text-black text-center"
            )}
          >
            <option value="">All Roles</option>
            {["Teacher", "Student", "N/A"].map((role, index) => (
              <option key={index} value={role}>
                {role}
              </option>
            ))}
          </Select>
          <BiChevronDown
            className="group pointer-events-none absolute top-2.5 right-2.5 size-4"
            aria-hidden="true"
          />
        </div>
      </Field>
    </div>
  );
};
export default RoleFilter;
