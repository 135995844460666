import { useCallback, useEffect, useState, useMemo, useRef } from "react";

const useGetEquations = ({
  verticalGroupLeft,
  verticalGroupRight,
  verticalGroup2,
  horizontalGroupBelow,
  horizontalGroupTop,
  horizontalGroup2,
  solved,
  setSolved,
  score,
  setScore,
  challengeLevel,
  maxWidth,
  groupsStatus,
  setGroupsStatus,
  zoomLevel,
  setChallengeSolved,
  linesDrawn,
  saveScores,
}) => {
  const svgRef = useRef(null);
  const xRef = useRef(null);
  const [showHBNumbers, setShowHBNumbers] = useState(false);
  const [showH2Numbers, setShowH2Numbers] = useState(false);
  const [showHTNumbers, setShowHTNumbers] = useState(false);
  const [showVLNumbers, setShowVLNumbers] = useState(false);
  const [showVRNumbers, setShowVRNumbers] = useState(false);
  const [showV2Numbers, setShowV2Numbers] = useState(false);
  const [equation, setEquation] = useState("");
  const [equationVariant, setEquationVariant] = useState("");
  const [equationVariant2, setEquationVariant2] = useState("");
  const [, setDisplayText] = useState([]);
  const [solution, setSolution] = useState(null);
  const [showSolution, setShowSolution] = useState(false);
  const [result, setResult] = useState("");
  const [scorePct, setScorePct] = useState({
    previousScore: "0%",
    currentScore: "0%",
  });
  const [xVal, setXVal] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [currentGroup, setCurrentGroup] = useState("");
  const [currentGroupStatus, setCurrentGroupStatus] = useState({
    status: false,
    result: false,
  });
  const [screenChange, setScreenChange] = useState(false);

  const groups = useMemo(
    () => [
      {
        group: verticalGroupLeft,
        showFn: setShowVLNumbers,
        show: showVLNumbers,
        color: "red",
        type: "verticalGroupLeft",
        cn: "uprightText fill-red-600",
      },
      {
        group: verticalGroupRight,
        showFn: setShowVRNumbers,
        show: showVRNumbers,
        color: "red",
        type: "verticalGroupRight",
        cn: "uprightText fill-red-600",
      },
      {
        group: horizontalGroupBelow,
        showFn: setShowHBNumbers,
        show: showHBNumbers,
        color: "blue",
        type: "horizontalGroupBelow",
        cn: "fill-blue-600",
      },
      {
        group: horizontalGroupTop,
        showFn: setShowHTNumbers,
        show: showHTNumbers,
        color: "blue",
        type: "horizontalGroupTop",
        cn: "fill-blue-600",
      },
      {
        group: horizontalGroup2,
        showFn: setShowH2Numbers,
        show: showH2Numbers,
        color: "blue",
        type: "horizontalGroup2",
        cn: "fill-blue-600",
      },
      {
        group: verticalGroup2,
        showFn: setShowV2Numbers,
        show: showV2Numbers,
        color: "red",
        type: "verticalGroup2",
        cn: "uprightText fill-red-600",
      },
    ],
    [
      verticalGroupLeft,
      verticalGroupRight,
      horizontalGroupBelow,
      horizontalGroupTop,
      horizontalGroup2,
      verticalGroup2,
      showH2Numbers,
      showHBNumbers,
      showHTNumbers,
      showV2Numbers,
      showVLNumbers,
      showVRNumbers,
    ]
  );

  const checkResult = (event) => {
    event.preventDefault();
    let gs = [...groupsStatus];
    gs.map((g) => {
      g.showStatus =
        g.type === currentGroup
          ? { status: true, result: parseFloat(xVal) === solution }
          : g.showStatus;
      return g;
    });
    setGroupsStatus(gs);

    setDisableSubmit(true);
    setShowSolution(true);
    const tmpSolved = solved;
    const tmpScore = score;
    tmpSolved[`level${challengeLevel + 1}`] =
      tmpSolved[`level${challengeLevel + 1}`] + 1;
    tmpSolved.overAll = tmpSolved.overAll + 1;
    setSolved(tmpSolved);
    if (parseFloat(xVal) === solution) {
      tmpScore[`level${challengeLevel + 1}`] =
        tmpScore[`level${challengeLevel + 1}`] + 1;
      tmpScore.overAll = score.overAll + 1;
      setScore(tmpScore);
      setResult("You Got it!");
    } else {
      setResult("Incorrect");
    }
    saveScores({ solved: tmpSolved, score: tmpScore, type: "polygon" });
  };

  const eq_sol = (group, numType, leftNumber) => {
    let eq = [];
    group.forEach((l) => {
      eq = [...eq, l.text[numType]];
      eq = eq.map((e) => (e === "x" ? 0 : e));
    });
    eq = eq.filter((e) => e !== leftNumber);
    eq.sort((a, b) => a - b);
    if (eq.length === group.length) {
      eq.shift();
    }
    eq = eq.map((e) => (e === 0 ? "x" : e));
    return eq;
  };

  const eq_sol_variant = (eq, displayLeft) => {
    const getExp = eq.reduce((p, c) => {
      if (c !== "x") {
        p[c] = (p[c] || 0) + 1;
      }
      return p;
    }, {});
    let exp, exp2;
    Object.keys(getExp)
      .filter((d) => d)
      .forEach((v) => {
        let rest = eq.filter((e) => e !== parseInt(v));
        if (getExp[v] > 1 && v === "2") {
          exp = (
            <div className="flex flex-row place-content-center">
              {displayLeft} = {rest.join(" + ")} +&nbsp;
              <p>
                {v}
                <sup>{getExp[v]}</sup>
              </p>
            </div>
          );
        }
        if (v !== 2 && getExp[v] > 1) {
          exp2 = (
            <div className="flex flex-row place-content-center">
              {displayLeft} = {rest.join(" + ")} {rest.length ? "+" : ""} ({v}
              <p className="text-sm max-[1024px]:mt-0 mt-1">*</p> 2)
            </div>
          );
        }
      });
    return [exp, exp2];
  };

  const createEquation = useCallback((group, leftNumber) => {
    let eq = eq_sol(group, "displayLineLength", leftNumber);
    let sol = eq_sol(group, "lineLength", leftNumber);

    let displayLeft = eq.indexOf("x") === -1 ? "x" : leftNumber;
    setEquation({
      problem: `${displayLeft} = ${eq.join(" + ")}`,
      solution: `${leftNumber} = ${sol.join(" + ")}`,
    });

    let [exp, exp2] = eq_sol_variant(eq, displayLeft);
    setEquationVariant({ problem: exp, solution: "" });
    setEquationVariant2({ problem: exp2, solution: "" });
    [exp, exp2] = eq_sol_variant(sol, leftNumber);
    setEquationVariant((equationVariant) => {
      return { ...equationVariant, solution: exp };
    });
    setEquationVariant2((equationVariant2) => {
      return { ...equationVariant2, solution: exp2 };
    });
  }, []);

  const unSelected = useCallback((unSelectGroup) => {
    setEquation("");
    setEquationVariant("");
    setEquationVariant2("");
    setShowSolution(false);
    setXVal("");
    setResult("");
    unSelectGroup.forEach((l) => {
      l.group.forEach((g) => {
        if (g.lineRef?.current) {
          g.lineRef.current.style.stroke = "black";
          if (g.line === "placeHolder") {
            g.lineRef.current.style.visibility = "hidden";
          }
        }
      });
      l.showFn(false);
    });
  }, []);

  const pickX = (lineRef, selectedGroups) => {
    let dt;
    const { group, color, type } = selectedGroups;
    dt = [...group].map((vg) => {
      vg.lineRef.current.style.stroke = color;
      if (vg.lineRef.current === lineRef) {
        setSolution(vg.text.lineLength);
        vg.text = { ...vg.text, ...{ displayLineLength: "x" } };
      } else {
        vg.text = { ...vg.text, displayLineLength: vg.text.lineLength };
      }
      return vg;
    });
    setDisplayText(dt);

    let gs = [...groupsStatus];

    if (!gs.filter((g) => g.type === type).length) {
      gs = [{ ...selectedGroups, show: true, group: [...dt] }, ...gs];
    }
    gs.map((g) => {
      g.show = g.type === type;
      g.group = g.type === type ? dt : g.group;
      return g;
    });

    setGroupsStatus(gs);

    let leftNumber = [
      "verticalGroupLeft",
      "verticalGroupRight",
      "verticalGroup2",
    ].filter((v) => v === type).length
      ? group[0].vertical
      : group[0].horizontal;
    createEquation(group, leftNumber);
  };

  const selected = ({ lineRef, type }) => {
    const group = [...groups].filter((g) => g.type === type);
    setCurrentGroup(type);
    setScreenChange(false);
    const unSelectGroup = groups.filter(
      (g) => g.type !== type && g.group.length
    );
    unSelected(unSelectGroup);
    group[0].group.forEach((g) => {
      if (g.line === "placeHolder") {
        if (g.lineRef.current) {
          g.lineRef.current.style.visibility = "visible";
        }
      }
    });
    group[0].showFn(true);
    pickX(lineRef, group[0]);
  };

  useEffect(() => setScreenChange(true), [maxWidth, zoomLevel]);

  useEffect(() => {
    let s = [];
    if (currentGroup && !result) {
      s = groupsStatus.filter((g) => g.type === currentGroup);
      if (s.length) setCurrentGroupStatus({ ...s[0].showStatus });
    }
  }, [currentGroup, groupsStatus, result]);

  useEffect(() => {
    const gr = [...groups].filter((g) => g.show);
    const activeGroup = [...groupsStatus].filter((g) => g.type === gr[0]?.type);
    if (screenChange && gr.length) {
      const group = [...gr[0].group];
      group.map(
        (a) =>
          (a.text.displayLineLength = activeGroup[0].group.filter(
            (b) => a.lineRef.current === b.lineRef.current
          )[0].text.displayLineLength)
      );
      setDisplayText([...group]);
    }
  }, [groups, screenChange, groupsStatus]);

  useEffect(() => {
    setDisableSubmit(xVal ? false : true);
  }, [xVal]);

  useEffect(() => {
    setScorePct(({ currentScore }) => {
      return {
        previousScore: currentScore,
        currentScore: `${
          solved.overAll && Math.round((score.overAll * 100) / solved.overAll)
        }%`,
      };
    });
  }, [solved.overAll, score.overAll]);

  useEffect(() => setGroupsStatus([]), [setGroupsStatus]);

  useEffect(
    () =>
      svgRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      }),
    [groups]
  );

  useEffect(() => {
    if (equation.problem && xRef?.current) {
      xRef.current.focus();
      xRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [equation.problem]);

  useEffect(() => {
    if (groupsStatus.length) {
      groups.forEach((gr) => {
        if (
          groupsStatus.filter(
            (gs) => gs.type === gr.type && gs?.showStatus?.status
          ).length
        ) {
          gr.group.forEach((g) => (g.lineRef.current.style.stroke = "#9333ea"));
        }
      });

      setChallengeSolved(
        groups
          .filter((g) => g.group.length)
          .every(
            (gr) =>
              groupsStatus.filter(
                (gs) => gs.type === gr.type && gs?.showStatus?.status
              ).length
          )
      );
    }
  }, [groupsStatus, groups, setChallengeSolved]);

  return [
    groups,
    equation,
    equationVariant,
    equationVariant2,
    checkResult,
    showSolution,
    result,
    scorePct,
    xVal,
    setXVal,
    disableSubmit,
    currentGroupStatus,
    selected,
    svgRef,
    xRef,
  ];
};

export default useGetEquations;
