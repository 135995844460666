import { Transition } from "@headlessui/react";
import Fractions from "../../Fractions/Fractions";

const ResultTable = ({
  rowIndex,
  showSolution,
  dropped,
  slideComplete,
  boxWidth,
  blockHeight,
}) => (
  <Transition
    show={
      (rowIndex > 0 || showSolution) &&
      dropped[rowIndex].length > 0 &&
      dropped[rowIndex]?.rowLocked
    }
    enter="transition-opacity duration-1000 ease-out"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    className="flex flex-row place-content-start"
    afterLeave={slideComplete}
    style={{
      width: boxWidth,
      height: blockHeight,
      background: dropped[rowIndex]?.blockColors,
    }}
  >
    <div
      draggable={false}
      className={`self-start pointer-events-none text-sm max-[1024px]:text-[12px] text-black font-bold max-[1024px]:border-solid max-[1024px]:border ${
        dropped[rowIndex]?.rowLocked
          ? "border-solid border-2"
          : "border-double border-8"
      } flex place-content-center border-black`}
    >
      <div className="w-full self-center flex flex-row place-content-center">
        {dropped[rowIndex].length > 0 &&
          dropped[rowIndex].duplicates.map(({ f, count }, index3) => (
            <div
              key={index3}
              className="flex text-sm max-[1024px]:text-[12px] flex-row place-content-center w-fit"
            >
              {f > 1 ? (
                <>
                  <div className="font-black self-center max-[640px]:hidden">
                    (
                  </div>
                  {count > 1 && (
                    <div className="self-center p-1 max-[1024px]:p-0">
                      {count}
                    </div>
                  )}
                  {count > 1 && (
                    <div className="font-black self-center pt-1">*</div>
                  )}

                  <div className="self-center p-1 max-[1024px]:p-0">
                    <Fractions
                      small={false}
                      numerator={1}
                      denominator={f}
                      dividerColor={"border-black self-center"}
                    />
                  </div>
                  <div className="font-black self-center max-[640px]:hidden">
                    )
                  </div>

                  {index3 < dropped[rowIndex].duplicates.length - 1 ? (
                    <div className="font-black self-center p-1 max-[1024px]:p-0">
                      +
                    </div>
                  ) : (
                    <div className="self-center flex flex-row place-content-center ">
                      <p className="self-center">&nbsp;=</p>
                      <div className="max-[1024px]:text-sm text-2xl self-center flex flex-row place-content-center">
                        &nbsp;{dropped[rowIndex].rightSide}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <p className="self-center text-3xl max-[1024px]:text-xl text-center">
                  1
                </p>
              )}
            </div>
          ))}
      </div>
    </div>
  </Transition>
);
export default ResultTable;
