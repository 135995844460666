import { useGetStudentsData } from "../../hooks";
import MessageModal from "../MessageModal/MessageModal";
import DateFilter from "./DateFilter";
import DataTable from "./DataTable";
import TableTop from "./TableTop";
import Skeleton from "../Loader/Skeleton";

const StudentsData = ({ appCheckToken, authData }) => {
  const [
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    scores,
    setScores,
    clear,
    noScores,
    endDateError,
    setEndDateError,
    gameLabels,
    toggleUser,
    collapse,
    setCollapse,
    showPercent,
    setShowPercent,
    showLoader,
  ] = useGetStudentsData({
    appCheckToken,
    authData,
  });
  return (
    <div className="w-screen absolute top-24 right-0 left-0 max-[1024px]:text-base max-[640px]:text-sm max-[320px]:text-xs">
      <div className="absolute top-auto right-0 left-0 flex flex-wrap place-content-center p-1">
        <DateFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setScores={setScores}
          clear={clear}
        />

        {showLoader ? (
          <Skeleton
            times={10}
            cn={"border border-black w-screen h-16 m-2 bg-gray-200"}
            transitionColor={"from-gray-200 via-cyan-200 to-gray-200"}
          />
        ) : (
          <>
            {noScores ? (
              <p className="mt-10 p-5 bg-black rounded-full font-bold text-orange-300">
                No games played.
              </p>
            ) : (
              !noScores && (
                <TableTop
                  startDate={startDate}
                  endDate={endDate}
                  collapse={collapse}
                  setCollapse={setCollapse}
                  showPercent={showPercent}
                  setShowPercent={setShowPercent}
                />
              )
            )}
            <DataTable
              scores={scores}
              gameLabels={gameLabels}
              toggleUser={toggleUser}
              showPercent={showPercent}
            />
          </>
        )}
      </div>
      <MessageModal
        openModal={endDateError}
        closeModal={() => setEndDateError(false)}
        message={{
          message: (
            <div className="flex flex-wrap place-content-center">
              <div className="self-center text-center">
                End date cannot be less than start date.
              </div>
            </div>
          ),
          bgColor: "bg-yellow-600",
        }}
      />
    </div>
  );
};
export default StudentsData;
