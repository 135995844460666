import React from "react";
import ShowItemsCarriers from "../ShowItemsCarriers/ShowItemsCarriers";
import AlienTruck from "../AlienTruck/AlienTruck";
import PetTransport from "../PetTransport/PetTransport";
import "../style.css";

const PickItemCarrier = ({
  selected,
  confirmSelection,
  item,
  setItem,
  carrier,
  setCarrier,
  itemList,
  setItemList,
  carrierList,
  setCarrierList,
  challengeLevel,
  move,
  setMove,
  transportRef,
  showItems,
  setShowTable,
}) => (
  <div
    className={`${
      selected ? "disappear" : "appear"
    } flex flex-col place-content-center`}
  >
    {challengeLevel < 2 ? (
      <AlienTruck
        item={item}
        carrier={carrier}
        confirmSelection={confirmSelection}
        move={move}
        ref={transportRef}
        setMove={setMove}
        selected={selected}
      />
    ) : (
      <PetTransport
        item={item}
        carrier={carrier}
        confirmSelection={confirmSelection}
        move={move}
        ref={transportRef}
        setMove={setMove}
        selected={selected}
      />
    )}
    <ShowItemsCarriers
      type={challengeLevel < 2 ? "fruit" : "food"}
      list={itemList}
      setList={setItemList}
      setListType={setItem}
      itemType={item}
    />
    <ShowItemsCarriers
      type={challengeLevel < 2 ? "carrier" : "pet"}
      list={carrierList}
      setList={setCarrierList}
      setListType={setCarrier}
      itemType={carrier}
    />
  </div>
);
export default PickItemCarrier;
