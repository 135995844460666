import { useState } from "react";
import ScoreCard from "../../ScoreCard/ScoreCard";
import LineNumberInputs from "../LineNumberInputs/LineNumberInputs";
import LineNumberButtons from "../LineNumberButtons/LineNumberButtons";

const LineNumberEquation = ({
  equation,
  showSolution,
  result,
  qVal,
  setQVal,
  disableSubmit,
  checkQVal,
  dividendClick,
  divisorClick,
  showDividendLines,
  showDivisorLines,
  challengeLevel,
  scorePct,
  solved,
  score,
  linesDrawn,
}) => {
  const [scoreChange, setScoreChange] = useState(false);

  return (
    <div className="flex flex-row place-content-center p-5">
      <div className="flex flex-wrap place-content-evenly text-blue-900 self-center border-2 border-black font-bold text-black text-base p-2 bg-lime-100 text-center w-full max-[1024px]:text-xs">
        {equation?.problem && (
          <div className="self-center flex flex-wrap place-content-evenly w-full">
            <div className="self-center flex flex-col">
              <LineNumberButtons
                linesDrawn={linesDrawn}
                dividendClick={dividendClick}
                divisorClick={divisorClick}
                showDividendLines={showDividendLines}
                showDivisorLines={showDivisorLines}
                equation={equation}
              />
              {equation?.problem && (
                <LineNumberInputs
                  challengeLevel={challengeLevel}
                  showDividendLines={showDividendLines}
                  showDivisorLines={showDivisorLines}
                  equation={equation}
                  setQVal={setQVal}
                  showSolution={showSolution}
                  result={result}
                  qVal={qVal}
                  disableSubmit={disableSubmit}
                  setScoreChange={setScoreChange}
                  checkQVal={checkQVal}
                />
              )}
            </div>
            <ScoreCard
              type="NumberLine"
              showSolution={showSolution}
              result={result}
              equation={equation}
              scorePct={scorePct}
              score={score}
              solved={solved}
              setScoreChange={setScoreChange}
              scoreChange={scoreChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default LineNumberEquation;
