import { GiBrightExplosion } from "react-icons/gi";

const BlockDrop = ({ blockFail, coords, plopRef, mute }) =>
  blockFail &&
  coords.left &&
  coords.top && (
    <div style={{ left: coords.left, top: coords.top }} className="absolute">
      <GiBrightExplosion className="font-bold text-3xl text-black animate-ping" />
      <audio ref={plopRef} autoFocus muted={mute}>
        <source src="/squish.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );

export default BlockDrop;
