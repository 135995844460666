import React, { useState } from "react";
import { useGetDestroy } from "../../hooks";
import GameTabs from "./GameTabs";
import GameChange from "./GameChange";
import BlockDrop from "./BlockDrop";

const MainPage = ({
  score,
  setScore,
  solved,
  setSolved,
  challengeLevel,
  setChallengeLevel,
  showTabs,
  setShowTabs,
  tabSelect,
  setTabSelect,
  games,
  setShowSignin,
  authData,
  authChecked,
  setLogoutConfirm,
  saveScores,
}) => {
  const [
    blockFail,
    setBlockFail,
    coords,
    dragStart,
    drop,
    plopRef,
    fractionBarRef,
    setCoords,
    touchEleCoords,
    setTouchEleCoords,
    mute,
    setMute,
  ] = useGetDestroy();

  const [gameChangeConfirm, setGameChangeConfirm] = useState(false);
  const [tabClicked, setTabClicked] = useState("");
  return (
    <div
      className={`w-screen absolute ${
        showTabs ? "top-24" : "top-0"
      } right-0 left-0`}
      onDragStart={dragStart}
      onDragOver={(event) => event.preventDefault()}
      onDrop={drop}
      onTouchStart={drop}
    >
      <GameTabs
        score={score}
        setScore={setScore}
        solved={solved}
        setSolved={setSolved}
        challengeLevel={challengeLevel}
        setChallengeLevel={setChallengeLevel}
        showTabs={showTabs}
        tabSelect={tabSelect}
        setTabClicked={setTabClicked}
        setGameChangeConfirm={setGameChangeConfirm}
        setBlockFail={setBlockFail}
        fractionBarRef={fractionBarRef}
        setCoords={setCoords}
        touchEleCoords={touchEleCoords}
        setTouchEleCoords={setTouchEleCoords}
        setMute={setMute}
        games={games}
        setShowTabs={setShowTabs}
        mute={mute}
        setShowSignin={setShowSignin}
        authData={authData}
        authChecked={authChecked}
        setLogoutConfirm={setLogoutConfirm}
        saveScores={saveScores}
      />
      <BlockDrop
        blockFail={blockFail}
        coords={coords}
        plopRef={plopRef}
        mute={mute}
      />
      <GameChange
        gameChangeConfirm={gameChangeConfirm}
        setGameChangeConfirm={setGameChangeConfirm}
        setTabSelect={setTabSelect}
        tabClicked={tabClicked}
        games={games}
      />
    </div>
  );
};

export default MainPage;
