import { Fragment } from "react";
import { Transition } from "@headlessui/react";

const ScoreBoard = ({
  scoreChange,
  scorePct,
  previousShowing,
  score,
  solved,
  newShowing,
}) => (
  <div className="self-start text-center flex flex-row max-[1024px]:flex-col max-[1024px]:mt-1 max-[1024px]:w-full place-content-center">
    <div className="border-2 border-black self-center shadow-2xl rounded-full h-24 w-24 max-[1024px]:h-16 max-[1024px]:w-16 bg-cyan-200 p-2">
      Score:
      <div className="h-full ">
        {!scoreChange && <div>{scorePct.currentScore}</div>}
        {scoreChange && (
          <Transition
            as={Fragment}
            show={previousShowing}
            leave="transform transition duration-[400ms]"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <div className={`${!previousShowing && "opacity-25"}`}>
              {scorePct.previousScore}
            </div>
          </Transition>
        )}
        {scoreChange && !previousShowing && (
          <Transition
            as={Fragment}
            show={newShowing}
            enter="transform transition duration-[400ms]"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
          >
            <div>{scorePct.currentScore}</div>
          </Transition>
        )}
      </div>
    </div>
    <div className="self-center ml-2 text-xs max-[1024px]:ml-0 max-[1024px]:text-[10px]">
      <div>Correct # {score.overAll}</div>
      <div className="self-center border border-black w-35 h-0" />
      <div>Solved # {solved.overAll}</div>
    </div>
  </div>
);

export default ScoreBoard;
