import React from "react";
import VideoExplanation from "../VideoExplanation/VideoExplanation";

const SignUpAndVideoLinks = ({
  vid = "",
  showInstructions = false,
  setShowInstructions = () => {},
  setShowSignin,
  authData,
  authChecked,
  setLogoutConfirm,
}) => {
  return (
    <div
      className={`self-center flex flex-wrap place-content-evenly mb-1 w-1/2 max-[1024px]:w-11/12`}
    >
      {authChecked && !authData && (
        <button
          className="flex flex-row place-content-center p-1 self-center font-bold outline-none text-blue-600 focus:text-blue-900 hover:text-blue-900 focus:underline hover:underline"
          onClick={() => setShowSignin(true)}
        >
          <p className="self-center">Sign Up/In&nbsp;</p>
          <p className="self-center text-red-400">
            (scores will not be saved if not signed in)
          </p>
        </button>
      )}
      {authData && (
        <div className="p-1 overflow-scroll max-[1024px]:text-xs text-base max-[640px]:w-full self-center flex flex-col place-content-center">
          <div className="self-center text-center text-blue-900">
            Logged in with email "{authData.email}"
          </div>
          <button
            onClick={() => setLogoutConfirm(true)}
            className="self-center font-bold text-red-400 outline-none focus:underline hover:underline focus:text-red-600 hover:text-red-600"
          >
            Not you? Logout
          </button>
        </div>
      )}
      {vid && (
        <div className={`self-center ${showInstructions && "bg-black"}`}>
          <VideoExplanation
            vid={vid}
            showInstructions={showInstructions}
            setShowInstructions={setShowInstructions}
          />
        </div>
      )}
    </div>
  );
};

export default SignUpAndVideoLinks;
