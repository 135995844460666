import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import ItemsImageCount from "./ItemsImageCount";

const ColumnData3 = ({
  columnValue,
  estimate,
  items,
  carriers,
  status,
  rowIndex,
  carrier,
  item,
  challengeLevel,
  expand,
}) => (
  <td className="text-center font-bold flex flex-wrap p-2 ">
    {status === "Incorrect" &&
      columnValue.index === rowIndex &&
      columnValue.val && (
        <div className="bg-red-600 text-white flex flex-wrap place-content-center p-2">
          {estimate}
        </div>
      )}
    <Transition
      as={Fragment}
      show={expand}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex flex-wrap place-content-start">
        {[...new Array(carriers)].map((i, index) => (
          <div
            key={index}
            className={`p-2 flex flex-col place-content-center self-center border-b border-b-black
${index % 2 === 0 ? "bg-green-300" : "bg-green-200"}`}
          >
            <div className="self-center">{carrier?.iconSmall}</div>
            <div className="self-center flex flex-wrap place-content-center">
              {[...new Array(Math.ceil(items / carriers))].map((i, index2) => (
                <ItemsImageCount
                  key={index2}
                  challengeLevel={challengeLevel}
                  item={item}
                  items={items}
                  carriers={carriers}
                  index={index}
                  index2={index2}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Transition>
  </td>
);
export default ColumnData3;
