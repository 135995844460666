import { forwardRef } from "react";
import ItemCarrierLN from "../ItemCarrierLN/ItemCarrierLN";

const KLineNumber = forwardRef(
  ({ maxWidth, itemCarriers, item, carrier }, ref) => (
    <div className="flex flex-col place-content-center p-5">
      <svg
        viewBox={`0 0 ${maxWidth} ${300}`}
        xmlns="http://www.w3.org/2000/svg"
        className="self-start bg-cyan-200 border-black border w-full"
        ref={ref}
      >
        <ItemCarrierLN
          pathPointsH={`20,100 ${maxWidth - 20},100`}
          pathPointsV={`20,50 20,100`}
          textPoints={[20, 120]}
          label={item}
          itemCarriers={itemCarriers}
          maxWidth={maxWidth}
          vPoints={[50, 100]}
          type={"items"}
        />
        <ItemCarrierLN
          pathPointsH={`20,250 ${maxWidth - 20},250`}
          pathPointsV={`20,200 20,250`}
          textPoints={[20, 270]}
          label={carrier}
          itemCarriers={itemCarriers}
          maxWidth={maxWidth}
          vPoints={[200, 250]}
          type={"carriers"}
        />
      </svg>
    </div>
  )
);
export default KLineNumber;
