import { useState, useEffect } from "react";
const keys = require("../keys.js");

const useGetApod = () => {
  const [apod, setApod] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const getApod = async () => {
      try {
        const response = await fetch(
          `https://api.nasa.gov/planetary/apod?api_key=${keys.apod.apiKey}`
        );
        const { title, url, explanation, media_type } = await response.json();
        setApod({ title, url, explanation, media_type });
      } catch (error) {
        setApod("");
      }
    };

    getApod();
    const intervalID = setInterval(() => {
      const newDate = new Date();
      if (
        !(
          currentDate.getDate() === newDate.getDate() &&
          currentDate.getMonth() === newDate.getMonth() &&
          currentDate.getFullYear() === newDate.getFullYear()
        )
      ) {
        if (new Date().getUTCHours() > 5) {
          setCurrentDate(new Date());
          getApod();
        }
      }
    }, 1000);
    return () => clearInterval(intervalID);
  }, [currentDate]);

  return [apod];
};
export default useGetApod;
