import { Transition } from "@headlessui/react";

const LevelButtons = ({
  setLevelChangeConfirm,
  setLevelChange,
  linesDrawn,
  challengeLevel,
  hideLevels,
  showTabs,
  levels = [0, 1, 2],
}) => (
  <div className="border-t border-b border-white bg-[#45B8AC]">
    <div className="h-16 flex flex-row place-content-center relative">
      <Transition
        show={!hideLevels}
        enter="transition-opacity delay-[4000ms] duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute top-0 w-full h-full flex place-content-center"
      >
        <div className="self-center">
          {levels.map((c) => (
            <button
              key={c}
              className={`max-[1024px]:text-xs self-center rounded-xl ${
                showTabs ? "mt-2" : "mt-6"
              }  mb-2 ml-2 mr-2 max-[1024px]:p-1 p-2 font-black ${
                c === challengeLevel ? "bg-[#5df5e6]" : "bg-green-50"
              } ${
                linesDrawn &&
                c !== challengeLevel &&
                "outline-none focus:bg-[#2f7d76] hover:bg-[#2f7d76] focus:text-white hover:text-white"
              } `}
              disabled={c === challengeLevel || !linesDrawn}
              onClick={() => {
                setLevelChange(c);
                setLevelChangeConfirm(true);
              }}
            >
              Level {c + 1}
            </button>
          ))}
        </div>
      </Transition>
      <Transition
        show={hideLevels || false}
        enter="ease-out transition-opacity delay-500 duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute top-0 w-full h-full flex place-content-center"
      >
        <div className="self-center">
          <p className="self-center text-base font-bold max-[1024px]:text-xs text-white">
            Now Playing Level {challengeLevel + 1}
          </p>
        </div>
      </Transition>
    </div>
  </div>
);

export default LevelButtons;
