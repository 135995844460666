import { useState } from "react";
import { useLevelScores, useGetApod, useGetAuth } from "../../hooks";
import Header from "../Header/Header";
import MainPage from "../MainPage/MainPage";
import LandingPage from "../LandingPage/LandingPage";
import SigninModal from "../SignUpAndVideoLinks/SigninModal";
import Spinner from "../Loader/Spinner";
import Flight from "../Loader/Flight";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import MessageModal from "../MessageModal/MessageModal";
import StudentsData from "../StudentsData/StudentsData";
import AdminView from "../AdminView/AdminView";
import DetailsView from "../DetailsView/DetailsView";

const PageLayout = () => {
  const [
    showSignin,
    setShowSignin,
    email,
    setEmail,
    getAuth,
    verifyUser,
    signInResult,
    setSignInResult,
    message,
    showSpinner,
    setShowSpinner,
    showFlight,
    emailOTPSuccess,
    setEmailOTPSuccess,
    code,
    setCode,
    authData,
    authChecked,
    logout,
    logoutConfirm,
    setLogoutConfirm,
    appCheckToken,
    loggedOut,
    showSchoolReg,
    setShowSchoolReg,
    showAdminView,
    setShowAdminView,
  ] = useGetAuth();
  const [
    score,
    setScore,
    solved,
    setSolved,
    challengeLevel,
    setChallengeLevel,
    showTabs,
    setShowTabs,
    tabSelect,
    setTabSelect,
    buttonRef,
    currentTab,
    games,
    saveScores,
    saveDataFail,
    setSaveDataFail,
    showStudentsData,
    setShowStudentsData,
  ] = useLevelScores({ authData, appCheckToken, loggedOut });

  const authProps = {
    showSignin,
    setShowSignin,
    email,
    setEmail,
    getAuth,
    verifyUser,
    signInResult,
    setSignInResult,
    message,
    emailOTPSuccess,
    setEmailOTPSuccess,
    code,
    setCode,
  };
  const scoreProps = {
    score,
    setScore,
    solved,
    setSolved,
    challengeLevel,
    setChallengeLevel,
    showTabs,
    setShowTabs,
    tabSelect,
    setTabSelect,
    games,
    saveScores,
  };
  const [apod] = useGetApod();

  const [showTeacherDetails, setShowTeacherDetails] = useState(false);
  const [showStudentDetails, setShowStudentDetails] = useState(false);

  return (
    <>
      <div className="relative">
        {((tabSelect !== null && showTabs) ||
          showStudentsData ||
          showAdminView) && (
          <Header
            setTabSelect={setTabSelect}
            setShowStudentsData={setShowStudentsData}
            setShowAdminView={setShowAdminView}
          />
        )}
        {tabSelect !== null ? (
          <MainPage
            {...scoreProps}
            setShowSignin={setShowSignin}
            authData={authData}
            authChecked={authChecked}
            setLogoutConfirm={setLogoutConfirm}
          />
        ) : (
          authChecked &&
          (showStudentsData ? (
            <StudentsData appCheckToken={appCheckToken} authData={authData} />
          ) : showAdminView ? (
            <AdminView appCheckToken={appCheckToken} authData={authData} />
          ) : (
            <LandingPage
              setTabSelect={setTabSelect}
              currentTab={currentTab}
              ref={buttonRef}
              apod={apod}
              setShowSignin={setShowSignin}
              authData={authData}
              authChecked={authChecked}
              setLogoutConfirm={setLogoutConfirm}
              setShowStudentsData={setShowStudentsData}
              appCheckToken={appCheckToken}
              setShowSpinner={setShowSpinner}
              showSchoolReg={showSchoolReg}
              setShowSchoolReg={setShowSchoolReg}
              showAdminView={showAdminView}
              setShowAdminView={setShowAdminView}
              setShowTeacherDetails={setShowTeacherDetails}
              setShowStudentDetails={setShowStudentDetails}
            />
          ))
        )}
      </div>
      {showSpinner && <Spinner />}
      {showFlight && (
        <div className="absolute top-0 w-screen h-screen z-50 flex place-content-center">
          <Flight
            times={1}
            cn={"w-full h-full self-center bg-sky-200"}
            transitionColor={"from-[#DFCFBE] via-[#537263] to-[#DFCFBE]"}
          />
        </div>
      )}
      {logoutConfirm && (
        <ConfirmModal
          setChangeConfirm={setLogoutConfirm}
          restartFn={() => {
            logout();
            setLogoutConfirm(false);
          }}
          confirmMessage={`Scores will reset for all games and will not be saved once Logged out, Confirm logout? `}
        />
      )}
      <MessageModal
        openModal={saveDataFail}
        closeModal={() => setSaveDataFail(false)}
        message={{
          message: (
            <div className="flex flex-wrap place-content-center">
              <div className="self-center text-center">
                Unable to save score at this time!
              </div>
            </div>
          ),
          bgColor: "bg-orange-800",
        }}
      />
      <SigninModal {...authProps} />
      <DetailsView
        showTeacherDetails={showTeacherDetails}
        setShowTeacherDetails={setShowTeacherDetails}
        showStudentDetails={showStudentDetails}
        setShowStudentDetails={setShowStudentDetails}
      />
    </>
  );
};
export default PageLayout;
