const gameLabels = [
  {
    label: "Polygon",
    keyName: "polygon",
    bgColor: "bg-blue-200",
    bgLevelColor: "bg-blue-200",
    bgScoreColor: "bg-blue-200",
  },
  {
    label: "Number Line",
    keyName: "numberLine",
    bgColor: "bg-rose-200",
    bgLevelColor: "bg-rose-200",
    bgScoreColor: "bg-rose-200",
  },
  {
    label: "K Constant",
    keyName: "kConstant",
    bgColor: "bg-green-200",
    bgLevelColor: "bg-green-200",
    bgScoreColor: "bg-green-200",
  },
  {
    label: "Angles",
    keyName: "angles",
    bgColor: "bg-orange-200",
    bgLevelColor: "bg-orange-200",
    bgScoreColor: "bg-orange-200",
  },
  {
    label: "Fraction Bars",
    keyName: "fractionBars",
    bgColor: "bg-violet-200",
    bgLevelColor: "bg-violet-200",
    bgScoreColor: "bg-violet-200",
  },
  {
    label: "Greatest Commn Factor",
    keyName: "greatestCommonFactor",
    bgColor: "bg-yellow-200",
    bgLevelColor: "bg-yellow-200",
    bgScoreColor: "bg-yellow-200",
  },
];
export default gameLabels;
