import { useEffect, useState } from "react";
import { GiPartyPopper } from "react-icons/gi";
import partyBagItems from "../../../icons/partyBagItems";
import ItemButtons from "./ItemButtons";
import PartyBags from "../PartyBags/PartyBags";

const PickItems = ({
  partyItems,
  setPartyItems,
  showSolution,
  solution,
  firstNumber,
  secondNumber,
}) => {
  const [list, setList] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let temp = [...partyBagItems];
    if (items.length > 0) {
      items.forEach((p) => (temp = [...temp].filter((l) => l !== p)));
    }
    setList([...temp]);
  }, [items]);

  useEffect(() => {
    if (showSolution) {
      setItems([...partyItems]);
    }
  }, [showSolution, partyItems]);

  return (
    <div
      className={`appear relative self-center bg-blue-100 border-8 border-double border-black rounded-lg w-full`}
    >
      <GiPartyPopper className="animate-pulse absolute top-0 left-0 h-16 w-16 text-red-600 rotate-90 z-10" />
      <GiPartyPopper className="animate-pulse absolute top-0 right-0 h-16 w-16 text-red-600 rotate-180 z-10" />
      <GiPartyPopper className="animate-pulse absolute bottom-0 left-0 h-16 w-16 text-red-600 z-10" />
      <GiPartyPopper className="animate-pulse absolute bottom-0 right-0 h-16 w-16 text-red-600 -rotate-90 z-10" />
      <div className="flex flex-col place-content-center p-5 max-[320px]:p-0">
        <div className="w-full party-wallpaper self-center flex flex-wrap place-content-evenly">
          <div className="self-center flex flex-col place-content-center">
            {showSolution ? (
              <p className="self-center font-bold text-blue-800">
                # of Party Bags {solution}
              </p>
            ) : (
              <p className="self-center font-bold text-blue-800">
                Pick two items you would like in your party bag
              </p>
            )}
            {items.length > 0 && (
              <PartyBags
                firstNumber={firstNumber}
                secondNumber={secondNumber}
                solution={showSolution ? solution : 1}
                items={items}
                coords={[
                  { x: -75, y: 60 },
                  { x: -20, y: 60 },
                ]}
              />
            )}
            {items.length === 2 && !showSolution && (
              <button
                onClick={() => setPartyItems([...items])}
                className="text-blue-600 hover:text-green-500 focus:text-green-500"
              >
                Confirm
              </button>
            )}
          </div>
          {!showSolution && <ItemButtons list={list} setItems={setItems} />}
        </div>
      </div>
    </div>
  );
};

export default PickItems;
