import ConfirmModal from "../ConfirmModal/ConfirmModal";
const GameChange = ({
  gameChangeConfirm,
  setGameChangeConfirm,
  setTabSelect,
  tabClicked,
  games,
}) =>
  gameChangeConfirm && (
    <ConfirmModal
      setChangeConfirm={setGameChangeConfirm}
      restartFn={() => {
        setTabSelect(tabClicked);
        setGameChangeConfirm(false);
      }}
      confirmMessage={
        <div className="flex flex-row place-content-center">
          <div className="self-center text-black">Jump to game&nbsp;</div>
          <div className="self-center italic">"{games[tabClicked]}"? </div>
        </div>
      }
    />
  );
export default GameChange;
