import { useEffect, useState, createRef, useCallback } from "react";

const useLinesH = ({
  maxWidth,
  maxHeight,
  level: { max, min, short },
  width,
}) => {
  const [polyColorPoints, setPolyColorPoints] = useState([]);
  const [linePoints, setLinePoints] = useState([]);
  const [vgLeft, setVgLeft] = useState([]);
  const [hgTop, setHgTop] = useState([]);
  const [hgBelow, setHgBelow] = useState([]);
  const [hg2] = useState([]);
  const [vgRight, setVgRight] = useState([]);
  const [vg2] = useState([]);
  const [lLength, setLLength] = useState({
    horizontal: null,
    vertical: null,
    l1: null,
    l2: null,
    l3: null,
    l4: null,
    l5: null,
    l6: null,
    l7: null,
    l8: null,
    l9: null,
    l10: null,
    l11: null,
    l12: null,
    l13: null,
    l14: null,
  });

  const [lineRefs, setLineRefs] = useState([...new Array(16)]);

  useEffect(() => setLineRefs((l) => l.map(() => createRef())), []);

  const getLineLengths = useCallback(() => {
    let horizontal,
      vertical,
      l1,
      l2,
      l3,
      l4,
      l5,
      l6,
      l7,
      l8,
      l9,
      l10,
      l11,
      l12,
      l13,
      l14;
    horizontal = Math.floor(
      Math.random() * (max - (min + short) + 1) + (min + short)
    );
    vertical = Math.floor(
      Math.random() * (max - (min + short) + 1) + (min + short)
    );
    l1 = vertical;
    l2 = Math.floor(Math.random() * (horizontal - min));
    l2 = l2 < short ? short : l2;
    l3 = Math.floor(Math.random() * (vertical - min));
    l3 = l3 < short ? short : l3;
    l13 = Math.floor(Math.random() * (vertical - l3 - short));
    l13 = l13 < short ? short : l13;
    l14 = l13;
    l4 = Math.floor(Math.random() * (horizontal - l2 - short));
    l4 = l4 < short ? short : l4;
    l5 = l3;
    l6 = horizontal - (l2 + l4);
    l7 = l1;
    l8 = Math.floor(Math.random() * (horizontal - min));
    l8 = l8 < short ? short : l8;
    l10 = Math.floor(Math.random() * (horizontal - l8 - short));
    l10 = l10 < short ? short : l10;
    l11 = vertical - (l3 + l13);
    l9 = l11;
    l12 = horizontal - (l8 + l10);
    return {
      horizontal,
      vertical,
      l1,
      l2,
      l3,
      l4,
      l5,
      l6,
      l7,
      l8,
      l9,
      l10,
      l11,
      l12,
      l13,
      l14,
    };
  }, [max, min, short]);

  useEffect(() => {
    const {
      horizontal,
      vertical,
      l1,
      l2,
      l3,
      l4,
      l5,
      l6,
      l7,
      l8,
      l9,
      l10,
      l11,
      l12,
      l13,
      l14,
    } = getLineLengths();
    setLLength({
      horizontal,
      vertical,
      l1,
      l2,
      l3,
      l4,
      l5,
      l6,
      l7,
      l8,
      l9,
      l10,
      l11,
      l12,
      l13,
      l14,
    });
  }, [getLineLengths]);

  useEffect(() => {
    if (maxWidth && maxHeight && lLength.horizontal) {
      const {
        horizontal,
        vertical,
        l1,
        l2,
        l3,
        l4,
        l5,
        l6,
        l7,
        l8,
        l9,
        l10,
        l11,
        l12,
        l13,
        l14,
      } = lLength;
      const polyBorderStart = 50;
      const verticalPoints = maxHeight - 100;
      const horizontalPoints = maxWidth - 100;
      const points = [
        {
          ref: lineRefs[0],
          type: "verticalGroupLeft",
          x1: `${polyBorderStart}`,
          y1: `${polyBorderStart}`,
          x2: `${polyBorderStart}`,
          y2: `${verticalPoints + polyBorderStart}`,
        },
        {
          ref: lineRefs[1],
          type: "horizontalGroupBelow",
          x1: `${polyBorderStart}`,
          y1: `${verticalPoints + polyBorderStart}`,
          x2: `${(horizontalPoints * l2) / horizontal + polyBorderStart}`,
          y2: `${verticalPoints + polyBorderStart}`,
        },
        {
          ref: lineRefs[2],
          type: "verticalGroupLeft",
          x1: `${(horizontalPoints * l2) / horizontal + polyBorderStart}`,
          y1: `${verticalPoints + polyBorderStart}`,
          x2: `${(horizontalPoints * l2) / horizontal + polyBorderStart}`,
          y2: `${((l11 + l13) * verticalPoints) / vertical + polyBorderStart}`,
        },
        {
          ref: lineRefs[3],
          type: "horizontalGroupBelow",
          x1: `${(horizontalPoints * l2) / horizontal + polyBorderStart}`,
          y1: `${((l11 + l13) * verticalPoints) / vertical + polyBorderStart}`,
          x2: `${
            (horizontalPoints * (l2 + l4)) / horizontal + polyBorderStart
          }`,
          y2: `${((l11 + l13) * verticalPoints) / vertical + polyBorderStart}`,
        },
        {
          ref: lineRefs[4],
          type: "verticalGroupRight",
          x1: `${
            (horizontalPoints * (l2 + l4)) / horizontal + polyBorderStart
          }`,
          y1: `${((l9 + l14) * verticalPoints) / vertical + polyBorderStart}`,
          x2: `${
            (horizontalPoints * (l2 + l4)) / horizontal + polyBorderStart
          }`,
          y2: `${verticalPoints + polyBorderStart}`,
        },
        {
          ref: lineRefs[5],
          type: "horizontalGroupBelow",
          x1: `${
            (horizontalPoints * (l2 + l4)) / horizontal + polyBorderStart
          }`,
          y1: `${verticalPoints + polyBorderStart}`,
          x2: `${horizontalPoints + polyBorderStart}`,
          y2: `${verticalPoints + polyBorderStart}`,
        },
        {
          ref: lineRefs[6],
          type: "verticalGroupRight",
          x1: `${horizontalPoints + polyBorderStart}`,
          y1: `${verticalPoints + polyBorderStart}`,
          x2: `${horizontalPoints + polyBorderStart}`,
          y2: `${polyBorderStart}`,
        },
        {
          ref: lineRefs[7],
          type: "horizontalGroupTop",
          x1: `${horizontalPoints + polyBorderStart}`,
          y1: `${polyBorderStart}`,
          x2: `${
            (horizontalPoints * (l12 + l10)) / horizontal + polyBorderStart
          }`,
          y2: `${polyBorderStart}`,
        },
        {
          ref: lineRefs[8],
          type: "verticalGroupRight",
          x1: `${
            (horizontalPoints * (l12 + l10)) / horizontal + polyBorderStart
          }`,
          y1: `${polyBorderStart}`,
          x2: `${
            (horizontalPoints * (l12 + l10)) / horizontal + polyBorderStart
          }`,
          y2: `${(l9 * verticalPoints) / vertical + polyBorderStart}`,
        },
        {
          ref: lineRefs[9],
          type: "horizontalGroupTop",
          x1: `${
            (horizontalPoints * (l12 + l10)) / horizontal + polyBorderStart
          }`,
          y1: `${(l9 * verticalPoints) / vertical + polyBorderStart}`,
          x2: `${(l12 * horizontalPoints) / horizontal + polyBorderStart}`,
          y2: `${(l9 * verticalPoints) / vertical + polyBorderStart}`,
        },
        {
          ref: lineRefs[10],
          type: "verticalGroupLeft",
          x1: `${(l12 * horizontalPoints) / horizontal + polyBorderStart}`,
          y1: `${(l9 * verticalPoints) / vertical + polyBorderStart}`,
          x2: `${(l12 * horizontalPoints) / horizontal + polyBorderStart}`,
          y2: `${polyBorderStart}`,
        },
        {
          ref: lineRefs[11],
          type: "horizontalGroupTop",
          x1: `${(l12 * horizontalPoints) / horizontal + polyBorderStart}`,
          y1: `${polyBorderStart}`,
          x2: `${polyBorderStart}`,
          y2: `${polyBorderStart}`,
        },
        {
          line: "placeHolder",
          type: "verticalGroupLeft",
          ref: lineRefs[12],
          x1: `${(l12 * horizontalPoints) / horizontal + polyBorderStart}`,
          y1: `${(l9 * verticalPoints) / vertical + polyBorderStart}`,
          x2: `${(l12 * horizontalPoints) / horizontal + polyBorderStart}`,
          y2: `${((l11 + l13) * verticalPoints) / vertical + polyBorderStart}`,

          show: false,
        },
        {
          line: "placeHolder",
          type: "verticalGroupRight",
          ref: lineRefs[13],
          x1: `${
            (horizontalPoints * (l12 + l10)) / horizontal + polyBorderStart
          }`,
          y1: `${(l9 * verticalPoints) / vertical + polyBorderStart}`,

          x2: `${
            (horizontalPoints * (l12 + l10)) / horizontal + polyBorderStart
          }`,
          y2: `${((l11 + l13) * verticalPoints) / vertical + polyBorderStart}`,
          show: false,
        },
        {
          line: "placeHolder",
          type: "horizontalGroupBelow",
          ref: lineRefs[14],
          x1: `${polyBorderStart}`,
          y1: "0",
          x2: `${horizontalPoints + polyBorderStart}`,
          y2: "0",
          show: false,
        },
        {
          line: "placeHolder",
          type: "horizontalGroupTop",
          ref: lineRefs[15],
          x1: `${polyBorderStart}`,
          y1: "0",
          x2: `${horizontalPoints + polyBorderStart}`,
          y2: "0",
          show: false,
        },
      ];
      let colorPoints;
      [...points]
        .filter((p) => p.line !== "placeHolder")
        .map(
          ({ x1, y1, x2, y2 }) =>
            (colorPoints = colorPoints
              ? `${colorPoints} ${x1},${y1} ${x2},${y2}`
              : `${x1},${y1} ${x2},${y2}`)
        );
      setPolyColorPoints(colorPoints);
      setLinePoints(points);
      setVgLeft([
        {
          lineRef: lineRefs[0],
          vertical,
          text: {
            x: `${polyBorderStart - 15}`,
            y: `${maxHeight / 2}`,
            lineLength: l1,
          },
        },
        {
          lineRef: lineRefs[2],
          vertical,
          text: {
            x: `${(horizontalPoints * l2) / horizontal + polyBorderStart + 15}`,
            y: `${
              (verticalPoints +
                polyBorderStart +
                (((l11 + l13) * verticalPoints) / vertical + polyBorderStart)) /
              2
            }`,
            lineLength: l3,
          },
        },
        {
          lineRef: lineRefs[10],
          vertical,
          text: {
            x: `${
              (l12 * horizontalPoints) / horizontal + polyBorderStart + 15
            }`,
            y: `${
              ((l9 * verticalPoints) / vertical +
                polyBorderStart +
                polyBorderStart) /
              2
            }`,
            lineLength: l11,
          },
        },
        {
          lineRef: lineRefs[12],
          vertical,
          text: {
            x: `${
              (l12 * horizontalPoints) / horizontal + polyBorderStart + 10
            }`,
            y: `${
              ((l9 * verticalPoints) / vertical +
                polyBorderStart +
                (((l11 + l13) * verticalPoints) / vertical + polyBorderStart)) /
              2
            }`,
            lineLength: l13,
          },
          line: "placeHolder",
        },
      ]);

      setVgRight([
        {
          lineRef: lineRefs[4],
          vertical,
          text: {
            x: `${
              (horizontalPoints * (l2 + l4)) / horizontal + polyBorderStart - 15
            }`,
            y: `${
              (((l9 + l14) * verticalPoints) / vertical +
                polyBorderStart +
                (verticalPoints + polyBorderStart)) /
              2
            }`,
            lineLength: l5,
          },
        },
        {
          lineRef: lineRefs[6],
          vertical,
          text: {
            x: `${horizontalPoints + polyBorderStart + 15}`,
            y: `${maxHeight / 2}`,
            lineLength: l7,
          },
        },
        {
          lineRef: lineRefs[8],
          vertical,
          text: {
            x: `${
              (horizontalPoints * (l12 + l10)) / horizontal +
              polyBorderStart -
              15
            }`,
            y: `${
              (polyBorderStart +
                ((l9 * verticalPoints) / vertical + polyBorderStart)) /
              2
            }`,
            lineLength: l9,
          },
        },
        {
          lineRef: lineRefs[13],
          vertical,
          text: {
            x: `${
              (horizontalPoints * (l12 + l10)) / horizontal +
              polyBorderStart -
              10
            }`,
            y: `${
              ((l9 * verticalPoints) / vertical +
                polyBorderStart +
                (((l11 + l13) * verticalPoints) / vertical + polyBorderStart)) /
              2
            }`,
            lineLength: l7 - (l5 + l9),
          },
          line: "placeHolder",
        },
      ]);
      setHgBelow([
        {
          lineRef: lineRefs[1],
          horizontal,
          text: {
            x: `${
              (polyBorderStart +
                ((horizontalPoints * l2) / horizontal + polyBorderStart)) /
              2
            }`,
            y: `${verticalPoints + polyBorderStart + 25}`,
            lineLength: l2,
          },
        },
        {
          lineRef: lineRefs[3],
          horizontal,
          text: {
            x: `${
              ((horizontalPoints * l2) / horizontal +
                polyBorderStart +
                ((horizontalPoints * (l2 + l4)) / horizontal +
                  polyBorderStart)) /
              2
            }`,
            y: `${
              ((l11 + l13) * verticalPoints) / vertical + polyBorderStart + 25
            }`,
            lineLength: l4,
          },
        },
        {
          lineRef: lineRefs[5],
          horizontal,
          text: {
            x: `${
              ((horizontalPoints * (l2 + l4)) / horizontal +
                polyBorderStart +
                (horizontalPoints + polyBorderStart)) /
              2
            }`,
            y: `${verticalPoints + polyBorderStart + 25}`,
            lineLength: l6,
          },
        },
        {
          lineRef: lineRefs[14],
          horizontal,
          text: {
            x: `${(horizontalPoints + polyBorderStart) / 2}`,
            y: "20",
            lineLength: horizontal,
          },
          line: "placeHolder",
        },
      ]);
      setHgTop([
        {
          lineRef: lineRefs[7],
          horizontal,
          text: {
            x: `${
              (horizontalPoints +
                polyBorderStart +
                ((horizontalPoints * (l12 + l10)) / horizontal +
                  polyBorderStart)) /
              2
            }`,
            y: `${polyBorderStart - 10}`,
            lineLength: l8,
          },
        },
        {
          lineRef: lineRefs[9],
          horizontal,
          text: {
            x: `${
              ((horizontalPoints * (l12 + l10)) / horizontal +
                polyBorderStart +
                ((l12 * horizontalPoints) / horizontal + polyBorderStart)) /
              2
            }`,
            y: `${(l9 * verticalPoints) / vertical + polyBorderStart - 10}`,
            lineLength: l10,
          },
        },
        {
          lineRef: lineRefs[11],
          horizontal,
          text: {
            x: `${
              ((l12 * horizontalPoints) / horizontal +
                polyBorderStart +
                polyBorderStart) /
              2
            }`,
            y: "40",
            lineLength: l12,
          },
        },
        {
          lineRef: lineRefs[15],
          horizontal,
          text: {
            x: `${(horizontalPoints + polyBorderStart) / 2}`,
            y: "20",
            lineLength: horizontal,
          },
          line: "placeHolder",
        },
      ]);
    }
  }, [maxWidth, maxHeight, width, lineRefs, lLength]);

  return [
    polyColorPoints,
    linePoints,
    vgLeft,
    hgTop,
    hgBelow,
    hg2,
    vgRight,
    vg2,
  ];
};

export default useLinesH;
