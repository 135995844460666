const tabButtons = [
  {
    tab: 0,
    buttonTitle: "Polygon Side Length Challenge",
    about:
      "Game designed to understand part, part, whole relationships when analyzing polygons. Visualize how to find the missing part.",
  },
  {
    tab: 1,
    buttonTitle: "Number Line Fraction Division Challenge",
    about:
      "Game designed to understand and visualize dividing with fractions. This activity will help make sense of quotients.",
  },
  {
    tab: 2,
    buttonTitle: "Find the Proportionality Constant",
    about: "Game designed to understand Proportionality Constant.",
  },
  {
    tab: 3,
    buttonTitle: "Measure the Angle Using Algebra",
    about:
      "Game designed to understand Supplementary and Complementary Angles.",
  },
  {
    tab: 4,
    buttonTitle: "Fraction Bars",
    about: "Game designed to understand Fraction Bars.",
  },
  {
    tab: 5,
    buttonTitle: "Greatest Common Factor",
    about: "Game designed to understand Greatest Common Factor.",
  },
];

export default tabButtons;
